import React from 'react'
import { makeStyles, withStyles } from '@material-ui/styles'
import {
  Container,
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'

const useStyles = makeStyles(() => ({
  backTheme: {
    width: '100%',
    backgroundColor: '#E5E5E5',
  },
  text: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 20,
    color: '#000000',
    textAlign: 'left',
    margin: '2rem 0 1rem',
  },
  AccordionHeading: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 14,
    lineHeight: '17px',
    color: '#222222',
  },
  AccordionText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '20px',
    color: '#000000',
    textAlign: 'left',
  },
  root: {
    width: '100%',
  },
}))

const AccordionSummaryCustom = withStyles({
  root: {
    backgroundColor: '#E5E5E5',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(AccordionSummary)

function Faq() {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState('panel1')

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }
  return (
    <Box className={classes.backTheme}>
      <Container maxWidth="lg">
        <Grid container>
          <Grid
            item
            md={12}
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              paddingTop: '7rem',
            }}
          >
            <Typography variant="h1">Často kladené otázky</Typography>
            <Typography className={classes.text}>Obrazy</Typography>
          </Grid>
          <Grid item xs={12}>
            <div>
              <Accordion
                elevation={0}
                square
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
              >
                <AccordionSummaryCustom
                  expandIcon={
                    expanded === 'panel1' ? <RemoveIcon /> : <AddIcon />
                  }
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  style={
                    expanded === 'panel1'
                      ? { borderBottom: '1px solid black' }
                      : null
                  }
                >
                  <Typography className={classes.AccordionHeading}>
                    Jaké informace potřebuji na vyplnění obrazu pro miminka?
                  </Typography>
                </AccordionSummaryCustom>
                <AccordionDetails style={{ backgroundColor: '#E5E5E5' }}>
                  <Typography className={classes.AccordionText}>
                    Naše personalizované obrazy pro miminka vypráví příběh
                    jejich narození a příběh jich samotných. Obraz je vhodný i
                    pro menší děti nebo batolata. Informace obsahují: Kvalitní
                    fotku, celé jméno, datum narození, krátkou básničku nebo
                    vzkaz miminku, čas narození, místo narození, délku a váhu
                    dítěte po narození. Kupujete obraz jako dárek a nevíte
                    nějakou z těchto informací? Využijte našich dárkových
                    voucherů, nebo nám napište na tvuj@lifecalendar.cz a my se
                    Vám pokusíme vyhovět s úpravou (záleží, o kterou informaci
                    přesně půjde).
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                elevation={0}
                square
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
              >
                <AccordionSummaryCustom
                  expandIcon={
                    expanded === 'panel2' ? <RemoveIcon /> : <AddIcon />
                  }
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                  style={{
                    backgroundColor: 'E5E5E5',

                    borderBottom:
                      expanded === 'panel2' ? '1px solid black' : null,
                  }}
                >
                  <Typography className={classes.AccordionHeading}>
                    Jaké informace potřebuji na vyplnění svatebního obrazu?
                  </Typography>
                </AccordionSummaryCustom>
                <AccordionDetails style={{ backgroundColor: '#E5E5E5' }}>
                  <Typography className={classes.AccordionText}>
                    - Naše svatební personalizované obrazy vypráví příběh Vašeho
                    svatebního dne a i drobnosti o Vašem vztahu. Obraz je vhodný
                    pro novomanžele.
                    <br />
                    - Informace obsahují: Datum svatby, krátký vzkaz nebo pár
                    slov o dni svatby, křestní jméno nevěsty a ženicha + jejich
                    společné příjmení, místo obřadu či oslavy, narození obou
                    novomanželů (kvůli ikony hvězdného znamení), v krátkosti Váš
                    příběh, čas obřadu, kým jste byli oddáni, vaše skladba (na
                    první tanec, či příchod nevěsty), počet hostů <br />-
                    Kupujete obraz jako dárek a nevíte nějakou z těchto
                    informací? Využijte našich dárkových voucherů, nebo nám
                    napiště na tvuj@lifecalendar.cz a my se Vám pokusíme vyhovět
                    s úpravou (záleží, o kterou informaci přesně půjde).
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                elevation={0}
                square
                expanded={expanded === 'panel3'}
                onChange={handleChange('panel3')}
              >
                <AccordionSummaryCustom
                  expandIcon={
                    expanded === 'panel3' ? <RemoveIcon /> : <AddIcon />
                  }
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                  style={
                    expanded === 'panel3'
                      ? { borderBottom: '1px solid black' }
                      : null
                  }
                >
                  <Typography className={classes.AccordionHeading}>
                    Jakou velikost má obraz?
                  </Typography>
                </AccordionSummaryCustom>
                <AccordionDetails style={{ backgroundColor: '#E5E5E5' }}>
                  <Typography className={classes.AccordionText}>
                    Obraz Svatba i Dítě mají velikost čistě papíru 40x50 cm.
                    Velikost s rámem je 42,5x52,5 cm.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                elevation={0}
                square
                expanded={expanded === 'panel4'}
                onChange={handleChange('panel4')}
              >
                <AccordionSummaryCustom
                  expandIcon={
                    expanded === 'panel4' ? <RemoveIcon /> : <AddIcon />
                  }
                  style={
                    expanded === 'panel4'
                      ? { borderBottom: '1px solid black' }
                      : null
                  }
                >
                  <Typography className={classes.AccordionHeading}>
                    Mohu si šablonu obrazu nechat upravit?
                  </Typography>
                </AccordionSummaryCustom>
                <AccordionDetails style={{ backgroundColor: '#E5E5E5' }}>
                  <Typography className={classes.AccordionText}>
                    Našim zákazníkům chceme vyjít co nejvíce vstříc. Jde přece o
                    VAŠE životní momenty a vzpomínky, které si chcete pamatovat.
                    Pokud tedy máte nápad na změnu, úpravu, nebo přepsání
                    jakékoliv informace, neváhejte nám psát na mail
                    tvuj@lifecalendar.cz. Budeme se snažit Vám vyjít co nejvíce
                    vstříc.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                elevation={0}
                square
                expanded={expanded === 'panel5'}
                onChange={handleChange('panel5')}
              >
                <AccordionSummaryCustom
                  expandIcon={
                    expanded === 'panel5' ? <RemoveIcon /> : <AddIcon />
                  }
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                  style={
                    expanded === 'panel5'
                      ? { borderBottom: '1px solid black' }
                      : null
                  }
                >
                  <Typography className={classes.AccordionHeading}>
                    Chci obraz darovat jako dárek, ale neznám všechny informace.
                  </Typography>
                </AccordionSummaryCustom>
                <AccordionDetails style={{ backgroundColor: '#E5E5E5' }}>
                  <Typography className={classes.AccordionText}>
                    - Nevadí. Je dost obtížné dělat personalizovaný obraz někomu
                    jinému. Pro tyto případy máme v nabídce i dárkové vouchery,
                    které novopečeným rodičům, nebo novomanželům rozhodně
                    udělají radost. Obdarovaní pak jednoduše vyplní formulář a
                    na konci objednávky akorát vypíší kód dárkového poukazu -
                    nákup tak budou mít zdarma.
                    <br />- Voucher můžete koupit na libovolný obraz, ve
                    variantě s rámem, či bez rámu.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                elevation={0}
                square
                expanded={expanded === 'panel7'}
                onChange={handleChange('panel7')}
              >
                <AccordionSummaryCustom
                  expandIcon={
                    expanded === 'panel7' ? <RemoveIcon /> : <AddIcon />
                  }
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                  style={
                    expanded === 'panel7'
                      ? { borderBottom: '1px solid black' }
                      : null
                  }
                >
                  <Typography className={classes.AccordionHeading}>
                    Co dělat, když má dítě prostřední jméno?
                  </Typography>
                </AccordionSummaryCustom>
                <AccordionDetails style={{ backgroundColor: '#E5E5E5' }}>
                  <Typography className={classes.AccordionText}>
                    Druhé jméno, prosím, napište klasicky ke křestnímu jménu
                    (tzn. Marie Lucie)
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                elevation={0}
                square
                expanded={expanded === 'panel8'}
                onChange={handleChange('panel8')}
              >
                <AccordionSummaryCustom
                  expandIcon={
                    expanded === 'panel8' ? <RemoveIcon /> : <AddIcon />
                  }
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                  style={
                    expanded === 'panel8'
                      ? { borderBottom: '1px solid black' }
                      : null
                  }
                >
                  <Typography className={classes.AccordionHeading}>
                    Proč by jste měli využít našich rámů?
                  </Typography>
                </AccordionSummaryCustom>
                <AccordionDetails style={{ backgroundColor: '#E5E5E5' }}>
                  <Typography className={classes.AccordionText}>
                    Držíme se hesla „kvalita před kvantitou“. Rámy, které
                    nabízíme jsou kvalitní, české výroby. Jedná se o masivní
                    rámy se sklem, které se designové hodí do každé domácnosti.
                    Rámy váží zhruba 1500g.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </Grid>
          <Grid
            item
            md={12}
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              paddingTop: '4rem',
            }}
          >
            <Typography className={classes.text}>Objednávky</Typography>
          </Grid>
          <Grid item xs={12}>
            <div>
              <Accordion
                elevation={0}
                square
                expanded={expanded === 'panel9'}
                onChange={handleChange('panel9')}
              >
                <AccordionSummaryCustom
                  expandIcon={
                    expanded === 'panel9' ? <RemoveIcon /> : <AddIcon />
                  }
                  aria-controls="panel4d-content"
                  id="panel4d-header"
                  style={
                    expanded === 'panel9'
                      ? { borderBottom: '1px solid black' }
                      : null
                  }
                >
                  <Typography className={classes.AccordionHeading}>
                    Jsou informace na mé objednávce v pořádku?
                  </Typography>
                </AccordionSummaryCustom>
                <AccordionDetails style={{ backgroundColor: '#E5E5E5' }}>
                  <Typography className={classes.AccordionText}>
                    Všechny objednávky pečlivě kontrolujeme a zapracováváme
                    informace do naší grafiky. Kdyby došlo k nějakému špatnému
                    vyplnění budeme Vás kontaktovat.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                elevation={0}
                square
                expanded={expanded === 'panel10'}
                onChange={handleChange('panel10')}
              >
                <AccordionSummaryCustom
                  expandIcon={
                    expanded === 'panel10' ? <RemoveIcon /> : <AddIcon />
                  }
                  aria-controls="panel5d-content"
                  id="panel5d-header"
                  style={
                    expanded === 'panel10'
                      ? { borderBottom: '1px solid black' }
                      : null
                  }
                >
                  <Typography className={classes.AccordionHeading}>
                    Co dělat když udělám typografickou chybu, nebo chybu v
                    informaci?
                  </Typography>
                </AccordionSummaryCustom>
                <AccordionDetails style={{ backgroundColor: '#E5E5E5' }}>
                  <Typography className={classes.AccordionText}>
                    Nevadí, tohle se může stát. Pokud jste nám objednávku již
                    odeslali, prosím napište nám na mail tvuj@lifecalendar.cz a
                    popište nám chybu, kterou potřebujete opravit - maximálně do
                    2 dnů od odeslání objednávky. Po více než dvou dnech si
                    účtujeme za změny nebo úpravy 300Kč.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                elevation={0}
                square
                expanded={expanded === 'panel11'}
                onChange={handleChange('panel11')}
              >
                <AccordionSummaryCustom
                  expandIcon={
                    expanded === 'panel11' ? <RemoveIcon /> : <AddIcon />
                  }
                  aria-controls="panel6d-content"
                  id="panel6d-header"
                  style={
                    expanded === 'panel11'
                      ? { borderBottom: '1px solid black' }
                      : null
                  }
                >
                  <Typography className={classes.AccordionHeading}>
                    Proč dodání mé objednávky trvá tak dlouho?
                  </Typography>
                </AccordionSummaryCustom>
                <AccordionDetails style={{ backgroundColor: '#E5E5E5' }}>
                  <Typography className={classes.AccordionText}>
                    Každou objednávku zpracováváme zvlášť. Fotky popřípadě
                    upravujeme tak, aby výsledek byl co nejlepší a aby všichni
                    naši zákazníci byli spokojení. Snažíme se připravovat
                    objednávky pro naše klienty co nejrychleji, ale v případě
                    většího množství objednávek se tato doba na zpracování může
                    prodlužovat. Doba doručení je obyčejně cca 1 týden.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                elevation={0}
                square
                expanded={expanded === 'panel12'}
                onChange={handleChange('panel12')}
              >
                <AccordionSummaryCustom
                  expandIcon={
                    expanded === 'panel12' ? <RemoveIcon /> : <AddIcon />
                  }
                  aria-controls="panel6d-content"
                  id="panel6d-header"
                  style={
                    expanded === 'panel12'
                      ? { borderBottom: '1px solid black' }
                      : null
                  }
                >
                  <Typography className={classes.AccordionHeading}>
                    Jak funguje objednání obrazu?
                  </Typography>
                </AccordionSummaryCustom>
                <AccordionDetails style={{ backgroundColor: '#E5E5E5' }}>
                  <Typography className={classes.AccordionText}>
                    Těší náš Váš zájem! Objednání je opravdu jednoduché. Stačí
                    si vybrat o jaký obraz máte zájem, vybrat variantu (s rámem,
                    či bez) a můžete přejít do editoru. V editoru vyplníte
                    všechny údaje a následně objednáte a zaplatíte. Je to
                    jednoduché. V případě otázek nám ale neváhejte napsat na
                    mail tvuj@lifecalendar.cz
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                elevation={0}
                square
                expanded={expanded === 'panel13'}
                onChange={handleChange('panel13')}
              >
                <AccordionSummaryCustom
                  expandIcon={
                    expanded === 'panel13' ? <RemoveIcon /> : <AddIcon />
                  }
                  aria-controls="panel6d-content"
                  id="panel6d-header"
                  style={
                    expanded === 'panel13'
                      ? { borderBottom: '1px solid black' }
                      : null
                  }
                >
                  <Typography className={classes.AccordionHeading}>
                    Jak funguje editor?
                  </Typography>
                </AccordionSummaryCustom>
                <AccordionDetails style={{ backgroundColor: '#E5E5E5' }}>
                  <Typography className={classes.AccordionText}>
                    Editor nám plní funkci vyplnění všech informací, které
                    potřebujeme k objednávce. Pro lepší představu pro Vás zde
                    máme náhled obrazu. Náhled je však jen ilustrativní a navíc
                    částečný. Vámi vyplněné informace poté až grafik ručně
                    vkládá do finální podoby šablony, kde ještě vše upravujeme a
                    ladíme, aby byl výsledek co nejlepší.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                elevation={0}
                square
                expanded={expanded === 'panel14'}
                onChange={handleChange('panel14')}
              >
                <AccordionSummaryCustom
                  expandIcon={
                    expanded === 'panel14' ? <RemoveIcon /> : <AddIcon />
                  }
                  aria-controls="panel6d-content"
                  id="panel6d-header"
                  style={
                    expanded === 'panel14'
                      ? { borderBottom: '1px solid black' }
                      : null
                  }
                >
                  <Typography className={classes.AccordionHeading}>
                    Jak mohu zrušit mou objednávku?
                  </Typography>
                </AccordionSummaryCustom>
                <AccordionDetails style={{ backgroundColor: '#E5E5E5' }}>
                  <Typography className={classes.AccordionText}>
                    Jste si jistý/á? Nevadí. Po kliknutí na tlačítko „Odeslat
                    objednávku“ jste nám zaslali a potvrdili Vaší objednávku,
                    pokud máte zájem tuto objednávku stornovat, můžete tak
                    bezplatně učinit do 2 dnů (přesně) od odeslání této
                    objednávky. Do těchto dvou dnů máte právo na vrácení peněz v
                    plné výši. Po uplynutí této doby zaniká toto právo a
                    jakákoliv náhlada není možná.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                elevation={0}
                square
                expanded={expanded === 'panel15'}
                onChange={handleChange('panel15')}
              >
                <AccordionSummaryCustom
                  expandIcon={
                    expanded === 'panel15' ? <RemoveIcon /> : <AddIcon />
                  }
                  aria-controls="panel6d-content"
                  id="panel6d-header"
                  style={
                    expanded === 'panel15'
                      ? { borderBottom: '1px solid black' }
                      : null
                  }
                >
                  <Typography className={classes.AccordionHeading}>
                    Jaké formáty fotek jsou přijímány?
                  </Typography>
                </AccordionSummaryCustom>
                <AccordionDetails style={{ backgroundColor: '#E5E5E5' }}>
                  <Typography className={classes.AccordionText}>
                    Do editoru je možné vložit pouze fotky z formátu .png, .jpg,
                    nebo .heic. Pokud fotku nelze nahrát, ujistěte se, že máte
                    fotku ve správném formátu.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                elevation={0}
                square
                expanded={expanded === 'panel16'}
                onChange={handleChange('panel16')}
              >
                <AccordionSummaryCustom
                  expandIcon={
                    expanded === 'panel16' ? <RemoveIcon /> : <AddIcon />
                  }
                  aria-controls="panel6d-content"
                  id="panel6d-header"
                  style={
                    expanded === 'panel16'
                      ? { borderBottom: '1px solid black' }
                      : null
                  }
                >
                  <Typography className={classes.AccordionHeading}>
                    Co dělat když obraz dojde poškozený?
                  </Typography>
                </AccordionSummaryCustom>
                <AccordionDetails style={{ backgroundColor: '#E5E5E5' }}>
                  <Typography className={classes.AccordionText}>
                    Jelikož posíláme v balících na přepravu náročné materiály
                    jako je sklo a papír, může se tak stát, že při nešetrném
                    zacházení se balík po cestě poničí, nebo rovnou praskne
                    sklo. Jestli balík již na první pohled vypadá velmi
                    poškozeně, tak zásilku od kurýra vůbec nepřebírejte. Pokud
                    zjistíte, že je balík poškozený až doma po rozbalení, prosím
                    kontaktujte nás na mail tvuj@lifecalendar.cz a my se dále
                    podle situace budeme snažit problém vyřešit.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </Grid>
        </Grid>
        <Box width="100%" height="2rem"></Box>
      </Container>
    </Box>
  )
}

export default Faq
