import React, { useEffect, useState } from 'react'
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Grid,
  Drawer,
  List,
  Hidden,
  Badge,
  ListItem,
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/styles'
import { Link, useHistory } from 'react-router-dom'
import {
  Menu as MenuIcon,
  LocalMallOutlined as LocalMallOutlinedIcon,
  Close as CloseIcon,
} from '@material-ui/icons'
import { useOrder } from '../Context/OrderContext'

const useStylesHeader = makeStyles((theme) => ({
  appBar: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '& .MuiToolbar-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
    } /* & na selectnutie oznacenych potomkov */,
  },
  appBar2: {
    backgroundColor: 'white',
    boxShadow: 'none',
    '& .MuiToolbar-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
    } /* & na selectnutie oznacenych potomkov */,
  },
  menuIcon: {
    marginRight: theme.spacing(0),
    marginLeft: 0,
    fill: '#1B1B1B',
    color: '#1B1B1B' /* tri ciarky v menu icone */,
  },
  menuIconBox: {
    marginLeft: 0,
    padding: 10,
  },
  linkText: {
    marginLeft: 38,
    textDecoration: 'none',

    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '25px',
    textTransform: 'uppercase',
    color: '#1B1B1B',
  },
  headerLogo: {
    width: '100%',
    maxWidth: 200,
  },
  paperContent: {
    paddingLeft: '1rem',
    paddingTop: '2rem',
  },
  paperFont: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 24,
    textTransform: 'uppercase',
    color: '#FFFFFF',
    border: 'none',
    background: 'transparent',
    cursor: 'pointer',
    padding: '0.2rem 0',
  },
  paperFont2: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 18,
    textTransform: 'uppercase',
    color: '#FFFFFF',
    border: 'none',
    background: 'transparent',
    cursor: 'pointer',
    padding: '0.2rem 0',
  },
  paperContent2: {
    paddingLeft: '1rem',
    paddingBottom: '1rem',
    marginTop: 'auto',
  },
}))

const ColorDrawer = withStyles((theme) => ({
  paper: {
    /* keby root tak sa styl aplikuje na hlavnu stranku nie na bocnu listu */
    color: theme.palette.getContrastText('#505050'),
    backgroundColor: '#505050',
    '&:hover': {
      backgroundColor: '#505050',
    },
  },
}))(Drawer)

const CartBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge)

function Header() {
  const classes = useStylesHeader()

  const { push } = useHistory()

  const { orders } = useOrder()
  const cartArrLen = orders?.length

  const [open, setOpen] = useState(false)

  const list = () => {
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          padding: '0 2rem 0 1rem',
        }}
      >
        <IconButton
          style={{
            padding: '12px 0',
            justifyContent: 'center',
            alignSelf: 'flex-start',
          }}
          onClick={() => setOpen(false)}
        >
          <CloseIcon style={{ color: 'white' }}></CloseIcon>
        </IconButton>
        <List className={classes.paperContent}>
          <ListItem
            component="button"
            variant="text"
            className={classes.paperFont}
            onClick={() => {
              push('/detail/kids')
              setOpen(false)
            }}
          >
            DĚTI
          </ListItem>
          <ListItem
            component="button"
            variant="text"
            onClick={() => {
              push('/detail/wedding')
              setOpen(false)
            }}
            className={classes.paperFont}
          >
            SVATBA
          </ListItem>
          <ListItem
            component="button"
            variant="text"
            className={classes.paperFont}
            onClick={() => {
              push('/')
              setOpen(false)
            }}
          >
            DOMŮ
          </ListItem>
          <ListItem
            component="button"
            variant="text"
            className={classes.paperFont}
            onClick={() => {
              push('/contact')
              setOpen(false)
            }}
          >
            NÁŠ PŘÍBĚH
          </ListItem>
          <ListItem
            component="button"
            variant="text"
            className={classes.paperFont}
            onClick={() => {
              push('/faq')
              setOpen(false)
            }}
          >
            ČASTÉ OTÁZKY
          </ListItem>
          {/* <ListItemText
              onClick={() => push('/category/life')}
              className={classes.paperFont}
            >
              LIFE CALENDAR
            </ListItemText> */}
        </List>

        <List className={classes.paperContent2}>
          <ListItem
            component="button"
            variant="text"
            className={classes.paperFont2}
            onClick={() => {
              push('/terms')
              setOpen(false)
            }}
          >
            OBCHODNÍ PODMÍNKY
          </ListItem>
          <ListItem
            component="button"
            variant="text"
            className={classes.paperFont2}
            onClick={() => {
              push('/gdpr')
              setOpen(false)
            }}
          >
            OCHRANA OSOBNÍCH ÚDAJŮ
          </ListItem>
        </List>
      </Box>
    )
  }

  const [pos, setPos] = useState('top')

  useEffect(() => {
    document.addEventListener('scroll', () => {
      let scrolled = document.scrollingElement.scrollTop
      if (scrolled >= 10) {
        setPos('moved')
      } else {
        setPos('top')
      }
    })
  }, [])

  return (
    <>
      <ColorDrawer anchor={'left'} open={open} onClose={() => setOpen(false)}>
        {list()}
      </ColorDrawer>
      <AppBar
        className={pos === 'top' ? classes.appBar : classes.appBar2}
        style={{
          borderBottom: '1px solid #1B1B1B',
          zIndex: '900',
        }}
      >
        <Toolbar variant="dense">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
              <Hidden mdUp>
                <Box className={classes.menuIconBox}>
                  <IconButton
                    onClick={() => setOpen(true)}
                    className={classes.menuIcon}
                    color="inherit"
                    aria-label="menu"
                  >
                    <MenuIcon></MenuIcon>
                  </IconButton>
                </Box>
              </Hidden>
              <Hidden smDown>
                <Link to="/detail/kids" className={classes.linkText}>
                  Dítě
                </Link>
                <Link to="/detail/wedding" className={classes.linkText}>
                  Svatba
                </Link>
              </Hidden>
            </Grid>
            <Grid
              item
              xs={3}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Link to="/">
                <img
                  src={'/images/logo.svg'}
                  alt="headerLogo"
                  className={classes.headerLogo}
                />
              </Link>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                justifyContent: 'flex-end',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Hidden smDown>
                <Link to="/contact" className={classes.linkText}>
                  Náš příběh
                </Link>
                <Link to="/faq" className={classes.linkText}>
                  Časté otázky
                </Link>
              </Hidden>
              <Box className={classes.menuIconBox}>
                {/* <IconButton component={Link} to="/profile">
                  <PermIdentityIcon></PermIdentityIcon>
                </IconButton> */}
              </Box>
              <Box padding="10px">
                <IconButton
                  component={Link}
                  to="/Cart"
                  className={classes.shoppingBagIcon}
                >
                  <CartBadge badgeContent={cartArrLen} color="secondary">
                    <LocalMallOutlinedIcon></LocalMallOutlinedIcon>
                  </CartBadge>
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default Header
