import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import { useOrder } from '../../Context/OrderContext'

function PaymentDialog() {
  const { push, go } = useHistory()
  const { gatewayResponse } = useOrder()

  const onMessage = (e) => {
    if (['success', 'error', 'processing'].includes(e.data)) {
      push('/payment/' + e.data)
      go(0)
    }
  }

  useEffect(() => {
    window.addEventListener('message', onMessage)

    return () => {
      window.removeEventListener('message', onMessage)
    }
  }, [])

  return (
    <>
      <Dialog open={!!gatewayResponse?.redirect} fullWidth={true} maxWidth="sm">
        <iframe
          src={gatewayResponse?.redirect}
          title="Platební brána"
          height="100%"
          style={{ minHeight: '80vh' }}
          width="100%"
          noscroll="true"
          frameBorder="0"
          position="fixed"
          top="0"
          left="0"
          bottom="0"
          right="0"
          border="0"
        ></iframe>
      </Dialog>
    </>
  )
}

export default PaymentDialog
