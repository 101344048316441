import React from 'react'
import { Box, Container, Grid, Typography, Button } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/styles'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  backTheme: {
    width: '100%',
  },
  title: {
    paddingTop: '3rem',
    fontFamily: 'Bodoni',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 36,
    lineHeight: '47px',
    color: '#1B1B1B',
  },
  titleMobile: {
    fontFamily: 'Bodoni',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 36,
    lineHeight: '47px',
    color: '#1B1B1B',
  },
  titleGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  text: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '156.4%',
    textAlign: 'center',
    color: '#1B1B1B',
  },
  textGrid: {
    maxWidth: 581,
  },
  text2: {
    width: '100%',
    marginTop: '-0.5em',
    marginLeft: '4em',
    textAlign: 'left',
    fontFamily: 'Over the Rainbow',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 30,
    lineHeight: '61px',
    color: '#505050',
    '@media (max-width: 900px)': {
      marginLeft: 0,
    },
  },
  text2Grid: {
    margin: '2rem 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
  },
}))

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#1B1B1B'),
    backgroundColor: '#1B1B1B',
    '&:hover': {
      backgroundColor: '#1B1B1B',
    },
  },
}))(Button)

function CuponComponent() {
  const classes = useStyles()
  const { push } = useHistory()

  return (
    <Box className={classes.backTheme}>
      <Container>
        <hr />
        <Grid
          container
          justifyContent="center"
          style={{
            minHeight: '37em',
            maxWidth: '1232px',
          }}
        >
          <Typography className={classes.title}>
            Darujte poukaz na vytvoření vlastního plakátu
          </Typography>
          <Grid item xs={12} className={classes.textGrid}>
            <Typography className={classes.text} style={{ padding: '1rem 0' }}>
              Nabízíme digitální poukazy, které zaíláme po zakoupení emailem v
              PDF. Poukaz můžete využít na všechny formy námi nabízených
              plakátů.
            </Typography>
          </Grid>
          <img
            style={{ margin: '1rem 0' }}
            width="100%"
            src="/images/poukazy.webp"
          />
          <Typography className={classes.text2}>
            milý dárek pro mé milé
          </Typography>
          <Grid item xs={12} className={classes.text2Grid}>
            <ColorButton
              variant="contained"
              onClick={() => push('/detail/voucher')}
            >
              Koupit dárkový poukaz
            </ColorButton>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default CuponComponent
