import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#000',
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: `"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif`,
    h1: {
      fontFamily: 'Bodoni',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 36,
      lineHeight: '47px',
      color: '#313131',
    },
    subtitle1: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 14,
      lineHeight: '17px',
      textTransform: 'uppercase',
      color: '#313131',
    },
  },
  overrides: {
    MuiToolbarGutters: {
      paddingLeft: 0,
    },
    MuiButton: {
      root: {
        padding: '6px 30px',
        color: '#FFF',
        borderRadius: 0,
        backgroundColor: '#1B1B1B',
        textTransform: 'uppercase',
        '&:hover': {
          backgroundColor: '#1B1B1B',
        },
        '&.Mui-disabled': {
          color: '#fff',
          backgroundColor: '#b9b9b9',
        },
      },
      text: {
        padding: '6px 28px',
      },
    },
    MuiTextField: {
      root: {
        fontSize: '14px',
        width: '100%',
        paddingTop: '1rem',
        paddingBottom: '1rem',
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderRadius: `0 0 0 0`,
          },
          '&.Mui-focused fieldset': {
            borderColor: 'black',
            borderWidth: '1px',
          },
        },
        '& .MuiFormLabel-root': {
          paddingTop: '1rem',
        },
        '& .MuiOutlinedInput-input': {
          padding: '8px 14px',
        },
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: 'black',
        '&$checked': {
          color: 'black',
        },
      },
    },
  },
})

export default theme
