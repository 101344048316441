import React, { useContext, createContext, useState } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'

const SnackBarContext = createContext()

function SnackBarProvider({ children }) {
  const [alerts, setAlerts] = useState([])

  const addAlert = (alert) =>
    setAlerts((alerts) => [{ message: alert, open: true }, ...alerts])

  const value = { addAlert }

  return (
    <SnackBarContext.Provider value={value}>
      {children}
      {alerts.map((alert, index) => (
        <Snackbar
          key={alert.message}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={alert.open}
          autoHideDuration={6000}
          onClose={() =>
            setAlerts(
              alerts.map((a, i) => ({
                ...a,
                open: index === i ? false : a.open,
              }))
            )
          }
          message={alert.message}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setAlerts(alerts.filter((a) => a !== alert))}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      ))}
    </SnackBarContext.Provider>
  )
}

const useSnackBars = () => useContext(SnackBarContext)

export { SnackBarProvider, useSnackBars }
