import React, { useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/styles'
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
} from '@material-ui/core'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  backTheme: {
    width: '100%',
    backgroundColor: '#E5E5E5',
    paddingBottom: '8rem',
  },
  title: {
    fontFamily: 'Bodoni',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 36,
    lineHeight: '47px',
    color: '#313131',
  },
  formText: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '138.4%',
    color: '#000000',
  },
  checkboxLabel: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '19px',
    color: '#808080',
  },
  registerText: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '24px',
    color: '#313131',
  },
  registerText2: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 10,
    lineHeight: '16px',
    color: '#313131',
  },
}))

const MyTextField = withStyles({
  root: {
    paddingBottom: '0rem',
  },
})(TextField)

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#1B1B1B'),
    backgroundColor: '#1B1B1B',
    '&:hover': {
      backgroundColor: '#1B1B1B',
    },
  },
}))(Button)

function LoginRegisterComponent() {
  const classes = useStyles()
  const [loginForm, setLoginForm] = useState({ email: '', password: '' })
  const [registerForm, setRegisterForm] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
  })

  return (
    <Box className={classes.backTheme}>
      <Container>
        <Grid container justifyContent="center" alignContent="center">
          <Grid item xs={6}>
            <Box
              style={{
                marginTop: '8rem',
                paddingRight: '2rem',
                borderRight: '1px solid black',
              }}
            >
              <Container style={{ backgroundColor: 'white' }}>
                <Typography
                  className={classes.title}
                  style={{ paddingTop: '2rem' }}
                >
                  Přihlašte se
                </Typography>
                <MyTextField
                  size="small"
                  id="email"
                  placeholder="Email"
                  variant="outlined"
                  value={loginForm.email}
                  onChange={(e) =>
                    setLoginForm({ ...loginForm, email: e.target.value })
                  }
                />
                <MyTextField
                  size="small"
                  type="password"
                  id="password"
                  placeholder="Heslo"
                  variant="outlined"
                  value={loginForm.password}
                  onChange={(e) =>
                    setLoginForm({ ...loginForm, password: e.target.value })
                  }
                />
                <Box style={{ paddingTop: '2rem' }}>
                  <FormControlLabel
                    className={classes.checkboxLabel}
                    control={<Checkbox name="loginDetails" />}
                    label="Zamapatovat si přihlašovací údaje"
                  ></FormControlLabel>
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '4rem',
                  }}
                >
                  <ColorButton
                    variant="contained"
                    style={{ borderRadius: 0, width: 157 }}
                    onClick={() => console.log(loginForm)}
                  >
                    Přihlásit se
                  </ColorButton>
                </Box>
                <Link
                  to="/ForgottenPassword"
                  className={classes.formText}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: 10,
                    paddingBottom: '2rem',
                  }}
                >
                  Zapoměli jste heslo?
                </Link>
              </Container>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              style={{
                marginTop: '8rem',
                paddingLeft: '2rem',
              }}
            >
              <Container>
                <Typography className={classes.title}>
                  Nemáte ještě účet?
                </Typography>
                <Typography className={classes.registerText}>
                  Registrace je nutná k objednání designových plakátů
                </Typography>
                <Typography className={classes.registerText2}>
                  Registrací získáte výhodu přehledu objednávky, či historii
                  objednávek. Nám tyto registrační údaje slouží jako kontaktní
                  údaje na Vás, v případě že by náš tým zjistil nějakou chybu,
                  nebo narazil na nekvalitní fotku na Vaší objednávce.
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <MyTextField
                      size="small"
                      id="firstname"
                      placeholder="Jméno"
                      variant="outlined"
                      value={registerForm.firstname}
                      onChange={(e) =>
                        setRegisterForm({
                          ...registerForm,
                          firstname: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MyTextField
                      size="small"
                      id="lastname"
                      placeholder="Přijmení"
                      variant="outlined"
                      value={registerForm.lastname}
                      onChange={(e) =>
                        setRegisterForm({
                          ...registerForm,
                          lastname: e.target.value,
                        })
                      }
                    />
                  </Grid>
                </Grid>
                <MyTextField
                  size="small"
                  id="email"
                  type="email"
                  placeholder="Email"
                  variant="outlined"
                  value={registerForm.email}
                  onChange={(e) =>
                    setRegisterForm({
                      ...registerForm,
                      email: e.target.value,
                    })
                  }
                />
                <MyTextField
                  size="small"
                  id="password"
                  type="password"
                  placeholder="Heslo"
                  variant="outlined"
                  value={registerForm.password}
                  onChange={(e) =>
                    setRegisterForm({
                      ...registerForm,
                      password: e.target.value,
                    })
                  }
                />
                <Box style={{ paddingTop: '2rem' }}>
                  <FormControlLabel
                    className={classes.checkboxLabel}
                    control={<Checkbox name="loginDetails" />}
                    label="Souhlasím s podmínkami a se zpracováním osobních údajů"
                  ></FormControlLabel>
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '1rem',
                    marginTop: 5,
                  }}
                >
                  <ColorButton
                    variant="contained"
                    style={{ borderRadius: 0, width: 157 }}
                  >
                    Registrovat
                  </ColorButton>
                </Box>
              </Container>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default LoginRegisterComponent
