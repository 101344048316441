import React from 'react'
import MainPreview from '../Components/MainPreview'
import Citation from '../Components/Citation'
import Kids from '../Components/Kids'
import Marriage from '../Components/Marriage'
/* import LifeCalendar from '../Components/LifeCalendar' */
import Cupon from '../Components/Cupon'
import HomeInfo from '../Components/HomeInfo'
import InstagramComponent from '../Components/Instagram'

const HomeFunction = () => {
  return (
    <>
      <MainPreview />
      <Citation />
      <Kids />
      <Marriage />
      {/* <LifeCalendar /> */}
      <HomeInfo />
      <Cupon />
      <InstagramComponent />
    </>
  )
}

export default HomeFunction
