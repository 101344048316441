import React from 'react'
import { useParams } from 'react-router-dom'

import DetailLife from '../Components/Detail/Life'
import DetailKids from '../Components/Detail/Kids'
import DetailWedding from '../Components/Detail/Wedding'
import DetailVoucher from '../Components/Detail/Voucher'

const Detail = () => {
  const { type, framed } = useParams()
  return (
    <>
      {type === 'life' && <DetailLife framed={!!framed} />}
      {type === 'kids' && <DetailKids framed={!!framed} />}
      {type === 'wedding' && <DetailWedding framed={!!framed} />}
      {type === 'voucher' && <DetailVoucher framed={!!framed} />}
    </>
  )
}

export default Detail
