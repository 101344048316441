import React, { useRef } from 'react'
import {
  Grid,
  Typography,
  Box,
  TextField,
  Button,
  Hidden,
  FormHelperText,
  LinearProgress,
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/styles'
import AddIcon from '@material-ui/icons/Add'
import Check from '@material-ui/icons/Check'
import PublishIcon from '@material-ui/icons/Publish'
import { useAuth } from '../../../Context/AuthContext'
import { useMarriageOrder } from '../../../Context/MarriageOrderContext'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import FrameColorSelect from '../../Editor/FrameColorSelect'

const useStyles = makeStyles((theme) => ({
  title1: {
    fontFamily: 'Bodoni',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 18,
    lineHeight: '173.5%',
    letterSpacing: '0.13em',
    textTransform: 'uppercase',
    color: '#1B1B1B',
  },
  title2: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '17px',
    textTransform: 'uppercase',
    color: '#1B1B1B',
  },
  plusSymbol: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '138.4%',
    color: '#000000',
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
  textFieldTextBlack: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '138.4%',
    color: '#000000',
  },
  textFieldTextGrey: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 10,
    lineHeight: '138.4%',
    color: '#C4C4C4',
  },
  uploadButton: {
    width: '100%',
    height: 118,
    borderRadius: 0,
  },
  uploadButtonText1: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '138.4%',
    color: '#505050',
    marginTop: '0.5rem',
  },
  uploadButtonText2: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 10,
    lineHeight: '138.4%',
    color: '#505050',
  },
  photoError: {
    color: theme.palette.error.main,
    marginLeft: '15px',
  },
}))

const UploadImgButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#FFFFFF'),
    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
    boxShadow: 'none !important',
    '&.Mui-disabled': {
      backgroundColor: '#f3f3f3',
    },
  },
}))(Button)

const MyTextFieldBig = withStyles({
  root: {
    width: '100%',
    height: 'auto',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: `0 0 0 0`,
      },
    },
    '& .MuiFormLabel-root': {
      paddingTop: '1rem',
    },
  },
})(TextField)

function MarriageInformation({ errors, framed }) {
  const classes = useStyles()
  const { uploadFile } = useAuth()

  const arr = [
    { value: 'Beran', text: 'Beran' },
    { value: 'Býk', text: 'Býk' },
    { value: 'Blíženci', text: 'Blíženci' },
    { value: 'Rak', text: 'Rak' },
    { value: 'Lev', text: 'Lev' },
    { value: 'Panna', text: 'Panna' },
    { value: 'Váhy', text: 'Váhy' },
    { value: 'Štír', text: 'Štír' },
    { value: 'Střelec', text: 'Střelec' },
    { value: 'Kozoroh', text: 'Kozoroh' },
    { value: 'Vodnář', text: 'Vodnář' },
    { value: 'Ryby', text: 'Ryby' },
  ]

  const name1Error = errors?.find((error) => error.path === 'name1')?.message
  const name2Error = errors?.find((error) => error.path === 'name2')?.message
  const sign1Error = errors?.find((error) => error.path === 'sign1')?.message
  const sign2Error = errors?.find((error) => error.path === 'sign2')?.message
  const noteError = errors?.find((error) => error.path === 'note')?.message
  const dateError = errors?.find((error) => error.path === 'date')?.message
  const photoError = errors?.find((error) => error.path === 'photo')?.message

  const { formInfo, setFormInfo } = useMarriageOrder()
  const uploadInput = useRef()
  const onButtonClick = () => {
    uploadInput.current.click()
  }

  const onFileUploaded = (res) => {
    setFormInfo({ ...formInfo, photo: res, isUploading: false })
  }

  const handlePhotoUpload = (e) => {
    setFormInfo({ ...formInfo, isUploading: true })
    uploadFile('/cart/upload', e.target.files[0], onFileUploaded)
  }

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Grid item xs={12} md={12} style={{ borderBottom: '1px solid black' }}>
          <Typography className={classes.title1}>Informace</Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          {!!framed && (
            <FrameColorSelect
              value={formInfo.colorSelect}
              onChange={(e) =>
                setFormInfo({ ...formInfo, colorSelect: e.target.value })
              }
            ></FrameColorSelect>
          )}
          <Typography className={classes.title2} style={{ paddingTop: '1rem' }}>
            Křestní jména novomanželů
          </Typography>
          <Hidden smDown>
            <Grid
              item
              md={12}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Grid item md={5}>
                <TextField
                  className={classes.textFieldTextBlack}
                  id="meno1"
                  placeholder="Matyáš"
                  variant="outlined"
                  value={formInfo.name1}
                  error={!!name1Error}
                  onChange={(e) =>
                    setFormInfo({ ...formInfo, name1: e.target.value })
                  }
                  helperText={name1Error}
                ></TextField>
              </Grid>

              <Grid
                item
                md={2}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <AddIcon className={classes.plusSymbol}></AddIcon>
              </Grid>
              <Grid item md={5}>
                <TextField
                  className={classes.textFieldTextBlack}
                  id="meno2"
                  placeholder="Petra"
                  variant="outlined"
                  value={formInfo.name2}
                  error={!!name2Error}
                  onChange={(e) =>
                    setFormInfo({ ...formInfo, name2: e.target.value })
                  }
                  helperText={name2Error}
                ></TextField>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Grid item xs={12} style={{ width: '100%' }}>
                <TextField
                  className={classes.textFieldTextBlack}
                  id="meno1"
                  placeholder="Matyáš"
                  variant="outlined"
                  value={formInfo.name1}
                  onChange={(e) =>
                    setFormInfo({ ...formInfo, name1: e.target.value })
                  }
                ></TextField>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <AddIcon className={classes.plusSymbol}></AddIcon>
              </Grid>
              <Grid item xs={12} style={{ width: '100%' }}>
                <TextField
                  className={classes.textFieldTextBlack}
                  id="meno2"
                  placeholder="Petra"
                  variant="outlined"
                  value={formInfo.name2}
                  onChange={(e) =>
                    setFormInfo({ ...formInfo, name2: e.target.value })
                  }
                ></TextField>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography
            className={classes.title2}
            style={{ paddingTop: '2rem', paddingBottom: '1rem' }}
          >
            Znamení obou novomanželů
          </Typography>
          <Hidden smDown>
            <Grid
              item
              md={12}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Grid item md={5}>
                <FormControl
                  className={classes.formControl}
                  style={{
                    width: '200px',
                    minWidth: '0',
                    height: '1.1876em',
                    paddingBottom: '6px',
                  }}
                >
                  <Select
                    onChange={(e) =>
                      setFormInfo({ ...formInfo, sign1: e.target.value })
                    }
                    value={formInfo.sign1}
                    className={classes.selectEmpty}
                    error={!!sign1Error}
                    helperText={sign1Error}
                  >
                    {arr.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.text}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* <TextField
                  className={classes.textFieldTextBlack}
                  id="znamenie1"
                  placeholder="např. Ryba"
                  variant="outlined"
                  value={formInfo.sign1}
                  error={!!sign1Error}
                  helperText={sign1Error}
                  onChange={(e) =>
                    setFormInfo({ ...formInfo, sign1: e.target.value })
                  }
                ></TextField> */}
              </Grid>

              <Grid
                item
                md={2}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <AddIcon className={classes.plusSymbol}></AddIcon>
              </Grid>
              <Grid item md={5}>
                <FormControl
                  className={classes.formControl}
                  style={{
                    width: '200px',
                    minWidth: '0',
                    height: '1.1876em',
                    paddingBottom: '6px',
                  }}
                >
                  <Select
                    onChange={(e) =>
                      setFormInfo({ ...formInfo, sign2: e.target.value })
                    }
                    value={formInfo.sign2}
                    className={classes.selectEmpty}
                    error={!!sign2Error}
                    helperText={sign2Error}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    {arr.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.text}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Grid item xs={12} style={{ width: '100%' }}>
                <TextField
                  className={classes.textFieldTextBlack}
                  id="znamenie1"
                  placeholder="např. Ryba"
                  variant="outlined"
                  value={formInfo.sign1}
                  onChange={(e) =>
                    setFormInfo({ ...formInfo, sign1: e.target.value })
                  }
                ></TextField>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <AddIcon className={classes.plusSymbol}></AddIcon>
              </Grid>
              <Grid item xs={12} style={{ width: '100%' }}>
                <TextField
                  className={classes.textFieldTextBlack}
                  id="znamenie2"
                  placeholder="např. Lev"
                  variant="outlined"
                  value={formInfo.sign2}
                  onChange={(e) =>
                    setFormInfo({ ...formInfo, sign2: e.target.value })
                  }
                ></TextField>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography className={classes.title2} style={{ paddingTop: '2rem' }}>
            Napište citát nebo krátkou básničku o lásce
          </Typography>
          <Grid item md={12} style={{ display: 'flex', alignItems: 'center' }}>
            <MyTextFieldBig
              placeholder="Popiště Váš vztah v pár větách. Zavzpomínejte jak a kde jste se poznali, kde proběhli zásnuby…"
              variant="outlined"
              multiline
              rows={4}
              value={formInfo.note}
              error={!!noteError}
              helperText={noteError}
              onChange={(e) =>
                setFormInfo({ ...formInfo, note: e.target.value })
              }
            />
          </Grid>
        </Grid>

        <Grid item md={12} style={{ display: 'flex', paddingTop: '2rem' }}>
          <Hidden smDown>
            <Grid item md={5}>
              <Typography className={classes.title2}>datum svatby</Typography>
              <TextField
                id="date"
                type="date"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                value={formInfo.date}
                error={!!dateError}
                helperText={dateError}
                onChange={(e) =>
                  setFormInfo({ ...formInfo, date: e.target.value })
                }
              />
            </Grid>
            <Grid item md={2}></Grid>
            <Grid item md={5}>
              <Typography className={classes.title2}>
                přibližný Čas svatby
              </Typography>
              <TextField
                id="time"
                type="time"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
                value={formInfo.hour}
                onChange={(e) =>
                  setFormInfo({ ...formInfo, hour: e.target.value })
                }
              />
            </Grid>
          </Hidden>
        </Grid>
        <Hidden mdUp>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Typography className={classes.title2}>datum svatby</Typography>
            <TextField
              id="date"
              type="date"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              value={formInfo.birthDate}
              onChange={(e) =>
                setFormInfo({ ...formInfo, birthDate: e.target.value })
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingTop: '1rem',
            }}
          >
            <Typography className={classes.title2}>
              přibližný Čas svatby
            </Typography>
            <TextField
              id="time"
              type="time"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
              value={formInfo.birthHour}
              onChange={(e) =>
                setFormInfo({ ...formInfo, birthHour: e.target.value })
              }
            />
          </Grid>
        </Hidden>
        <Grid item md={12} style={{ paddingTop: '2rem' }}>
          <Typography className={classes.title2}>Fotka</Typography>
          <Box style={{ paddingTop: '1rem' }}>
            <input
              style={{ display: 'none' }}
              ref={uploadInput}
              type="file"
              multiple={false}
              accept=".jpg, .jpeg, .png, .heic"
              onInput={handlePhotoUpload}
              disabled={formInfo.isUploading}
            />
            <UploadImgButton
              variant="contained"
              color="default"
              component="label"
              htmlFor="imageUpload"
              className={classes.uploadButton}
              onClick={onButtonClick}
              style={{ borderColor: photoError ? '#f44336' : undefined }}
              disabled={formInfo.isUploading}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {formInfo.isUploading ? (
                  <>
                    <LinearProgress style={{ width: '100%' }} />
                    <Typography className={classes.uploadButtonText1}>
                      Nahrávání obrázku
                    </Typography>
                  </>
                ) : formInfo.photo ? (
                  <>
                    <Check></Check>
                    <Typography className={classes.uploadButtonText1}>
                      Obrázek úspěšně uložen
                    </Typography>
                  </>
                ) : (
                  <>
                    <PublishIcon></PublishIcon>
                    <Typography className={classes.uploadButtonText1}>
                      Vybrat soubor
                    </Typography>
                    <Typography className={classes.uploadButtonText2}>
                      (jpg, png, heic)
                    </Typography>
                  </>
                )}
              </Box>
            </UploadImgButton>
            <FormHelperText className={classes.photoError} error={!!photoError}>
              {photoError}
            </FormHelperText>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MarriageInformation
