import React from 'react'
import { Box, Container, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useMediaQuery } from 'react-responsive'
import Cupon from './Cupon'

const useStyles = makeStyles(() => ({
  backTheme: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    minHeight: 'calc(100vh - 188px)',
  },
  title: {
    fontFamily: 'Bodoni',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 36,
    color: '#313131',
  },
  title2: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 20,
    color: '#1B1B1B',

    padding: '2rem 0 1.5rem',
  },
  text: {
    marginTop: '1rem',

    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    color: '#1B1B1B',
  },
  textIco: {
    marginTop: '2rem',

    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    color: '#000000',
  },
  image: {
    width: '100%',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
  },
  checkboxLabel: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    color: '#808080',
  },
}))
/* 
const MyTextField = withStyles(() => ({
  root: {
    width: '100%',
    maxWidth: 514,
    '& .MuiFormLabel-root': {
      paddingTop: '2rem',
    },
  },
}))(TextField)

const MyTextField2 = withStyles(() => ({
  root: {
    width: '100%',
    maxWidth: 514,
    '& .MuiFormLabel-root': {
      paddingTop: '2rem',
    },
    '& .MuiInputBase-root': {
      minHeight: 125,
    },
  },
}))(TextField)

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#1B1B1B'),
    backgroundColor: '#1B1B1B',
    '&:hover': {
      backgroundColor: '#1B1B1B',
    },
  },
}))(Button) */

function ContactComponent() {
  const classes = useStyles()

  const isMobile = useMediaQuery({ query: `(max-width: 959px)` })
  return (
    <Box className={classes.backTheme}>
      <Container style={{ paddingTop: '6rem' }}>
        <Grid container spacing={2} style={{ padding: '2rem 0' }}>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{
                  display: 'flex',
                  justifyContent: isMobile === true ? 'center' : null,
                }}
              >
                <Typography variant="h1" className={classes.title}>
                  Náš příběh
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Typography className={classes.text}>
                  LifeCalendar, ale také Lucka a Marek. Oba odjakživa obdivujeme
                  krásno, design, nápady, ale také přírodu. Naše idea o vkusně
                  zpracovaných vzpomínách vznikla nejspíše někdě na horách, v
                  průběhu času.
                </Typography>
                <Typography className={classes.text}>
                  Nápad dlouho utvářel svou podobu, než se tak opravdu stalo,
                  ale nyní konečně můžeme říci, že do tohoto našeho “projektu”
                  jsme dali opravdu kus sebe. S každým kusem, který nám projde
                  pod rukama expedujeme i špetku lásky.
                </Typography>
                <Typography className={classes.textIco}>
                  Lucie Přikrylová
                  <br />
                  ič: 09142886
                  <br />
                  email:&nbsp;tvuj@lifecalendar.cz
                  <br />
                  tel: +420 739 831 800
                  <br />
                </Typography>
              </Grid>

              {/* <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: isMobile === true ? 'center' : null,
                  }}
                >
                  <Typography className={classes.title2}>
                    Pokud máš jakékoliv otázky, napiš nám!
                  </Typography>
                  Jméno:
                  <MyTextField id="meno" variant="outlined"></MyTextField>
                  Email:
                  <MyTextField id="email" variant="outlined"></MyTextField>
                  Vaše zpráva:
                  <MyTextField2 id="sprava" variant="outlined"></MyTextField2>
                  <FormControlLabel
                    className={classes.checkboxLabel}
                    control={<Checkbox name="checkedC" />}
                    label="Souhlasím se zpracováním osobních údajů"
                  ></FormControlLabel>
                  <Box
                    className={classes.button}
                    style={{
                      paddingBottom: '6rem',
                      paddingRight: isMobile === false ? '8rem' : null,
                    }}
                  >
                    <ColorButton
                      variant="contained"
                      style={{
                        borderRadius: 0,
                        width: '204px',
                        height: '46px',
                      }}
                    >
                      Odeslat zprávu
                    </ColorButton>
                  </Box>
                </Grid>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img className={classes.image} src={'/images/us.webp'}></img>
          </Grid>
        </Grid>
        <Box marginTop="4rem">
          <Cupon />
        </Box>
      </Container>
    </Box>
  )
}

export default ContactComponent
