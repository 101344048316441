import React from "react";

const someStyle = {
  color: "red",
  margin: 150,
};

function LifeCalendarOffer() {
  return <div style={someStyle}>Life Calendar Detail page</div>;
}

export default LifeCalendarOffer;
