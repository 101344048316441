import React, { useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/styles'
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Link,
} from '@material-ui/core'
import { useParams } from 'react-router-dom'
import KidCustomInformation from '../Components/Forms/KidForms/KidCustomInformation'
import KidCustomDetail from '../Components/Forms/KidForms/KidCustomDetail'
import CustomOverview from '../Components/Forms/KidForms/CustomOverview'
import CustomImageComponent from '../Components/Editor/CustomImage'
import ConfirmOrder from '../Components/Dialogs/ConfirmOrder'
import MarriageInformation from '../Components/Forms/MarriageForms/MarriageInformation'
import MarriageDetail from '../Components/Forms/MarriageForms/MarriageDetail'
import { Hidden } from '@material-ui/core'
import CustomImageDialogMobile from '../Components/CustomImageDialogMobile'
import { useMediaQuery } from 'react-responsive'
import { useKidOrder } from '../Context/KidOrderContext'
import { useMarriageOrder } from '../Context/MarriageOrderContext'
import WeddingDataLayerMobile from '../Components/Editor/WeddingDataLayerMobile'
import KidsDataLayer from '../Components/Editor/KidsDataLayer'
import { ImagesProvider } from '../Context/ImagesContext'
import { useEffect } from 'react'
import { object, string, number, date, boolean } from 'yup'

const useStyles = makeStyles(() => ({
  backTheme: {
    width: '100%',
    backgroundColor: 'white',
  },
  upperTextActual: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 12,
    lineHeight: '15px',
    textDecorationLine: 'underline',
    textTransform: 'capitalize',
    color: '#1B1B1B',
  },
  upperText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '15px',
    textTransform: 'capitalize',
    color: '#1B1B1B',
  },
  upperTextHorizontalLine: {
    marginTop: 7,
    width: 113,
    height: 1,
    backgroundColor: 'black',
    margin: '0px 8px',
  },
  upperTextHorizontalLineMobile: {
    marginTop: 7,
    width: 47,
    height: 1,
    backgroundColor: 'black',
    margin: '0px 8px',
  },
  title2: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '17px',
    textTransform: 'uppercase',
    color: '#1B1B1B',
  },
  uploadButton: {
    width: '100%',
    height: 118,
    borderRadius: 0,
  },
  uploadButtonText1: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '138.4%',
    color: '#505050',
  },
  uploadButtonText2: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 10,
    lineHeight: '138.4%',
    color: '#505050',
  },
  nextBackButtonText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '17px',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#FFFFFF',
  },
  btnLinkText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '156.4%',
    textDecorationLine: 'underline',
    color: '#000000',
  },
}))

const BlackColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#1B1B1B'),
    backgroundColor: '#1B1B1B',
    '&:hover': {
      backgroundColor: '#1B1B1B',
    },
  },
}))(Button)

const WhiteColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#FFFFFF'),
    backgroundColor: '#FFFFFF',
    border: '1px solid black',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
}))(Button)

function Editor() {
  const classes = useStyles()

  const { type, framed } = useParams()

  const [state, setState] = useState(0)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [imgDialogOpen, setImgDialogOpen] = useState(false)
  const [formErrors, setFormErrors] = useState()
  const [dimensions, setDimensions] = useState({
    width: null,
    height: null,
  })

  const {
    formInfo: formInfoKids,
    formDetail: formKidsDetail,
    formOverview: formOverviewKids,
    setFormOverview: setFormOverviewKids,
    handleSaveKid,
  } = useKidOrder()
  const {
    formInfo: formInfoMarriage,
    formDetail: formWeddingDetail,
    formOverview: formOverviewMarriage,
    setFormOverview: setFormOverviewMarriage,
    handleSaveMarriage,
  } = useMarriageOrder()

  const isMobile = useMediaQuery({ query: `(max-width: 959px)` })

  function handleCustomImgDialogOpen(tmpState) {
    setImgDialogOpen(tmpState)
  }

  const kidSchemaone = object({
    name: string().required('Vyplňte jméno'),
    surename: string().required('Vyplňte příjmení'),
    message: string().required('Vyplňte zprávu'),
    photo: string()
      .nullable(true)
      .typeError('Vložte fotku')
      .required('Vyplňte fotku'),
  })
  const kidSchematwo = object({
    sign: string().required('Vyplňte znamení'),
    weight: number()
      .typeError('Vyplňte váhu')
      .required('Vyplňte váhu')
      .positive()
      .integer(),
    length: number()
      .required('Vyplňte délku')
      .typeError('Vyplňte délku')
      .positive()
      .integer(),
    birthPlace: string().required('Vyplňte místo narození'),
    birthDate: date().default(() => new Date()),
  })

  const weddingSchemaone = object({
    name1: string().required('Vyplňte jméno'),
    name2: string().required('Vyplňte jméno'),
    sign1: string().required('Vyplňte znamení'),
    sign2: string().required('Vyplňte znamení'),
    note: string().required('Vyplňte poznámku'),
    date: date().default(() => new Date()),
    photo: string()
      .nullable(true)
      .typeError('Vložte fotku')
      .required('Vyplňte fotku'),
  })

  const weddingSchematwo = object({
    place: string().required('Vyplňte místo'),
    song: string().required('Vyplňte píseň'),
    priest: string().required('Vyplňte oddávájícího'),
    procession: string().required('Vyplňte účast'),
    story: string().required('Vyplňte příběh'),
  })

  const overviewSchema = object({
    agreement: boolean().required('Klikněte na pole'),
  })

  async function checkAndSetBtnState() {
    try {
      if (type === 'wedding') {
        if (state === 0)
          await weddingSchemaone.validate(formInfoMarriage, {
            abortEarly: false,
          })
        if (state === 1)
          await weddingSchematwo.validate(formWeddingDetail, {
            abortEarly: false,
          })
        if (state === 2)
          await overviewSchema.validate(formOverviewMarriage, {
            abortEarly: false,
          })
      }

      if (type === 'kids') {
        if (state === 0)
          await kidSchemaone.validate(formInfoKids, { abortEarly: false })
        if (state === 1)
          await kidSchematwo.validate(formKidsDetail, { abortEarly: false })
        if (state === 2)
          await overviewSchema.validate(formOverviewKids, {
            abortEarly: false,
          })
      }

      if (state === 2) {
        if (type === 'kids') {
          handleSaveKid(!!framed)
        } else if (type === 'wedding') {
          handleSaveMarriage(!!framed)
        }
        setDialogOpen(true)
      } else {
        setState(state + 1)
      }
    } catch (formErrors) {
      setFormErrors(formErrors.inner)
    }
  }

  useEffect(() => {
    setFormErrors()
    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }, [state])

  useEffect(() => {
    if (formErrors?.length > 0)
      window.scroll({
        top: 0,
        behavior: 'smooth',
      })
  }, [formErrors])

  return (
    <Box className={classes.backTheme}>
      <Container>
        <Grid container style={{ paddingTop: '6rem' }}>
          <Grid
            item
            xs={12}
            md={12}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Typography
              className={classes.upperText}
              style={state === 0 ? { textDecorationLine: 'underline' } : null}
            >
              Informace
            </Typography>
            <Box
              className={
                isMobile === true
                  ? classes.upperTextHorizontalLineMobile
                  : classes.upperTextHorizontalLine
              }
            ></Box>
            <Typography
              className={classes.upperText}
              style={state === 1 ? { textDecorationLine: 'underline' } : null}
            >
              Detaily
            </Typography>
            <Box
              className={
                isMobile === true
                  ? classes.upperTextHorizontalLineMobile
                  : classes.upperTextHorizontalLine
              }
            ></Box>
            <Typography
              className={classes.upperText}
              style={state === 2 ? { textDecorationLine: 'underline' } : null}
            >
              Přehled
            </Typography>
          </Grid>
        </Grid>
        <Box style={{ marginTop: '1rem', display: 'flex' }}>
          <Box
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            {type === 'kids' && (
              <>
                {state === 0 && (
                  <KidCustomInformation errors={formErrors} framed={framed} />
                )}
                {state === 1 && <KidCustomDetail errors={formErrors} />}
                {state === 2 && (
                  <CustomOverview
                    values={formOverviewKids}
                    onFormChange={(values) => setFormOverviewKids(values)}
                  />
                )}
              </>
            )}
            {type === 'wedding' && (
              <>
                {state === 0 && (
                  <MarriageInformation errors={formErrors} framed={framed} />
                )}
                {state === 1 && <MarriageDetail errors={formErrors} />}
                {state === 2 && (
                  <CustomOverview
                    values={formOverviewMarriage}
                    onFormChange={(values) => setFormOverviewMarriage(values)}
                  />
                )}
              </>
            )}
            <Hidden mdUp>
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingTop: '1rem',
                }}
              ></Grid>
            </Hidden>
            <Box
              style={{
                display: 'flex',
                paddingTop: '1rem',
                borderTop: '1px solid black',
                justifyContent: 'space-between',
                marginBottom: isMobile ? '1rem' : '-1.5rem',
              }}
            >
              <Box>
                {state !== 0 && (
                  <WhiteColorButton
                    onClick={() => {
                      setState(state - 1)
                    }}
                    variant="contained"
                    style={{ borderRadius: 0, width: 140, height: 46 }}
                    className={classes.nextBackButtonText}
                  >
                    Zpět
                  </WhiteColorButton>
                )}
              </Box>

              <Box>
                <BlackColorButton
                  disabled={
                    (state === 2 &&
                      type === 'wedding' &&
                      !formOverviewMarriage.agreement) ||
                    (state === 2 &&
                      type === 'kids' &&
                      !formOverviewKids.agreement)
                  }
                  onClick={checkAndSetBtnState}
                  variant="contained"
                  style={{ borderRadius: 0, width: 'auto', height: 46 }}
                >
                  <Typography className={classes.nextBackButtonText}>
                    {state === 0 && 'Další'}
                    {state === 1 && 'Pokračovat na přehled'}
                    {state === 2 && 'Vložit do košíku'}
                  </Typography>
                </BlackColorButton>
              </Box>
            </Box>
            <Hidden mdUp>
              <WhiteColorButton
                style={{
                  borderRadius: 0,
                  width: 140,
                  height: 46,
                  justifyContent: 'center',
                  position: 'sticky',
                  bottom: 20,
                  marginBottom: 20,
                  left: 'calc(50% - 70px)',
                  padding: '15px',
                }}
              >
                <Link
                  style={{ textDecoration: 'none', justifyContent: 'center' }}
                  className={classes.btnLinkText}
                  component="button"
                  variant="body2"
                  onClick={() => handleCustomImgDialogOpen(true)}
                >
                  NÁHLED
                </Link>
              </WhiteColorButton>
            </Hidden>
          </Box>

          <Hidden smDown>
            <Box
              style={{
                flex: 1,
                padding: '32px 0 0 1rem',
              }}
            >
              {type === 'kids' && (
                <ImagesProvider>
                  <CustomImageComponent
                    setDimensions={setDimensions}
                    baseImage="editor/kids_base.png"
                  >
                    <KidsDataLayer dimensions={dimensions} />
                  </CustomImageComponent>
                </ImagesProvider>
              )}
              {type === 'wedding' && (
                <ImagesProvider>
                  <CustomImageComponent
                    setDimensions={setDimensions}
                    baseImage="editor/wedding_base.png"
                  >
                    <WeddingDataLayerMobile dimensions={dimensions} />
                  </CustomImageComponent>
                </ImagesProvider>
              )}
            </Box>
          </Hidden>
        </Box>
        {!isMobile && <Box width="100%" height="4rem"></Box>}
      </Container>
      <ConfirmOrder open={dialogOpen} />
      {type === 'kids' && (
        <ImagesProvider>
          <CustomImageDialogMobile
            open={imgDialogOpen}
            setOpen={setImgDialogOpen}
            setDimensions={setDimensions}
            baseImage={'editor/kids_base.png'}
          >
            <KidsDataLayer dimensions={dimensions} />
          </CustomImageDialogMobile>
        </ImagesProvider>
      )}
      {type === 'wedding' && (
        <ImagesProvider>
          <CustomImageDialogMobile
            open={imgDialogOpen}
            setOpen={setImgDialogOpen}
            baseImage={'editor/wedding_base.png'}
            setDimensions={setDimensions}
          >
            <WeddingDataLayerMobile dimensions={dimensions} />
          </CustomImageDialogMobile>
        </ImagesProvider>
      )}
    </Box>
  )
}

export default Editor
