import React from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';
import { Box, TextField } from '@material-ui/core';
import { useCartProvider } from '../../../Context/CartContext';

const useStyles = makeStyles(() => ({
  backTheme: {
    width: '100%',
    backgroundColor: '#E5E5E5',
  },
  summartyTitle: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',

    paddingTop: '1rem',
    paddingBottom: '1rem',
    paddingLeft: '2rem',
    borderBottom: '1px black solid',
    backgroundColor: 'white',
  },
  billImage: {
    maxWidth: 35,
    maxHeight: 'auto',
  },
  summartyTitle2: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 12,
    lineHeight: '20px',
    color: '#000000',
    textAlign: 'left',
  },
  summartyTitle3: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '20px',
    color: '#000000',
    textAlign: 'right',

    paddingRight: '1rem',
  },
}));

const MyTextField = withStyles({
  root: {
    paddingBottom: '0rem',
  },
})(TextField);

function CartBillContactDetails() {
  const classes = useStyles();

  const { shipping, setShipping } = useCartProvider();

  return (
    <Box className={classes.backTheme}>
      <Box style={{ display: 'inline-flex', width: '100%' }}>
        <Box pb="1rem" style={{ width: '100%' }}>
          <MyTextField
            size="small"
            id="firstname"
            placeholder="Jméno"
            variant="outlined"
            value={shipping.delivery_address.First_name}
            onChange={(e) =>
              setShipping({
                ...shipping,
                delivery_address: {
                  ...shipping.delivery_address,
                  First_name: e.target.value,
                },
              })
            }
          ></MyTextField>
          <MyTextField
            size="small"
            id="lastname"
            placeholder="Přijímení"
            variant="outlined"
            value={shipping.delivery_address.Last_name}
            onChange={(e) =>
              setShipping({
                ...shipping,
                delivery_address: {
                  ...shipping.delivery_address,
                  Last_name: e.target.value,
                },
              })
            }
          ></MyTextField>
          <MyTextField
            size="small"
            id="address"
            placeholder="Ulice"
            variant="outlined"
            value={shipping.delivery_address.Street}
            onChange={(e) =>
              setShipping({
                ...shipping,
                delivery_address: {
                  ...shipping.delivery_address,
                  Street: e.target.value,
                },
              })
            }
          ></MyTextField>
          <MyTextField
            size="small"
            id="city"
            placeholder="Město"
            variant="outlined"
            value={shipping.delivery_address.City}
            onChange={(e) =>
              setShipping({
                ...shipping,
                delivery_address: {
                  ...shipping.delivery_address,
                  City: e.target.value,
                },
              })
            }
          ></MyTextField>
          <MyTextField
            size="small"
            id="zipCode"
            placeholder="PSČ"
            variant="outlined"
            value={shipping.delivery_address.Postal_code}
            onChange={(e) =>
              setShipping({
                ...shipping,
                delivery_address: {
                  ...shipping.delivery_address,
                  Postal_code: e.target.value,
                },
              })
            }
          ></MyTextField>
          <MyTextField
            size="small"
            id="country"
            placeholder="Stát"
            variant="outlined"
            value={shipping.delivery_address.Country}
            onChange={(e) =>
              setShipping({
                ...shipping,
                delivery_address: {
                  ...shipping.delivery_address,
                  Country: e.target.value,
                },
              })
            }
          ></MyTextField>
        </Box>
      </Box>
    </Box>
  );
}

export default CartBillContactDetails;
