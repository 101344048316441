import React from 'react'

import UserOrders from '../Components/UserOrders'

function UserOrdersPage() {
  return (
    <>
      <UserOrders />
    </>
  )
}

export default UserOrdersPage
