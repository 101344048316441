import React, { useRef, useLayoutEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box, Typography, CircularProgress } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  backTheme: {
    width: '100%',
    backgroundColor: 'white',
    margin: 5,
  },
  horizontalLineRight: {
    marginLeft: 16,
    width: 11,
    backgroundColor: 'black',
    height: 1,
  },
  verticalLineRight: {
    height: 'calc(50% - 40px)',
    width: 1,
    marginLeft: 21,
    backgroundColor: 'black',
  },
  verticalLineBottom: {
    marginTop: 5,
    height: 11,
    width: 1,
    backgroundColor: 'black',
  },
  horizontalLineBottom: {
    marginTop: 10,
    width: 'calc(50% - 35px)',
    height: 1,
    backgroundColor: 'black',
  },
}))

const RESET_TIMEOUT = 100

function CustomImageComponent({ children, baseImage, setDimensions }) {
  const classes = useStyles()
  const [loaded, setLoaded] = useState(false)
  const targetRef = useRef()

  let movement_timer = null

  const onDimensionsChange = () => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      })
    }
  }

  useLayoutEffect(() => {
    loaded && onDimensionsChange()
  }, [loaded])

  window.addEventListener('resize', () => {
    clearInterval(movement_timer)
    movement_timer = setTimeout(onDimensionsChange, RESET_TIMEOUT)
  })

  return (
    <Box style={{ position: 'sticky', top: '80px' }}>
      <Box style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Box>
          <Box
            ref={targetRef}
            border="1px solid #ddd"
            position="relative"
            style={{
              aspectRatio: '2501 / 3126',
              maxHeight: 'calc(100vh - 7rem - 143px)',
            }}
          >
            {!loaded && (
              <Box
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 1,
                  backgroundColor: 'white',
                }}
              >
                <CircularProgress />
              </Box>
            )}
            {children}
            <img
              onLoad={() => setTimeout(() => setLoaded(true), 1000)}
              style={{ width: '100%' }}
              src={`/images/${baseImage}`}
            />
          </Box>
          <Box style={{ display: 'flex', paddingTop: '1rem' }}>
            <Box className={classes.verticalLineBottom}></Box>
            <Box className={classes.horizontalLineBottom}></Box>
            <Typography style={{ marginLeft: 7, marginRight: 7 }}>
              40 CM
            </Typography>
            <Box className={classes.horizontalLineBottom}></Box>
            <Box className={classes.verticalLineBottom}></Box>
          </Box>
        </Box>

        <Box style={{ paddingLeft: '1rem' }}>
          <Box className={classes.horizontalLineRight}></Box>
          <Box className={classes.verticalLineRight}></Box>
          <Typography
            style={{
              marginTop: 7,
              marginBottom: 7,
              textAlign: 'center',
              whiteSpace: 'nowrap',
            }}
          >
            50 CM
          </Typography>
          <Box className={classes.verticalLineRight}></Box>
          <Box className={classes.horizontalLineRight}></Box>
        </Box>
        <Box flex={1}></Box>
      </Box>
      <Typography style={{ opacity: 0.5 }}>
        *automatický náhled, nejedná se o finální verzi
      </Typography>
    </Box>
  )
}

export default CustomImageComponent
