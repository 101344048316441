import React from 'react'
import { makeStyles, withStyles } from '@material-ui/styles'
import { Box, Container, Grid, Button } from '@material-ui/core'
import ConnectorLine from '../Components/ConnectorLine'

const useStyles = makeStyles(() => ({
  backTheme: {
    backgroundColor: 'white',
    width: '100%',
  },
  instaImage: {
    backgroundColor: '#C4C4C4',
    display: 'inline-block',
    height: 265,
    background: 'black',
    margin: '1rem',
  },
  imagesGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontStyle: 'normal',
    fontSize: 14,
    lineHeight: '143.4%',
    textAlign: 'center',
    textTransform: 'uppercase',
    textDecoration: 'none',
    '&:hover': {
      fontWeight: '600',
    },
  },
}))

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#1B1B1B'),
    backgroundColor: '#1B1B1B',
    '&:hover': {
      backgroundColor: '#1B1B1B',
    },
  },
}))(Button)

function InstagramComponent() {
  const classes = useStyles()
  return (
    <Box className={classes.backTheme}>
      <Container>
        <Box width="100%" height="2rem"></Box>
        <ConnectorLine />
        <Box width="100%" height="2rem"></Box>
        <Grid container style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
          <Grid item md={3} xs={12} className={classes.imagesGrid}>
            <img
              src="/images/instagram/1.webp"
              className={classes.instaImage}
            />
          </Grid>
          <Grid item md={3} xs={12} className={classes.imagesGrid}>
            <img
              src="/images/instagram/2.webp"
              className={classes.instaImage}
            />
          </Grid>
          <Grid item md={3} xs={12} className={classes.imagesGrid}>
            <img
              src="/images/instagram/3.webp"
              className={classes.instaImage}
            />
          </Grid>
          <Grid item md={3} xs={12} className={classes.imagesGrid}>
            <img
              src="/images/instagram/4.webp"
              className={classes.instaImage}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '1rem',
            }}
          >
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.instagram.com/lifecalendar_cz/"
              className={classes.text}
            >
              <ColorButton variant="contained">
                Sledujte nás na instagramu
              </ColorButton>
            </a>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default InstagramComponent
