import { useState, useEffect } from 'react'

function getInitialValue(key) {
  const storageValue = localStorage.getItem(key)
  if (storageValue) {
    return JSON.parse(storageValue)
  }
  return null
}

export default function useLocalStorage(key) {
  const [currentValue, setCurrentValue] = useState(() => getInitialValue(key))

  // on every render, re-subscribe to the storage event
  useEffect(() => {
    const handler = (e) => {
      if (e.storageArea === localStorage && e.key === key) {
        if (e.newValue) {
          setCurrentValue(JSON.parse(e.newValue))
        }
      }
    }
    window.addEventListener('storage', handler)
    return () => {
      window.removeEventListener('storage', handler)
    }
  })

  // update localStorage
  useEffect(() => {
    if (currentValue) {
      localStorage.setItem(key, JSON.stringify(currentValue))
    }
  }, [key, currentValue])

  return [currentValue, setCurrentValue]
}
