import React from "react";

import LoginRegister from "../Components/LoginRegister";

function Login() {
  return (
    <>
      <LoginRegister />
    </>
  );
}

export default Login;
