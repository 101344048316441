import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box, Container, Grid, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  backTheme: {
    width: '100%',
    backgroundColor: '#E5E5E5',
    paddingBottom: '5rem' /* ! mimo Gridy spodok sive */,
  },
  historyTitle: {
    marginLeft: '2rem',

    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 14,
    lineHeight: '17px',
    textDecorationLine: 'underline',
    textTransform: 'uppercase',
    color: '#000000',
  },
  profileTitle: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '17px',
    textTransform: 'uppercase',
    color: '#000000',
  },
  emailTitle: {
    fontFamily: 'Bodoni',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 18,
    lineHeight: '173.5%',
    letterSpacing: '0.13em',
    color: '#1B1B1B',
  },
  oderNumberTitle: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '20px',
    color: '#000000',
  },
  downloadBillTitle: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 14,
    lineHeight: '20px',
    textDecorationLine: 'underline',
    color: '#000000',
  },
  itemBigTitle: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 18,
    lineHeight: '20px',
    color: '#000000',
  },
  itemBigTitle2: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 14,
    lineHeight: '20px',
    color: '#000000',
  },
  itemBigText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '20px',
    color: '#000000',
  },
  itemSmallText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 10,
    lineHeight: '20px',
    color: '#000000',
  },
  billImage: {
    borderBottom: '20px solid white',
    borderTop: '20px solid white',
    borderLeft: '15px solid white',
    borderRight: '15px solid white',
    maxWidth: 91,
    maxHeight: 'auto',
  },
  billImageBox: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
}))

function UserOrders() {
  const classes = useStyles()

  return (
    <Box className={classes.backTheme}>
      <Container>
        <Grid container style={{ paddingTop: '8rem', paddingBottom: '1rem' }}>
          <Grid
            item
            xs={6}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              borderBottom: '1px solid black',
              maxHeight: '2rem',
            }}
          >
            <Typography className={classes.emailTitle}>
              emailuživatele@seznam.cz
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              borderBottom: '1px solid black',
              maxHeight: '2rem',
            }}
          >
            <Link to="/profile" className={classes.profileTitle}>
              MŮJ PROFIL
            </Link>
            <Typography className={classes.historyTitle}>
              HISTORIE OBJEDNÁVEK
            </Typography>
          </Grid>
        </Grid>
        <Grid container style={{ border: '1px solid black' }}>
          <Grid
            item
            xs={6}
            style={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'white',
              minHeight: 51,
              borderBottom: '1px solid black',
            }}
          >
            <Typography
              className={classes.oderNumberTitle}
              style={{ paddingLeft: '1rem' }}
            >
              Číslo objednávky: 003
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              backgroundColor: 'white',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              minHeight: 51,
              borderBottom: '1px solid black',
            }}
          >
            <Typography
              className={classes.downloadBillTitle}
              style={{ paddingRight: '1rem' }}
            >
              Stáhnout fakturu
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box className={classes.billImageBox}>
              <img
                className={classes.billImage}
                alt="OrderKidImage2"
                src={'/images/OrderKidImage2.png'}
              ></img>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <Typography
              className={classes.itemBigTitle}
              style={{ paddingTop: '1rem' }}
            >
              Personalizovaný obraz - dítě
            </Typography>
            <Typography
              className={classes.itemBigTitle2}
              style={{ paddingTop: '1rem' }}
            >
              Datum objednávky:
            </Typography>
            <Typography className={classes.itemBigText}>12. 2. 2021</Typography>
            <Typography
              className={classes.itemBigTitle2}
              style={{ paddingTop: '1rem' }}
            >
              Metoda platby:
            </Typography>
            <Typography
              className={classes.itemBigText}
              style={{ paddingBottom: '4rem' }}
            >
              Kreditní karta/debitní karta .........6549
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}
          >
            <Typography className={classes.itemBigTitle2}>
              Konečná suma:
            </Typography>
            <Typography
              className={classes.itemBigText}
              style={{ paddingBottom: '4rem' }}
            >
              1600 Kč
            </Typography>
          </Grid>
          {/* 
          <Grid
            item
            xs={3}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box className={classes.billImageBox}>
              <img
                className={classes.billImage}
                src={"/images/OrderKidImage2.png"}
              ></img>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <Typography
              className={classes.itemBigTitle}
              style={{ paddingTop: "1rem" }}
            >
              Personalizovaný obraz - dítě
            </Typography>
            <Typography
              className={classes.itemBigTitle2}
              style={{ paddingTop: "1rem" }}
            >
              Datum objednávky:
            </Typography>
            <Typography className={classes.itemBigText}>12. 2. 2021</Typography>
            <Typography
              className={classes.itemBigTitle2}
              style={{ paddingTop: "1rem" }}
            >
              Metoda platby:
            </Typography>
            <Typography
              className={classes.itemBigText}
              style={{ paddingBottom: "4rem" }}
            >
              Kreditní karta/debitní karta .........6549
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <Typography className={classes.itemBigTitle2}>
              Konečná suma:
            </Typography>
            <Typography
              className={classes.itemBigText}
              style={{ paddingBottom: "4rem" }}
            >
              1600 Kč
            </Typography>
          </Grid>
          */}
        </Grid>
        <Grid
          container
          style={{
            borderBottom: '1px solid black',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
          }}
        >
          <Grid item xs={6}></Grid>
          <Grid
            item
            xs={3}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              padding: '1rem 0',
            }}
          >
            <Typography className={classes.itemBigText}>Mezisoučet:</Typography>
            <Typography className={classes.itemBigText}>Doručení:</Typography>
            <Typography className={classes.itemBigText}>
              Konečná suma:
            </Typography>
            <Typography className={classes.itemSmallText}>
              (konečná suma včetně DPH)
            </Typography>
          </Grid>
          <Grid item xs={3} style={{ padding: '1rem 0' }}>
            <Typography className={classes.itemBigText}>1600 Kč</Typography>
            <Typography className={classes.itemBigText}>60 Kč</Typography>
            <Typography className={classes.itemBigText}>1660 kč</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            borderBottom: '1px solid black',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
          }}
        >
          <Grid item xs={6} style={{ padding: '1rem 6rem' }}>
            <Typography className={classes.itemBigTitle}>
              Doručovací adresa
            </Typography>
            <Typography className={classes.itemBigText}>
              Lucie Přikrylová
            </Typography>
            <Typography className={classes.itemBigText}>
              Náměstí Biskupa Bruna 3387/4
            </Typography>
            <Typography className={classes.itemBigText}>
              702 00, Ostrava CZ
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ padding: '1rem 0' }}>
            <Typography className={classes.itemBigTitle}>
              Fakturační adresa:
            </Typography>
            <Typography className={classes.itemBigText}>
              Lucie Přikrylová
            </Typography>
            <Typography className={classes.itemBigText}>
              {' '}
              Náměstí Biskupa Bruna 3387/4
            </Typography>
            <Typography className={classes.itemBigText}>
              702 00, Ostrava CZ
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default UserOrders
