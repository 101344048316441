import React from 'react'
import { makeStyles, withStyles } from '@material-ui/styles'
import { Grid, TextField, Typography } from '@material-ui/core'
import { useMarriageOrder } from '../../../Context/MarriageOrderContext'

const useStyles = makeStyles(() => ({
  title1: {
    fontFamily: 'Bodoni',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 18,
    lineHeight: '173.5%',
    letterSpacing: '0.13em',
    textTransform: 'uppercase',
    color: '#1B1B1B',
  },
  title2: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '17px',
    textTransform: 'uppercase',
    color: '#1B1B1B',
  },
  title3: {
    marginLeft: '0.5rem',

    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '17px',
    color: '#1B1B1B',
  },
  textFieldTextGrey: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 10,
    lineHeight: '138.4%',
    color: '#C4C4C4',
  },
  description: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '17px',
    color: '#1B1B1B',
  },
}))

const MyTextField = withStyles({
  root: {
    width: '100%',
    height: 'auto',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: `0 0 0 0`,
      },
    },
    '& .MuiFormLabel-root': {
      paddingTop: '1rem',
    },
  },
})(TextField)

const MyTextFieldBig = withStyles({
  root: {
    width: '100%',
    height: 'auto',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: `0 0 0 0`,
      },
    },
    '& .MuiFormLabel-root': {
      paddingTop: '1rem',
    },
  },
})(TextField)

function MarriageDetail({ errors }) {
  const classes = useStyles()
  const { formDetail, setFormDetail } = useMarriageOrder()

  const placeError = errors?.find((error) => error.path === 'place')?.message
  const songError = errors?.find((error) => error.path === 'song')?.message
  const priestError = errors?.find((error) => error.path === 'priest')?.message
  const storyError = errors?.find((error) => error.path === 'story')?.message
  const processionError = errors?.find(
    (error) => error.path === 'procession'
  )?.message

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Grid item xs={12} md={12} style={{ borderBottom: '1px solid black' }}>
          <Typography className={classes.title1}>Informace</Typography>
        </Grid>
        <Grid item xs={12} md={12} style={{ paddingTop: '1rem' }}>
          <Typography className={classes.title2}>
            Místo obřadu (nebo Vaší svatby)
          </Typography>
          <MyTextField
            className={classes.textFieldTextGrey}
            id="miesto"
            placeholder="např. hostinec Pod dubem"
            variant="outlined"
            value={formDetail.place}
            error={!!placeError}
            helperText={placeError}
            onChange={(e) =>
              setFormDetail({ ...formDetail, place: e.target.value })
            }
          ></MyTextField>
        </Grid>

        <Grid item xs={12} md={12} style={{ paddingTop: '2rem' }}>
          <Typography variant="span" className={classes.title2}>
            Svatební píseň
          </Typography>
          <Typography variant="span" className={classes.title3}>
            (píseň pro svatební obřad, nebo pro první tanec)
          </Typography>
          <MyTextField
            className={classes.textFieldTextGrey}
            id="piesen"
            placeholder="např. Can't help falling in love - Elvis Presley"
            variant="outlined"
            value={formDetail.song}
            error={!!songError}
            helperText={songError}
            onChange={(e) =>
              setFormDetail({ ...formDetail, song: e.target.value })
            }
          ></MyTextField>
        </Grid>

        <Grid item xs={12} md={12} style={{ paddingTop: '2rem' }}>
          <Typography className={classes.title2}>Oddáni</Typography>
          <MyTextField
            className={classes.textFieldTextGrey}
            id="menoFarara"
            placeholder="např. farářem Martinem Solčanským"
            variant="outlined"
            value={formDetail.priest}
            error={!!priestError}
            helperText={priestError}
            onChange={(e) =>
              setFormDetail({ ...formDetail, priest: e.target.value })
            }
          ></MyTextField>
        </Grid>

        <Grid item xs={12} md={12} style={{ paddingTop: '2rem' }}>
          <Typography className={classes.title2}>Za účasti</Typography>
          <MyTextField
            className={classes.textFieldTextGrey}
            id="zaUcasti"
            placeholder="např. 68 hostů včetně rodiny a přátel"
            variant="outlined"
            value={formDetail.procession}
            error={!!processionError}
            helperText={processionError}
            onChange={(e) =>
              setFormDetail({ ...formDetail, procession: e.target.value })
            }
          ></MyTextField>
        </Grid>

        <Grid item xs={12} md={12}>
          <Typography className={classes.title2} style={{ paddingTop: '2rem' }}>
            NÁŠ PŘÍBĚH
          </Typography>
          <Typography
            className={classes.description}
            style={{ paddingTop: '0.5rem' }}
          >
            Zavzpomínejte, jak jste se poznali a na Vaší společnou životní
            cestu. Nebojte se zmínit krásné momenty, nebo důležité události
          </Typography>
          <MyTextFieldBig
            id="historiaVztahu"
            placeholder="Popiště v pár větách historii Vašeho vztahu"
            variant="outlined"
            multiline
            rows={4}
            value={formDetail.story}
            error={!!storyError}
            helperText={storyError}
            onChange={(e) =>
              setFormDetail({ ...formDetail, story: e.target.value })
            }
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MarriageDetail
