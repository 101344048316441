import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Container, Box, Grid, Typography, Button } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  backTheme: {
    width: '100%',
    minHeight: 'calc(100vh - 188px)',
    backgroundColor: '#E5E5E5',
  },
  text: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '20px',
    textAlign: 'center',
    color: '#000000',
  },
}))

function State() {
  const classes = useStyles()
  const { push } = useHistory()
  const { state } = useParams()

  useEffect(
    () =>
      window !== window.parent ? window.parent.postMessage(state, '*') : null,
    []
  )

  return (
    <Box className={classes.backTheme}>
      <Container maxWidth="lg">
        <Grid container style={{ paddingTop: '6rem' }}>
          <Grid
            xs={12}
            sm={12}
            md={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              paddingTop: '2rem',
              paddingBottom: '8rem',
            }}
          >
            <img
              src={
                '/images/' +
                (state === 'success'
                  ? 'ThanksIcon.png'
                  : state === 'processing'
                  ? ''
                  : 'Error.png')
              }
            ></img>
            <Typography
              variant="h1"
              style={{ marginTop: '1rem', textAlign: 'center' }}
            >
              {state === 'success' && 'Objednávka úspěšně zaplacena'}
              {state === 'error' && 'Vyskytla se chyba při platbě objednávky'}
              {state === 'processing' && 'Platba se zpracovává'}
            </Typography>
            <Box style={{ maxWidth: '540px' }}>
              <Typography
                className={classes.text}
                style={{ paddingTop: '1rem' }}
              >
                {state === 'success' &&
                  'Vaše objednávka je zaplacená a my se pouštíme do práce.'}
                {state === 'error' &&
                  'Budeme Vás kontaktovat s informacemi k platbě převodem.'}
                {state === 'processing' &&
                  'Vaše platba je stále v procesu zpracování a o jejím výsledku Vás budeme informovat.'}
              </Typography>
            </Box>
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              marginTop="2rem"
            >
              <Button onClick={() => push('/')}>Pokračovat na homepage</Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default State
