import React, { useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/styles'
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  backTheme: {
    width: '100%',
    backgroundColor: '#E5E5E5',
    paddingBottom: '4rem',
  },
  emailTitle: {
    fontFamily: 'Bodoni',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 18,
    lineHeight: '173.5%',
    letterSpacing: '0.13em',
    color: '#1B1B1B',
  },
  historyTitle: {
    marginLeft: '2rem',

    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '17px',
    textTransform: 'uppercase',
    color: '#000000',
  },
  profileTitle: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 14,
    lineHeight: '17px',
    textDecorationLine: 'underline',
    textTransform: 'uppercase',
    color: '#000000',
  },
  deleteIcon: {
    maxHeight: 43,
    marginRight: '1rem',
  },
}))

const MyTextField = withStyles({
  root: {
    paddingBottom: '0',
  },
})(TextField)

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#1B1B1B'),
    backgroundColor: '#1B1B1B',
    '&:hover': {
      backgroundColor: '#1B1B1B',
    },
  },
}))(Button)

function ProfilComponent() {
  const classes = useStyles()
  const [profileData, setProfileData] = useState({
    firstname: 'Lucie',
    lastname: 'Přikrylová',
    email: 'ja@lucieprikrylova.cz',
    phone: '158',
  })

  return (
    <Box className={classes.backTheme}>
      <Container>
        <Grid container style={{ paddingTop: '8rem', paddingBottom: '1rem' }}>
          <Grid
            item
            xs={6}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              borderBottom: '1px solid black',
              maxHeight: '2rem',
            }}
          >
            <Typography className={classes.emailTitle}>
              emailuživatele@seznam.cz
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              borderBottom: '1px solid black',
              maxHeight: '2rem',
            }}
          >
            <Typography className={classes.profileTitle}>MŮJ PROFIL</Typography>
            <Link to="/orders" className={classes.historyTitle}>
              HISTORIE OBJEDNÁVEK
            </Link>
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            border: '1px solid black',
          }}
        >
          <Grid
            item
            xs={1}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: '2rem',
            }}
          >
            <img src={'/images/ObjednavkaProfilHumanIconImage.png'}></img>
          </Grid>
          <Grid
            item
            xs={5}
            style={{ paddingRight: '1rem', paddingTop: '1.5rem' }}
          >
            <MyTextField
              id="firstname"
              placeholder="Jméno"
              variant="outlined"
              value={profileData.firstname}
              onChange={(e) =>
                setProfileData({ ...profileData, firstname: e.target.value })
              }
            />
            <MyTextField
              id="email"
              placeholder="Email"
              variant="outlined"
              value={profileData.email}
              onChange={(e) =>
                setProfileData({ ...profileData, email: e.target.value })
              }
            />
          </Grid>
          <Grid
            item
            xs={5}
            style={{ paddingLeft: '1rem', paddingTop: '1.5rem' }}
          >
            <MyTextField
              id="lastname"
              placeholder="Přijmení"
              variant="outlined"
              value={profileData.lastname}
              onChange={(e) =>
                setProfileData({ ...profileData, lastname: e.target.value })
              }
            />
            <MyTextField
              id="phone"
              placeholder="Telefonní číslo"
              variant="outlined"
              value={profileData.phone}
              onChange={(e) =>
                setProfileData({ ...profileData, phone: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid
            item
            xs={11}
            style={{
              paddingTop: '2rem',
              paddingBottom: '1rem',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <ColorButton
                variant="contained"
                style={{
                  borderRadius: 0,
                  width: 194,
                  height: 45,
                }}
                onClick={() => console.log(profileData)}
              >
                Upravit
              </ColorButton>
            </Box>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid
            item
            xs={1}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '8rem',
            }}
          >
            <img src="/images/ObjednavkaProfilAdressIconImage.png"></img>
          </Grid>
          <Grid
            item
            xs={10}
            style={{ border: '1px solid black', marginBottom: '8rem' }}
          >
            <Grid container>
              <Grid item xs={6} style={{ padding: '1rem' }}>
                <Typography>Doručovací adresa</Typography>
                <Typography>Lucie Přikrylová</Typography>
                <Typography>Náměstí Biskupa Bruna 3387/4</Typography>
                <Typography>702 00, Ostrava CZ</Typography>
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  paddingRight: '1rem',
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                  }}
                >
                  <Box className={classes.deleteIcon}>
                    <IconButton>
                      <DeleteIcon></DeleteIcon>
                    </IconButton>
                  </Box>
                  <ColorButton
                    variant="contained"
                    style={{
                      borderRadius: 0,
                      width: 194,
                      height: 45,
                    }}
                  >
                    Upravit
                  </ColorButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} style={{ marginBottom: '8rem' }}></Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default ProfilComponent
