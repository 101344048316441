import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { Box, Container, Grid, Typography, Button } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import Lightbox from 'react-image-lightbox'
import { Slide } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'
import Cupon from '../Cupon'
import TypeSelect from './TypeSelect'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 40,
    [theme.breakpoints.down('xs')]: {
      fontSize: 32,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '40px'
    }
  },
  backTheme: {
    backgroundColor: '#FFFFFF',
    width: '100%',
  },
  image: {
    maxWidth: '100%',
    width: '100%',
    cursor: 'pointer',
  },
  textP: {
    fontSize: '14px',
    fontWeight: 'normal',
    color: '#1B1B1B',
  },
  cena: {
    fontFamily: 'Bodoni',
    fontSize: '32px',
    fontWeight: 'normal',
    marginBlock: '32px',
  },
  pop: {
    marginTop: '20px',
    marginRight: '10px',
  },
  amount: {
    border: '2px solid',
    padding: '6px',
    marginTop: '10px',
    maxWidth: '50px',
    maxHeight: '30px',
    paddingLeft: '20px',
  },
  btn: {
    backgroundColor: '#1B1B1B',
    borderRadius: 0,
    color: 'white',
    '&:hover': {
      background: 'black',
    },
  },
  butt: {
    width: '100%',
    marginBlock: '32px',
  },
  text2: {
    fontSize: '14px',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    marginTop: '16px'
  },
  text3: {
    fontSize: '14px',
    fontWeight: 'normal',
    marginTop: '16px'
  },
  collapseMenu: {
    fontSize: '14px',
    fontWeight: 'bold',
    marginTop: '60px',
  },
  collapseMenu1: {
    fontSize: '14px',
    fontWeight: 'bold',
    marginTop: '15px',
  },
  heading: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  root: {
    marginTop: '40px',
    borderRadius: '0px',
  },
  accord: {
    marginTop: '10px',
    borderBottom: '1px solid #E4E4E4',
    boxShadow: '5px 5px 0px -30px #FFFFFF',
  },
  list: {
    paddingLeft: 25,
    listStyle: 'none',
    lineHeight: 1.5,
    position: 'relative',
    margin: 0
  },
  listItem: {
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    "&:before": {
      display: 'block',
      position: 'absolute',
      content: '""',
      width: 3,
      height: 3,
      borderRadius: 50,
      background: 'black',
      left: 10
    }
  },
  description: {
    fontSize: '14px'
  }
}))
const DetailWedding = ({ framed }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [imageIndex, setImageIndex] = useState(0)
  const classes = useStyles()
  const theme = useTheme()
  const { push, replace } = useHistory()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  const imageData = [
    {
      url: `/images/detail/wedding/${isDesktop ? 'desktop' : 'mobile'}/${
        framed ? 'framed/' : 'poster/'
      }detail_1.webp`,
      title: 'Kids 1',
    },
    {
      url: `/images/detail/wedding/${isDesktop ? 'desktop' : 'mobile'}/${
        framed ? 'framed/' : 'poster/'
      }detail_2.webp`,
      title: 'Kids detail 2',
    },
    {
      url: `/images/detail/wedding/${isDesktop ? 'desktop' : 'mobile'}/${
        framed ? 'framed/' : 'poster/'
      }detail_3.webp`,
      title: 'Kids detail 3',
    },
    {
      url: `/images/detail/wedding/${isDesktop ? 'desktop' : 'mobile'}/${
        framed ? 'framed/' : 'poster/'
      }detail_4.webp`,
      title: 'Kids detail 4',
    },
    {
      url: `/images/detail/wedding/${isDesktop ? 'desktop' : 'mobile'}/${
        framed ? 'framed/' : 'poster/'
      }detail_5.webp`,
      title: 'Kids detail 5',
    },
  ]

  return (
    <>
      <Box className={classes.backTheme}>
        <Container
          style={{
            backgroundColor: '#FFFFFF',
          }}
        >
          <Grid
            container
            spacing={isDesktop ? 8 : null}
            style={{
              marginTop: '100px',
              width: '100%',
              backgroundColor: '#FFFFFF',
            }}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {isDesktop ? (
                <div>
                  {imageData.map((img, i) => (
                    <img
                      onClick={() => {
                        setIsOpen(true)
                        setImageIndex(i)
                      }}
                      className={classes.image}
                      key={img.title}
                      src={img.url}
                      alt={img.title}
                    />
                  ))}
                </div>
              ) : (
                <Slide
                  {...{
                    duration: 5000,
                    autoplay: false,
                    transitionDuration: 200,
                    arrows: true,
                    infinite: true,
                  }}
                >
                  {imageData.map((image, i) => (
                    <div key={image.url} className="each-slide-effect">
                      <img
                        onClick={() => {
                          setIsOpen(true)
                          setImageIndex(i)
                        }}
                        style={{ width: '100%' }}
                        src={image.url}
                      />
                    </div>
                  ))}
                </Slide>
              )}
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography variant="h1" className={classes.title}>Plakát na míru Svatba</Typography>
              <Typography className={classes.text3}>
                Ať už plánujete malou svatbu nebo velkou událost, tento plakát je skvělým způsobem,
                jak zdokumentovat všechny důležité detaily vašeho svatebního dne. Navíc je to krásná památka,
                ke které se můžete opakovaně obracet.
              </Typography>

              <Typography className={classes.cena}>
                {framed ? '1 850,-' : '1 290,-'}
              </Typography>

              <div style={{ display: 'flex', gap: '18px' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                  <Typography className={classes.description}>
                    <strong>VELIKOST</strong>
                  </Typography>

                  <Typography className={classes.description}>
                    <strong>PAPÍR</strong>
                  </Typography>

                  <Typography className={classes.description}>
                    <strong>VERZE</strong>
                  </Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                  <Typography className={classes.description}>
                    {framed ? '42,5 x 52,5' : '40 x 50'} CM
                  </Typography>

                  <Typography className={classes.description}>
                    180 g/m²
                  </Typography>

                  <Typography className={classes.description}>
                    {framed ? 's rámem' : 'bez rámu'}
                  </Typography>
                </div>
              </div>

              <TypeSelect
                framed={framed}
                onClick={(framed) =>
                  replace('/detail/wedding' + (framed ? '/framed' : ''))
                }
              />
              <Box className={classes.butt}>
                <Button
                  className={classes.btn}
                  onClick={() =>
                    push('/editor/wedding' + (framed ? '/framed' : ''))
                  }
                >
                  Vytvořit plakát
                </Button>
              </Box>

              <Typography className={classes.text3}>
                Nabízíme řadu přizpůsobení, abychom se ujistili, že plakát se vzpomínkou na tuto výjimečnou
                událost bude dokonalý, podle Vašich představ. Plakát můžete darovat jako speciální dárek,
                který bude jedinečnou památkou pro novopečené rodiče nebo prarodiče.
              </Typography>
              <Typography className={classes.text2}>
                Na obraz se vyplňuje:
              </Typography>
              <ul className={classes.list}>
                <li className={classes.listItem}>Jména obou partnerů</li>
                <li className={classes.listItem}>Jejich znamení</li>
                <li className={classes.listItem}>Datum svatby</li>
                <li className={classes.listItem}>Místo svatby</li>
                <li className={classes.listItem}>Čas obřadu</li>
                <li className={classes.listItem}>Vaše skladba (první tanec, nebo obřadní sklaba)</li>
                <li className={classes.listItem}>Počet hostů</li>
                <li className={classes.listItem}>Kým byli manželé oddání</li>
                <li className={classes.listItem}>Příběh seznámení</li>
                <li className={classes.listItem}>Krátký text o svatebním dni, či o lásce</li>
              </ul>
            </Grid>
          </Grid>
          <Box marginTop="4rem">
            <Cupon />
          </Box>
        </Container>
      </Box>

      {isOpen && (
        <Lightbox
          mainSrc={imageData[imageIndex]?.url}
          nextSrc={imageData[(imageIndex + 1) % imageData.length]?.url}
          prevSrc={
            imageData[(imageIndex + imageData.length - 1) % imageData.length]
              ?.url
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setImageIndex(
              (imageIndex + imageData.length - 1) % imageData.length
            )
          }
          onMoveNextRequest={() =>
            setImageIndex((imageIndex + 1) % imageData.length)
          }
        />
      )}
    </>
  )
}

export default DetailWedding
