import React from 'react'
import FaqComponent from '../../Components/TextComponents/Faq'

function FaqPage() {
  return (
    <>
      <FaqComponent />
    </>
  )
}

export default FaqPage
