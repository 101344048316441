import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Container, Box, Grid, Typography, Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  backTheme: {
    width: '100%',
    minHeight: 'calc(100vh - 188px)',
    backgroundColor: '#E5E5E5',
  },
  text: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '20px',
    textAlign: 'center',
    color: '#000000',
  },
}))

function CartThanks() {
  const classes = useStyles()
  const { push } = useHistory()

  return (
    <Box className={classes.backTheme}>
      <Container maxWidth="lg">
        <Grid container>
          <Grid
            xs={12}
            sm={12}
            md={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              paddingTop: '2rem',
              paddingBottom: '8rem',
            }}
          >
            <img src={'/images/ThanksIcon.png'}></img>
            <Typography
              variant="h1"
              style={{ marginTop: '1rem', textAlign: 'center' }}
            >
              Děkujeme za Vaší objednávku
            </Typography>
            <Box style={{ maxWidth: '540px' }}>
              <Typography
                className={classes.text}
                style={{ paddingTop: '1rem' }}
              >
                Přijali jsme Vaší objednávku do našeho systému. Každá objednávka
                je pro nás důležitá, a tak v případě zjištění sebemenšího
                estetického problému Vás budeme kontaktovat.
              </Typography>
            </Box>
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              marginTop="2rem"
            >
              <Button onClick={() => push('/')}>Pokračovat na homepage</Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default CartThanks
