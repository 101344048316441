import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box, Container, Grid, Typography, Button } from '@material-ui/core'
import NumericInput from 'react-numeric-input'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import 'react-slideshow-image/dist/styles.css'
import ImageSlider from 'react-simple-image-slider'

const useStyles = makeStyles(() => ({
  backTheme: {
    backgroundColor: '#FFFFFF',
    width: '100%',
  },
  image: {
    position: 'relative',
    maxWidth: '100%',
    width: '364.53px',
    height: '373.1px',
    marginLeft: '40px',
    marginTop: '30px',
  },

  image2: {
    position: 'relative',
    maxWidth: '100%',
    width: '364.53px',
    height: '373.1px',
    marginLeft: '40px',
  },

  image3: {
    position: 'relative',
    maxWidth: '100%',
    width: '364.53px',
    height: '373.1px',
    marginLeft: '40px',
  },

  title: {
    fontFamily: 'Bodoni',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '36px',
    marginTop: '20px',
    marginLeft: '20px',
  },

  textP: {
    fontSize: '14px',
    fontWeight: 'normal',
    marginLeft: '20px',
    color: '#1B1B1B',
  },

  cena: {
    marginLeft: '20px',
    fontSize: '22px',
    fontWeight: 'normal',
    marginTop: '35px',
  },

  pop: {
    marginLeft: '20px',
    marginTop: '20px',
    marginRight: '10px',
  },

  rozmer: {
    marginLeft: '20px',
    marginTop: '20px',
    wordSpacing: '30px',
  },

  amount: {
    border: '2px solid',
    padding: '6px',
    marginTop: '10px',
    marginLeft: '20px',
    maxWidth: '50px',
    maxHeight: '30px',
    paddingLeft: '20px',
  },

  btn: {
    marginLeft: '20px',
    marginTop: '10px',
    backgroundColor: '#1B1B1B',
    borderRadius: 0,
    color: 'white',
    '&:hover': {
      background: 'black',
    },
  },

  butt: {
    width: '100%',
  },

  text2: {
    fontSize: '14px',
    fontWeight: 'bold',
    marginLeft: '20px',
    marginTop: '170px',
  },

  text3: {
    fontSize: '14px',
    fontWeight: 'normal',
    marginLeft: '20px',
    marginRight: '10px',
  },

  collapseMenu: {
    fontSize: '14px',
    fontWeight: 'bold',
    marginLeft: '20px',
    marginTop: '60px',
  },

  collapseMenu1: {
    fontSize: '14px',
    fontWeight: 'bold',
    marginLeft: '20px',
    marginTop: '15px',
  },

  numeric: {
    marginLeft: '20px',
  },

  numStyle: {
    marginLeft: '20px',
    marginTop: '60px',
  },

  heading: {
    fontSize: '14px',
    fontWeight: 'bold',
  },

  root: {
    marginLeft: '20px',
    marginTop: '40px',
    borderRadius: '0px',
  },

  accord: {
    marginTop: '10px',
    borderBottom: '1px solid #E4E4E4',
    boxShadow: '5px 5px 0px -30px #FFFFFF',
  },
}))
const DetailLife = () => {
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  const imageData = [
    {
      src: '../obr.png',
      title: 'První návrh',
    },
    {
      src: '../obr.png',
      title: 'Druhý návrh',
    },
  ]

  return (
    <>
      <Box className={classes.backTheme}>
        <Container
          style={{
            backgroundColor: '#FFFFFF',
          }}
        >
          <Grid
            container
            style={{
              marginTop: '83px',
              width: '100%',
              backgroundColor: '#FFFFFF',
            }}
          >
            <Grid xs={12} sm={6} md={6} lg={6} xl={6}>
              {matches ? (
                <>
                  <img className={classes.image} src={'obr.png'}></img>
                  <img className={classes.image2} src={'obr.png'}></img>
                  <img className={classes.image3} src={'obr.png'}></img>
                </>
              ) : (
                <Box>
                  <ImageSlider
                    height="100%"
                    width="100%"
                    autoplay={false}
                    data={imageData}
                  />
                </Box>
              )}
            </Grid>

            <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography className={classes.title}>LifeCalendar</Typography>
              <Typography className={classes.textP}>
                PERSONALIZOVANÉ DESIGNOVÉ PLAKÁTY / PLAKÁT BEZ VYPLNĚNÍ
              </Typography>
              <Typography className={classes.cena}>590,-</Typography>
              <Typography className={classes.pop}>
                Vizualizuj si svůj život pomocí malých čtverečků. Jeden čtverec
                v jednoduchém intuitivním kaledáři předsavuje jeden týden našeho
                života. Ohlédni se za svým dosavadním životem. CELÝ POPIS
              </Typography>
              <Typography className={classes.rozmer}>VELIKOST 30x40</Typography>

              <Box className={classes.numStyle}>
                <NumericInput
                  className="numeric"
                  value="1"
                  min={1}
                  max={100}
                  step={1}
                  precision={0}
                  size={5}
                  mobile
                  style={{
                    wrap: {
                      borderRadius: '6px 3px 3px 6px',
                      fontSize: 15,
                    },
                    input: {
                      color: '#000000',
                      backgroundColor: '#FFFFFF',
                      padding: '5px',
                      display: 'block',
                      fontWeight: 100,
                    },
                  }}
                />
              </Box>

              <Box className={classes.butt}>
                <Button
                  className={classes.btn}
                  variant="contained"
                  disableElevation
                >
                  PŘIDAT DO KOŠÍKU
                </Button>
              </Box>

              <Typography className={classes.text2}>POPIS</Typography>
              <Typography className={classes.text3}>
                Vizualizuj si svůj život pomocí malých čtverečků. Jeden čtverec
                v jednoduchém intuitivním kaledáři předsavuje jeden týden našeho
                života. Ohlédni se za svým dosavadním životem. Vizualizuj si
                svůj život pomocí malých čtverečků. Jeden čtverec v jednoduchém
                intuitivním kaledáři předsavuje jeden týden našeho života.
                Ohlédni se za svým dosavadním životem.
              </Typography>

              <div className={classes.root}>
                <Accordion>
                  <AccordionSummary
                    className={classes.accord}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>KONCEPT</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse malesuada lacus ex, sit amet blandit leo
                      lobortis eget.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    className={classes.accord}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      JAK TO FUNGUJE?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse malesuada lacus ex, sit amet blandit leo
                      lobortis eget.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    className={classes.accord}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      CO DĚLAT S KALENDÁŘEM?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse malesuada lacus ex, sit amet blandit leo
                      lobortis eget.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default DetailLife
