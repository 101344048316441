import React, { useState } from 'react'
import { useOrder } from './OrderContext'
import { useAuth } from './AuthContext'

const MarriageContext = React.createContext()

const MarriageOrderProvider = ({ children }) => {
  const { loadOrders } = useOrder()
  const { postData } = useAuth()

  const [formInfo, setFormInfo] = useState({
    name1: '',
    name2: '',
    sign1: '',
    sign2: '',
    date: '2017-05-24',
    hour: '07:30',
    note: '',
    photo: null,
    colorSelect: 'black',
  })
  const [formDetail, setFormDetail] = useState({
    place: '',
    song: '',
    priest: '',
    procession: '',
    story: '',
  })
  const [formOverview, setFormOverview] = useState({
    agreement: false,
    annotation: '',
  })

  const handleSaveMarriage = async (framed) => {
    await postData('/cart/', {
      ProductID: framed ? 3 : 4,
      Quantity: 1,
      Data: JSON.stringify({ ...formInfo, ...formDetail, ...formOverview }),
    })
    loadOrders()
  }

  return (
    <MarriageContext.Provider
      value={{
        formInfo,
        setFormInfo,
        formDetail,
        setFormDetail,
        formOverview,
        setFormOverview,
        handleSaveMarriage,
      }}
    >
      {children}
    </MarriageContext.Provider>
  )
}

const useMarriageOrder = () => React.useContext(MarriageContext)
export { MarriageOrderProvider, useMarriageOrder }
