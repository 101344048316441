import React from "react";

import EmptyOrders from "../Components/EmptyOrders";

function EmptyOrdersFun() {
  return (
    <>
      <EmptyOrders />
    </>
  );
}

export default EmptyOrdersFun;
