import React from "react";

import Test from "../Components/TextComponents/Faq";

function TmpTestPage() {
  return (
    <>
      <Test />
    </>
  );
}

export default TmpTestPage;
