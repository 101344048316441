import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Box,
  Radio,
  Container,
  Typography,
  FormControlLabel,
  Grid,
} from '@material-ui/core'
import CartSummary from '../../CartComponents/CartSummary'
import { useMediaQuery } from 'react-responsive'
import { useOrder } from '../../../Context/OrderContext'
import { useCartProvider } from '../../../Context/CartContext'

const useStyles = makeStyles(() => ({
  backTheme: {
    width: '100%',
    backgroundColor: '#E5E5E5',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    color: '#000000',
  },
  price: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '20px',
    color: '#000000',
    textAlign: 'right',

    paddingRight: '1rem',
  },
  textLabel: {
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '15px',

    paddingLeft: 6,
  },
}))

function CartShippingPayment() {
  const classes = useStyles()

  const isMobile = useMediaQuery({ query: `(max-width: 959px)` })
  const { payment, setPayment, DELIVERY_PRICE } = useCartProvider()
  const { isPersonalDelivery, voucher } = useOrder()

  useEffect(() => {
    if (payment.ID_delivery === null) {
      if (isPersonalDelivery) {
        setPayment({
          ...payment,
          ID_delivery: 1,
        })
      } else {
        setPayment({
          ...payment,
          ID_delivery: 3,
        })
      }
    }
  }, [payment.ID_delivery])

  return (
    <Box className={classes.backTheme}>
      <Container>
        <Grid container>
          <Grid item xs={12} md={7}>
            <Grid container style={{ border: '1px solid black' }}>
              <Grid
                item
                xs={12}
                style={{
                  backgroundColor: 'white',
                  paddingTop: '1rem',
                  paddingBottom: '1rem',
                  paddingLeft: '1rem',
                  borderBottom: '1px solid black',
                }}
              >
                <Typography className={classes.title}>Doprava</Typography>
              </Grid>
              {isPersonalDelivery ? (
                <>
                  <Grid
                    item
                    xs={12}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Grid item xs={9}>
                      <FormControlLabel
                        style={{ paddingLeft: '1rem' }}
                        control={
                          <Radio
                            name="checkboxPpl"
                            color="default"
                            checked={payment.ID_delivery === 1}
                            onChange={() =>
                              setPayment({
                                ...payment,
                                ID_delivery: 1,
                              })
                            }
                          />
                        }
                        label={
                          <React.Fragment>
                            <Box
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <img
                                src="/images/pplLogo.png"
                                style={{ width: 26, height: 6 }}
                              ></img>
                              <Typography className={classes.textLabel}>
                                PPL
                              </Typography>
                            </Box>
                          </React.Fragment>
                        }
                      ></FormControlLabel>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography className={classes.price}>
                        {voucher ? 'Zdarma' : `+${DELIVERY_PRICE} Kč`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Grid item xs={9}>
                      <FormControlLabel
                        style={{ paddingLeft: '1rem' }}
                        control={
                          <Radio
                            name="checkboxOdber"
                            color="default"
                            checked={payment.ID_delivery === 2}
                            onChange={() =>
                              setPayment({
                                ...payment,
                                ID_delivery: 2,
                              })
                            }
                          />
                        }
                        label={
                          <React.Fragment>
                            <Typography className={classes.textLabel}>
                              Osobní odběr (28. října 23/21, Ostrava)
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Typography className={classes.price}>Zdarma</Typography>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Grid
                  item
                  xs={12}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <Grid item xs={9}>
                    <FormControlLabel
                      style={{ paddingLeft: '1rem' }}
                      control={
                        <Radio
                          name="checkboxOnline"
                          color="default"
                          checked={payment.ID_delivery === 3}
                          onChange={() =>
                            setPayment({
                              ...payment,
                              ID_delivery: 3,
                            })
                          }
                        />
                      }
                      label={
                        <React.Fragment>
                          <Box
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Typography className={classes.textLabel}>
                              Online
                            </Typography>
                          </Box>
                        </React.Fragment>
                      }
                    ></FormControlLabel>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography className={classes.price}>zdarma</Typography>
                  </Grid>
                </Grid>
              )}
              {/* <Grid
                    item
                    md={12}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Grid item md={9}>
                      <FormControlLabel
                        style={{ paddingLeft: "1rem" }}
                        control={
                          <Checkbox
                            name="checkboxPosta"
                            color="default"
                            checked={payment.shippingMethod === "post"}
                            onChange={() =>
                              setPayment({
                                ...payment,
                                shippingMethod:
                                  payment.shippingMethod === "post"
                                    ? null
                                    : "post",
                              })
                            }
                          />
                        }
                        label={
                          <React.Fragment>
                            <Box
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                src="/images/ceskaPostaLogo.png"
                                style={{ width: 25, height: 17 }}
                              ></img>
                              <Typography className={classes.textLabel}>
                                Česká pošta
                              </Typography>
                            </Box>
                          </React.Fragment>
                        }
                      />
                    </Grid>
                    <Grid item md={3}>
                      <Typography className={classes.price}>+60 Kč</Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Grid item md={9}>
                      <FormControlLabel
                        style={{ paddingLeft: "1rem" }}
                        control={
                          <Checkbox
                            name="checkboxZasielkovna"
                            color="default"
                            checked={payment.shippingMethod === "zasilkovna"}
                            onChange={() =>
                              setPayment({
                                ...payment,
                                shippingMethod:
                                  payment.shippingMethod === "zasilkovna"
                                    ? null
                                    : "zasilkovna",
                              })
                            }
                          />
                        }
                        label={
                          <React.Fragment>
                            <Box
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                src="/images/zasielkovnaLogo.png"
                                style={{ width: 18, height: 18 }}
                              ></img>
                              <Typography className={classes.textLabel}>
                                Zásilkovna
                              </Typography>
                            </Box>
                          </React.Fragment>
                        }
                      />
                    </Grid>
                    <Grid item md={3}>
                      <Typography className={classes.price}>+60 Kč</Typography>
                    </Grid>
                  </Grid> */}
            </Grid>
            <Grid
              container
              style={{ border: '1px solid black', marginTop: '2rem' }}
            >
              <Grid
                item
                xs={12}
                style={{
                  backgroundColor: 'white',
                  paddingTop: '1rem',
                  paddingBottom: '1rem',
                  paddingLeft: '1rem',
                  borderBottom: '1px solid black',
                }}
              >
                <Typography className={classes.title}>Platba</Typography>
              </Grid>
              {/*               {isPersonalDelivery && (
                <Grid
                  item
                  xs={12}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <Grid item xs={9}>
                    <FormControlLabel
                      style={{ paddingLeft: '1rem' }}
                      control={
                        <Radio
                          name="checkboxCash"
                          color="default"
                          checked={payment.Payment === 'cash'}
                          onChange={() =>
                            setPayment({
                              ...payment,
                              Payment:
                                payment.Payment === 'cash' ? null : 'cash',
                            })
                          }
                        />
                      }
                      label={
                        <React.Fragment>
                          <Box
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <img
                              src="/images/cashLogo.png"
                              style={{ width: 14, height: 10 }}
                            ></img>
                            <Typography className={classes.textLabel}>
                              Hotově
                            </Typography>
                          </Box>
                        </React.Fragment>
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography className={classes.price}>Zdarma</Typography>
                  </Grid>
                </Grid>
              )} */}
              <Grid
                item
                xs={12}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Grid item xs={9}>
                  <FormControlLabel
                    style={{ paddingLeft: '1rem' }}
                    control={
                      <Radio
                        name="checkboxKarta"
                        color="default"
                        checked={payment.Payment === 'card'}
                        onChange={() =>
                          setPayment({
                            ...payment,
                            Payment: payment.Payment === 'card' ? null : 'card',
                          })
                        }
                      />
                    }
                    label={
                      <React.Fragment>
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            src="/images/kartaLogo.png"
                            style={{ width: 16, height: 12 }}
                          ></img>
                          <Typography className={classes.textLabel}>
                            Kartou online/QR Platba
                          </Typography>
                        </Box>
                      </React.Fragment>
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.price}>Zdarma</Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Grid item xs={9}>
                  <FormControlLabel
                    style={{ paddingLeft: '1rem' }}
                    control={
                      <Radio
                        name="checkboxBanka"
                        color="default"
                        checked={payment.Payment === 'bankTransfer'}
                        onChange={() =>
                          setPayment({
                            ...payment,
                            Payment:
                              payment.Payment === 'bankTransfer'
                                ? null
                                : 'bankTransfer',
                          })
                        }
                      />
                    }
                    label={
                      <React.Fragment>
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            src="/images/prevodLogo.png"
                            style={{ width: 13, height: 17 }}
                          ></img>
                          <Typography className={classes.textLabel}>
                            Bankovním převodem
                          </Typography>
                        </Box>
                      </React.Fragment>
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography className={classes.price}>Zdarma</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={0} md={1}></Grid>
          <Grid
            item
            xs={12}
            md={4}
            style={isMobile ? { marginTop: '2rem' } : undefined}
          >
            <CartSummary></CartSummary>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default CartShippingPayment
