import React from 'react'
import { makeStyles, withStyles } from '@material-ui/styles'
import {
  Button,
  Box,
  Container,
  Grid,
  Typography,
  TextField,
} from '@material-ui/core'
import moment from 'moment'
import { useMediaQuery } from 'react-responsive'
import { useCartProvider } from '../../../Context/CartContext'
import { useOrder } from '../../../Context/OrderContext'
import { useAuth } from '../../../Context/AuthContext'
import { useSnackBars } from '../../../Context/ErrorSnackbarContext'

const useStyles = makeStyles(() => ({
  backTheme: {
    width: '100%',
    backgroundColor: '#E5E5E5',
  },
  polozkyTitle: {
    fontStyle: 'normal',
    fontWeight: '600px',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#000000',
  },
  billImage: {
    maxWidth: 66,
    maxHeight: 'auto',
  },
  billImageBox: {
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  deteleIcon: {
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    marginTop: '1rem',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'rgb(196, 196, 196)',
    },
  },
  deleteIconText: {
    fontStyle: 'normal',
    fontWeight: '700px',
    fontSize: 10,
    lineHeight: '20px',
    color: '#000000',
    marginLeft: '0.5rem',
  },
  itemBigTitle: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 18,
    lineHeight: '20px',
    color: '#000000',
    textAlign: 'left',

    paddingTop: '2rem',
  },
  itemBigTitle2: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 12,
    lineHeight: '20px',
    color: '#000000',
    textDecoration: 'underline',
  },
  itemBigTitle3: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 12,
    lineHeight: '20px',
    color: '#000000',
  },
  price: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '20px',
    color: '#000000',
    textAlign: 'right',
    paddingRight: '1rem',
  },
  priceFinal: {
    fontStyle: 'normal',
    fontWeight: '600px',
    fontSize: 14,
    lineHeight: '20px',
    color: '#000000',
    textAlign: 'right',
    paddingRight: '1rem',
    paddingBottom: '0.5rem',
  },
  priceTextFinal: {
    fontStyle: 'normal',
    fontWeight: '600px',
    fontSize: 14,
    lineHeight: '20px',
    color: '#000000',
    paddingBottom: '0.5rem',
  },
  text: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '20px',
    color: '#000000',

    paddingLeft: '1rem',
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  cartItem: {
    display: 'flex',
    borderTop: '1px solid #C4C4C4',
  },
  emptyTextBig: {
    margin: '4rem 0',
    fontFamily: 'EB_Garamond',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 25,
    lineHeight: '30px',
    textAlign: 'center',
    color: '#505050',
  },
}))

const MyTextField = withStyles({
  root: {
    width: '25%',
    '& .MuiOutlinedInput-root': {
      height: '2rem',
      '& fieldset': {
        borderRadius: `0 0 0 0`,
      },
    },
  },
})(TextField)

function CartContent() {
  const { deleteData } = useAuth()
  const classes = useStyles()
  const { addAlert } = useSnackBars()
  const { loadOrders, orders, totalPrice, voucher } = useOrder()
  const isMobile = useMediaQuery({ query: `(max-width: 959px)` })
  const { coupon, setCoupon, checkCoupon } = useCartProvider()

  const handleVoucherSubmit = async () => {
    const res = await checkCoupon()
    if (res) {
      loadOrders()
      addAlert('Sleva přidána')
    }
  }

  const deleteItem = async (id) => {
    const res = await deleteData('/cart', {
      ID_cart_item: id,
    })
    if (res) {
      loadOrders()
    }
  }

  return (
    <Box className={classes.backTheme}>
      <Container maxWidth="lg">
        <Grid style={{ border: '1px solid black' }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'white',
              minHeight: 51,
              borderBottom: '1px solid black',
            }}
          >
            <Typography
              className={classes.polozkyTitle}
              style={{ paddingLeft: '1rem' }}
            >
              Váš nákupní košík (položky: {orders.length})
            </Typography>
          </Grid>
          {orders?.length > 0 ? (
            <>
              {orders.map((item) => (
                <Grid
                  key={item.ID_cart_item}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  className={classes.cartItem}
                >
                  <Grid
                    item
                    xs={3}
                    sm={3}
                    md={1}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Box className={classes.billImageBox}>
                      <img
                        className={classes.billImage}
                        alt="OrderKidImage2"
                        src={'/images/OrderKidImage2.png'}
                      ></img>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    sm={9}
                    md={11}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Typography className={classes.itemBigTitle}>
                      {item.Name}
                    </Typography>
                    <Grid container>
                      <Grid item xs={8}>
                        <Typography className={classes.itemBigTitle3}>
                          Uloženo:{' '}
                          {moment(item.Timestamp).isSame(moment(), 'day')
                            ? moment(item.Timestamp).format('HH:mm')
                            : moment(item.Timestamp).format('DD.MM.YYYY HH:mm')}
                        </Typography>
                        {/*                 <Typography className={classes.itemBigTitle2}>
                  Náhled z formuláře
                </Typography> */}
                        <div>
                          <Button
                            variant="outlined"
                            className={classes.deteleIcon}
                            onClick={() => deleteItem(item.ID_cart_item)}
                          >
                            <svg
                              width="12"
                              height="14"
                              viewBox="0 0 12 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.75 0V0.777778H0V2.33333H0.75V12.4444C0.75 12.857 0.908035 13.2527 1.18934 13.5444C1.47064 13.8361 1.85218 14 2.25 14H9.75C10.1478 14 10.5294 13.8361 10.8107 13.5444C11.092 13.2527 11.25 12.857 11.25 12.4444V2.33333H12V0.777778H8.25V0H3.75ZM2.25 2.33333H9.75V12.4444H2.25V2.33333ZM3.75 3.88889V10.8889H5.25V3.88889H3.75ZM6.75 3.88889V10.8889H8.25V3.88889H6.75Z"
                                fill="black"
                              />
                            </svg>
                            <Typography className={classes.deleteIconText}>
                              Odstranit
                            </Typography>
                          </Button>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        style={{
                          alignItems: 'flex-end',
                          justifyContent: 'flex-end',
                          display: 'flex',
                        }}
                      >
                        <Typography className={classes.price}>
                          {item.Price} Kč
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              {voucher && (
                <Box
                  display="flex"
                  justifyContent={
                    isMobile === true ? 'space-between' : 'flex-end'
                  }
                >
                  <Typography
                    className={classes.priceTextFinal}
                    style={
                      isMobile === true
                        ? { paddingLeft: '1rem' }
                        : { paddingRight: '2rem' }
                    }
                  >
                    Sleva ({voucher.code}):
                  </Typography>
                  <Typography className={classes.priceFinal}>
                    - {voucher.discount} Kč
                  </Typography>
                </Box>
              )}
              <Box
                display="flex"
                justifyContent={
                  isMobile === true ? 'space-between' : 'flex-end'
                }
              >
                <Typography
                  className={classes.priceTextFinal}
                  style={
                    isMobile === true
                      ? { paddingLeft: '1rem' }
                      : { paddingRight: '2rem' }
                  }
                >
                  Konečná suma:
                </Typography>
                <Typography className={classes.priceFinal}>
                  {totalPrice} Kč
                </Typography>
              </Box>
            </>
          ) : (
            <Typography className={classes.emptyTextBig}>
              Košík je prázdný
            </Typography>
          )}
        </Grid>
        <Grid
          container
          style={{
            border: '1px solid black',
            marginTop: '1rem',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '0.2rem 0.5rem',
            }}
          >
            <Typography className={classes.text}>Slevový kód:</Typography>
            <MyTextField
              style={{ marginLeft: '0.5rem', width: isMobile ? '60%' : '20%' }}
              id="cupon"
              placeholder="XXX"
              variant="outlined"
              value={coupon.couponName}
              onChange={(e) =>
                setCoupon({ ...coupon, couponName: e.target.value })
              }
            ></MyTextField>
            <Button
              size="small"
              style={{ marginLeft: '0.5rem' }}
              variant="outlined"
              onClick={() => coupon.couponName !== '' && handleVoucherSubmit()}
            >
              Použít
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default CartContent
