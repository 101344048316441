import React from 'react'
import { Box, Container } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import Life from '../Components/Category/Life'
import Kids from '../Components/Category/Kids'
import Wedding from '../Components/Category/Wedding'
import { ImagesProvider } from '../Context/ImagesContext'
import Cupon from '../Components/Cupon'

function Category() {
  const { type } = useParams()

  return (
    <ImagesProvider>
      <Container style={{ paddingTop: '7rem' }}>
        {type === 'life' && <Life />}
        {type === 'kids' && <Kids />}
        {type === 'wedding' && <Wedding />}
        <Box marginTop="4rem">
          <Cupon />
        </Box>
        <Box width="100%" height="4rem"></Box>
      </Container>
    </ImagesProvider>
  )
}

export default Category
