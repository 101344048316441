import React from 'react'

import ContactComponent from '../Components/ContactComponent'

function ContactFun() {
  return (
    <>
      <ContactComponent />
    </>
  )
}

export default ContactFun
