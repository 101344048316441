import React from 'react'
import { makeStyles, withStyles } from '@material-ui/styles'
import {
  Button,
  Grid,
  Box,
  Typography,
  Container,
  Hidden,
} from '@material-ui/core'
import ConnectorLine from '../Components/ConnectorLine'
import { useHistory } from 'react-router-dom'
import FadeIn from './FadeIn'

const useStyles = makeStyles((theme) => ({
  imageProp: {
    width: '100%',
  },
  title: {
    marginBottom: '1rem',
    fontFamily: 'EB_Garamond',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 52,
    lineHeight: '47px',
    color: '#1B1B1B',
    [theme.breakpoints.down("xs")]: {
      fontSize: 48,
    }
  },
  text: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '156.4%',
    color: '#1B1B1B',
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    }
  },
  text2: {
    fontFamily: 'Over the Rainbow',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 30,
    lineHeight: '61px',
    color: '#1B1B1B',
  },
  backTheme: {
    width: '100%',
  },
}))
const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#1B1B1B'),
    backgroundColor: '#1B1B1B',
    '&:hover': {
      backgroundColor: '#1B1B1B',
    },
  },
}))(Button)

function KidsComponent() {
  const classes = useStyles()
  const { push } = useHistory()

  return (
    <Box className={classes.backTheme}>
      <Container>
        <ConnectorLine />
        <Hidden mdUp>
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            style={{
              minHeight: 500,
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <FadeIn>
                <img
                  className={classes.imageProp}
                  src={'/images/kidsHome.webp'}
                />
              </FadeIn>
            </Grid>
            <FadeIn>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  paddingTop: '2rem',
                  paddingBottom: '4rem',
                }}
              >
                <Box style={{ margin: '0 auto' }}>
                  <Typography className={classes.title}>Tvé narození</Typography>
                  <Typography className={classes.text}>
                    S příchodem nového života si budete chtít uchovat památku na událost narození. Plakát zachycuje informace o miminku a také malé detaily ze dne narození dítěte.
                  </Typography>
                  <Typography className={classes.text}>
                    Originální dárek pro miminko, novopečené rodiče nebo prarodiče.
                  </Typography>
                  <br></br>

                  <Typography className={classes.text2}>
                    s laskavostí k rodině
                  </Typography>
                  <Box>
                    <ColorButton
                      variant="contained"
                      style={{ borderRadius: 0, paddingInline: 20}}
                      onClick={() => {
                        push('/detail/kids')
                      }}
                    >
                      PODROBNOSTI
                    </ColorButton>
                  </Box>
                </Box>
              </Grid>
            </FadeIn>
          </Grid>
        </Hidden>
        <Hidden smDown>
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            style={{
              height: '70vh',
              minHeight: 500,
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingRight: '4rem',
              }}
            >
              <FadeIn>
                <img
                  className={classes.imageProp}
                  src={'/images/kidsHome.webp'}
                />
              </FadeIn>
            </Grid>
            <FadeIn>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                <Box>
                  <Typography className={classes.title}>Tvé narození</Typography>
                  <Typography className={classes.text}>
                    S příchodem nového života si budete chtít uchovat památku na událost narození. Plakát zachycuje informace o miminku a také malé detaily ze dne narození dítěte.
                  </Typography>
                  <Typography className={classes.text}>
                    Originální dárek pro miminko, novopečené rodiče nebo prarodiče.
                  </Typography>
                  <br></br>

                  <Typography className={classes.text2}>
                    s laskavostí k rodině
                  </Typography>
                  <Box>
                    <ColorButton
                      variant="contained"
                      style={{ borderRadius: 0, paddingInline: 20}}
                      onClick={() => {
                        push('/detail/kids')
                      }}
                    >
                      PODROBNOSTI
                    </ColorButton>
                  </Box>
                </Box>
              </Grid>
            </FadeIn>
          </Grid>
        </Hidden>
      </Container>
    </Box>
  )
}

export default KidsComponent
