import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { brown } from '@material-ui/core/colors'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'

const BrownRadio = withStyles({
  root: {
    color: brown[200],
    '&$checked': {
      color: brown[300],
    },
  },
  checked: {},
})((props) => <Radio color="primary" {...props} />)

const WhiteRadio = withStyles({
  root: {
    color: '#888888',
    '&$checked': {
      color: '#909090',
    },
  },
  checked: {},
})((props) => <Radio color="primary" {...props} />)

const BlackRadio = withStyles({
  root: {
    color: '#000000',
    '&$checked': {
      color: '#080808',
    },
  },
  checked: {},
})((props) => <Radio color="primary" {...props} />)

function FrameColorSelect({ value, onChange }) {
  return (
    <div>
      <FormLabel
        component="legend"
        style={{
          marginTop: '2rem',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: 14,
          lineHeight: '17px',
          textTransform: 'uppercase',
          color: '#1B1B1B',
        }}
      >
        Vyberte barvu rámečku:
      </FormLabel>

      <FormControlLabel
        value="end"
        control={
          <BrownRadio
            checked={value === 'brown'}
            onChange={onChange}
            value="brown"
            name="hnědá"
            inputProps={{ 'aria-label': 'Brown' }}
          />
        }
        label="Hnědá"
      />
      <FormControlLabel
        value="end"
        control={
          <WhiteRadio
            checked={value === 'white'}
            onChange={onChange}
            value="white"
            name="bílá"
            label="Bílá"
            helperText="Bílá"
            inputProps={{ 'aria-label': 'White' }}
          />
        }
        label="Bílá"
      />

      <FormControlLabel
        value="end"
        control={
          <BlackRadio
            checked={value === 'black'}
            onChange={onChange}
            value="black"
            name="černá"
            label="Černá"
            helperText="Černá"
            inputProps={{ 'aria-label': 'Black' }}
          />
        }
        label="Černá"
      />
    </div>
  )
}

export default FrameColorSelect
