import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Container, Box, Grid, Typography } from '@material-ui/core'
import { useMediaQuery } from 'react-responsive'

const useStyles = makeStyles(() => ({
  backTheme: {
    width: '100%',
    backgroundColor: '#E5E5E5',
  },
  circle: {
    borderRadius: '50%',
    width: 40,
    height: 40,
    border: '1px solid #505050',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circleActual: {
    borderRadius: '50%',
    width: 40,
    height: 40,
    border: '1px solid #505050',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#505050',
  },
  sliderText: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 14,
    lineHeight: '20px',
    textAlign: 'right',
    color: '#505050',
  },
  sliderTextActual: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 14,
    lineHeight: '20px',
    textAlign: 'right',
    color: '#FFFFFF',
  },
}))

function CartBase({ page }) {
  const classes = useStyles()

  const isMobile = useMediaQuery({ query: `(max-width: 959px)` })

  return (
    <Box className={classes.backTheme}>
      <Container maxWidth="lg">
        <Grid
          container
          style={{
            paddingTop: '7rem',
            paddingBottom: '2rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {isMobile === false && (
            <>
              <Grid
                item
                md={2}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <Box
                  className={page === 0 ? classes.circleActual : classes.circle}
                >
                  <Typography
                    className={
                      page === 0 ? classes.sliderTextActual : classes.sliderText
                    }
                  >
                    1
                  </Typography>
                </Box>
                <Typography
                  style={{ marginLeft: '0.5rem' }}
                  className={classes.sliderText}
                >
                  Košík
                </Typography>
              </Grid>
              <Grid
                item
                md={2}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography style={{ color: '#1B1B1B' }}>&gt;</Typography>
              </Grid>
              <Grid item md={1}></Grid>
              <Grid
                item
                md={2}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box
                  className={page === 1 ? classes.circleActual : classes.circle}
                >
                  <Typography
                    className={
                      page === 1 ? classes.sliderTextActual : classes.sliderText
                    }
                  >
                    2
                  </Typography>
                </Box>
                <Typography
                  style={{ marginLeft: '0.5rem' }}
                  className={classes.sliderText}
                >
                  Kontaktní údaje
                </Typography>
              </Grid>
              <Grid
                item
                md={3}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography style={{ color: '#1B1B1B' }}>&gt;</Typography>
              </Grid>
              <Grid
                item
                md={2}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <Box
                  className={page === 2 ? classes.circleActual : classes.circle}
                >
                  <Typography
                    className={
                      page === 2 ? classes.sliderTextActual : classes.sliderText
                    }
                  >
                    3
                  </Typography>
                </Box>
                <Typography
                  style={{ marginLeft: '0.5rem' }}
                  className={classes.sliderText}
                >
                  Doprava a platba
                </Typography>
              </Grid>
            </>
          )}
          {isMobile === true && (
            <>
              <Grid
                item
                md={2}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <Box
                  className={page === 0 ? classes.circleActual : classes.circle}
                >
                  <Typography
                    className={
                      page === 0 ? classes.sliderTextActual : classes.sliderText
                    }
                  >
                    1
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                md={3}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography style={{ color: '#1B1B1B' }}>&gt;</Typography>
              </Grid>
              <Grid
                item
                md={2}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box
                  className={page === 1 ? classes.circleActual : classes.circle}
                >
                  <Typography
                    className={
                      page === 1 ? classes.sliderTextActual : classes.sliderText
                    }
                  >
                    2
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                md={3}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography style={{ color: '#1B1B1B' }}>&gt;</Typography>
              </Grid>
              <Grid
                item
                md={2}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <Box
                  className={page === 2 ? classes.circleActual : classes.circle}
                >
                  <Typography
                    className={
                      page === 2 ? classes.sliderTextActual : classes.sliderText
                    }
                  >
                    3
                  </Typography>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </Box>
  )
}

export default CartBase
