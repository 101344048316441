import React from 'react'

function TypeSelect({ framed, onClick }) {
  return (
    <div style={{ marginTop: '0.5rem' }}>
      <img
        style={{
          cursor: 'pointer',
          border: `2px solid ${framed ? 'transparent' : '#000'}`,
          width: '100px',
          marginRight: '1rem',
        }}
        onClick={() => onClick()}
        src="/images/detail/paper_miniature.png"
      />
      <img
        style={{
          cursor: 'pointer',
          border: `2px solid ${framed ? '#000' : 'transparent'}`,
          width: '100px',
        }}
        onClick={() => onClick(true)}
        src="/images/detail/framed_miniature.png"
      />
    </div>
  )
}

export default TypeSelect
