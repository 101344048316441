import React from 'react'
import { makeStyles, withStyles } from '@material-ui/styles'
import {
  Box,
  Grid,
  Typography,
  TextField,
  Hidden,
  FormHelperText,
} from '@material-ui/core'
import { useKidOrder } from '../../../Context/KidOrderContext'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const useStyles = makeStyles(() => ({
  formHelper: {
    marginLeft: '28px',
    marginTop: '-7px',
  },
  title1: {
    fontFamily: 'Bodoni',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 18,
    lineHeight: '173.5%',
    letterSpacing: '0.13em',
    textTransform: 'uppercase',
    color: '#1B1B1B',
  },
  title2: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '17px',
    textTransform: 'uppercase',
    color: '#1B1B1B',
  },
  weightUnitFont: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '138.4%',
    color: '#505050',

    paddingLeft: '1rem',
  },
}))

const MyTextField = withStyles({
  root: {
    width: '80px',
    maxHeight: 28,
    paddingTop: '1rem',
    paddingBottom: '1rem',
    '& .MuiOutlinedInput-root': {
      height: '2rem',
      '& fieldset': {
        borderRadius: `0 0 0 0`,
      },
    },
    '& .MuiFormLabel-root': {
      paddingTop: '1rem',
    },
  },
})(TextField)

const MyTextField2 = withStyles({
  root: {
    width: '40%',
    height: 'auto',
    maxHeight: 28,
    paddingTop: '1rem',
    paddingBottom: '1rem',
    '& .MuiOutlinedInput-root': {
      height: '2rem',
      '& fieldset': {
        borderRadius: `0 0 0 0`,
      },
    },
    '& .MuiFormLabel-root': {
      paddingTop: '0rem',
    },
  },
})(TextField)

function KidCustomDetailComponent({ errors }) {
  const classes = useStyles()
  const { formDetail, setFormDetail } = useKidOrder()
  const weightError = errors?.find((error) => error.path === 'weight')?.message
  const lengthError = errors?.find((error) => error.path === 'length')?.message
  const birthPlaceError = errors?.find(
    (error) => error.path === 'birthPlace'
  )?.message
  const birthDateError = errors?.find(
    (error) => error.path === 'birthDate'
  )?.message
  const signError = errors?.find((error) => error.path === 'sign')?.message

  const arr = [
    { value: 'Beran', text: 'Beran' },
    { value: 'Býk', text: 'Býk' },
    { value: 'Blíženci', text: 'Blíženci' },
    { value: 'Rak', text: 'Rak' },
    { value: 'Lev', text: 'Lev' },
    { value: 'Panna', text: 'Panna' },
    { value: 'Váhy', text: 'Váhy' },
    { value: 'Štír', text: 'Štír' },
    { value: 'Střelec', text: 'Střelec' },
    { value: 'Kozoroh', text: 'Kozoroh' },
    { value: 'Vodnář', text: 'Vodnář' },
    { value: 'Ryby', text: 'Ryby' },
  ]

  const signs = (date) => {
    const value = new Date(date)
    const day = value.getDate()
    const month = value.getMonth() + 1

    switch (month) {
      case 1:
        return day >= 20 ? 'Vodnář' : 'Kozoroh'
      case 2:
        return day >= 19 ? 'Ryby' : 'Vodnář'
      case 3:
        return day >= 21 ? 'Beran' : 'Ryby'
      case 4:
        return day >= 20 ? 'Býk' : 'Beran'
      case 5:
        return day >= 21 ? 'Blíženci' : 'Býk'
      case 6:
        return day >= 21 ? 'Rak' : 'Blíženci'
      case 7:
        return day >= 23 ? 'Lev' : 'Rak'
      case 8:
        return day >= 23 ? 'Panna' : 'Lev'
      case 9:
        return day >= 23 ? 'Váhy' : 'Panna'
      case 10:
        return day >= 23 ? 'Štír' : 'Váhy'
      case 11:
        return day >= 22 ? 'Střelec' : 'Štír'
      case 12:
        return day >= 22 ? 'Kozoroh' : 'Střelec'
    }
  }

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Grid
          item
          xs={12}
          md={12}
          style={{ marginTop: '1rem', borderBottom: '1px solid black' }}
        >
          <Typography className={classes.title1}>Detaily</Typography>
        </Grid>
        <Hidden smDown>
          <Grid container spacing={2} style={{ paddingTop: '1rem' }}>
            <Grid item md={6}>
              <Typography className={classes.title2}>datum narození</Typography>
              <TextField
                id="date"
                type="date"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                value={formDetail.birthDate}
                error={!!birthDateError}
                helperText={birthDateError}
                onChange={(e) => {
                  setFormDetail({
                    ...formDetail,
                    birthDate: e.target.value,
                    sign: signs(e.target.value),
                  })
                }}
              />
            </Grid>
            {birthDateError}
            <Grid item md={6}>
              <Typography className={classes.title2}>Čas narození</Typography>
              <TextField
                id="time"
                type="time"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
                value={formDetail.birthHour}
                onChange={(e) =>
                  setFormDetail({ ...formDetail, birthHour: e.target.value })
                }
              />
            </Grid>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingTop: '1rem',
            }}
          >
            <Typography className={classes.title2}>datum narození</Typography>
            <TextField
              id="date"
              type="date"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              value={formDetail.birthDate}
              onChange={(e) =>
                setFormDetail({ ...formDetail, birthDate: e.target.value })
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingTop: '1rem',
            }}
          >
            <Typography className={classes.title2}>Čas narození</Typography>
            <TextField
              id="time"
              type="time"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
              value={formDetail.birthHour}
              onChange={(e) =>
                setFormDetail({ ...formDetail, birthHour: e.target.value })
              }
            />
          </Grid>
        </Hidden>
        <Grid
          item
          xs={12}
          md={12}
          style={{ display: 'flex', paddingTop: '1rem', marginBottom: '20px' }}
        >
          <Hidden smDown>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Typography className={classes.title2}>VÁHA</Typography>

                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    style={{ width: '8%', paddingRight: '1rem' }}
                    src={'/images/WeightIcon.png'}
                  ></img>
                  <MyTextField
                    id="vaha"
                    variant="outlined"
                    value={formDetail.weight}
                    error={!!weightError}
                    onChange={(e) =>
                      setFormDetail({ ...formDetail, weight: e.target.value })
                    }
                  ></MyTextField>
                  <Typography className={classes.weightUnitFont}>g</Typography>
                </Box>
                <FormHelperText
                  error={!!weightError}
                  className={classes.formHelper}
                >
                  {weightError}
                </FormHelperText>
              </Grid>

              <Grid item md={6}>
                <Typography className={classes.title2}>Délka</Typography>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    style={{ width: '8%', paddingRight: '1rem' }}
                    src={'/images/BothSideArrowIcon.png'}
                  ></img>
                  <MyTextField
                    id="dlzka"
                    variant="outlined"
                    value={formDetail.length}
                    error={!!lengthError}
                    onChange={(e) =>
                      setFormDetail({ ...formDetail, length: e.target.value })
                    }
                  ></MyTextField>
                  <Typography className={classes.weightUnitFont}>cm</Typography>
                </Box>
                <FormHelperText
                  className={classes.formHelper}
                  error={!!lengthError}
                >
                  {lengthError}
                </FormHelperText>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography className={classes.title2}>VÁHA</Typography>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    style={{ width: '8%', paddingRight: '1rem' }}
                    src={'/images/WeightIcon.png'}
                  ></img>
                  <MyTextField
                    style={{ width: '100%' }}
                    id="vaha"
                    variant="outlined"
                    value={formDetail.weight}
                    onChange={(e) =>
                      setFormDetail({ ...formDetail, weight: e.target.value })
                    }
                  ></MyTextField>
                  <Typography className={classes.weightUnitFont}>g</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.title2}>Délka</Typography>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    style={{ width: '8%', paddingRight: '1rem' }}
                    src={'/images/BothSideArrowIcon.png'}
                  ></img>
                  <MyTextField
                    style={{ width: '100%' }}
                    id="dlzka"
                    variant="outlined"
                    value={formDetail.length}
                    onChange={(e) =>
                      setFormDetail({ ...formDetail, length: e.target.value })
                    }
                  ></MyTextField>
                  <Typography className={classes.weightUnitFont}>cm</Typography>
                </Box>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
        <Grid item xs={12} md={12} style={{ paddingTop: '1rem' }}>
          <Typography className={classes.title2}>Znamení</Typography>
          <FormControl
            className={classes.formControl}
            style={{
              width: '100%',
              paddingBottom: '12px',
            }}
          >
            <Select
              displayEmpty
              error={!!signError}
              helperText={signError}
              value={formDetail.sign}
              className={classes.selectEmpty}
              inputProps={{ 'aria-label': 'Without label' }}
              onChange={(e) => {
                setFormDetail({ ...formDetail, sign: e.target.value })
              }}
            >
              {arr.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={12} style={{ paddingTop: '1rem' }}>
          <Typography className={classes.title2}>Místo narození</Typography>
          <MyTextField2
            style={{ width: '100%' }}
            id="miestoNarodenia"
            placeholder="např. Olomouc"
            variant="outlined"
            value={formDetail.birthPlace}
            error={!!birthPlaceError}
            helperText={birthPlaceError}
            onChange={(e) =>
              setFormDetail({ ...formDetail, birthPlace: e.target.value })
            }
          ></MyTextField2>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default KidCustomDetailComponent
