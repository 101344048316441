import React from 'react'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import { Box, Container, Grid, Hidden, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  backTheme: {
    backgroundColor: '#505050',
    width: '100%',
  },
  footerLogo: {
    width: 200,
    height: 'auto',
  },
  leftGrid: {
    border: '1px solid white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  middleGrid: {
    borderBottom: '1px solid white',
    borderTop: '1px solid white',
  },
  rightGrid: {
    border: '1px solid white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightGrid2: {
    borderTop: '1px solid white',
    borderRight: '1px solid white',
    borderBottom: '1px solid white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  linkText: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 14,
    lineHeight: '162.9%',
    color: '#FFFFFF',

    textDecoration: 'none',
  },
  linkText2: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 14,
    lineHeight: '162.9%',
    color: '#FFFFFF',
    paddingLeft: '1rem',
    paddingTop: '1rem',
  },
  linkText2Mobile: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 14,
    lineHeight: '162.9%',
    color: '#FFFFFF',
  },
}))

const footerimgs = [
  {
    src: `4.svg`,
    title: 'Mastercard',
  },
  {
    src: `1.svg`,
    title: 'Maestro',
  },
  {
    src: `5.svg`,
    title: 'Visa',
  },
  {
    src: `3.svg`,
    title: 'Česko platí kartou',
  },
  {
    src: `2.svg`,
    title: 'Comgate',
  },
]

function FooterComponent() {
  const classes = useStyles()

  return (
    <Box className={classes.backTheme}>
      <Container>
        <Hidden smDown>
          <Grid container style={{ minHeight: 140, paddingTop: '4rem' }}>
            <Grid item md={3} className={classes.leftGrid}>
              <Link to="/">
                <img
                  src={'/images/footerLogo.svg'}
                  alt="footerLogo"
                  className={classes.footerLogo}
                />
              </Link>
            </Grid>
            <Grid item md={7} className={classes.middleGrid}></Grid>
            <Grid item md={1} className={classes.rightGrid}>
              <Link to="/faq" className={classes.linkText}>
                FAQ
              </Link>
            </Grid>
            <Grid item md={1} className={classes.rightGrid2}>
              <Link to="/contact" className={classes.linkText}>
                O nás
              </Link>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={6}>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.instagram.com/lifecalendar_cz/"
              >
                <IconButton>
                  <InstagramIcon style={{ color: 'white' }}></InstagramIcon>
                </IconButton>
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.facebook.com/LifeCalendar.cz"
              >
                <IconButton>
                  <FacebookIcon style={{ color: 'white' }}></FacebookIcon>
                </IconButton>
              </a>
            </Grid>
            <Grid
              item
              md={6}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Link to="/terms" className={classes.linkText2}>
                Obchodní podmínky
              </Link>
              <Link to="/gdpr" className={classes.linkText2}>
                Ochrana osobních údajů
              </Link>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid container style={{ minHeight: 140, paddingTop: '4rem' }}>
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                border: '1px solid white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Link to="/">
                <img
                  src={'/images/footerLogo.svg'}
                  alt="footerLogo"
                  className={classes.footerLogo}
                />
              </Link>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '1rem',
              }}
            >
              <Link to="/faq" className={classes.linkText}>
                FAQ
              </Link>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '1rem',
              }}
            >
              <Link to="/contact" className={classes.linkText}>
                O nás
              </Link>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.instagram.com/lifecalendar_cz/"
              >
                <IconButton>
                  <InstagramIcon style={{ color: 'white' }}></InstagramIcon>
                </IconButton>
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.facebook.com/LifeCalendar.cz"
              >
                <IconButton>
                  <FacebookIcon style={{ color: 'white' }}></FacebookIcon>
                </IconButton>
              </a>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Link to="/terms" className={classes.linkText2Mobile}>
                Obchodní podmínky
              </Link>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '1rem',
                paddingBottom: '4rem',
              }}
            >
              <a href="/gdpr" className={classes.linkText2Mobile}>
                Ochrana osobních údajů
              </a>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden smDown>
          <Box
            style={{
              display: 'flex',
              position: 'left',
              gap: '40px',
              paddingTop: '20px',
              paddingBottom: '30px',
              justifyContent: 'flex-end',
            }}
          >
            {footerimgs.map((img) => (
              <img
                className={classes.image}
                key={img.title}
                src={`/images/footer/${img.src}`}
                alt={img.title}
              />
            ))}
          </Box>
        </Hidden>

        <Hidden mdUp>
          <Box
            style={{
              display: 'flex',
              gap: '50px 120px',
              flexWrap: 'wrap',
              justifyContent: 'center',
              paddingBottom: '20px',
            }}
          >
            {footerimgs.map((img) => (
              <img
                className={classes.image}
                key={img.title}
                src={`/images/footer/${img.src}`}
                alt={img.title}
              />
            ))}
          </Box>
        </Hidden>
      </Container>
    </Box>
  )
}

export default FooterComponent
