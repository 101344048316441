import React from 'react'
import { makeStyles, withStyles } from '@material-ui/styles'
import {
  Box,
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  title1: {
    fontFamily: 'Bodoni',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 18,
    lineHeight: '173.5%',
    letterSpacing: '0.13em',
    textTransform: 'uppercase',
    color: '#1B1B1B',
  },
  title2: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '17px',
    textTransform: 'uppercase',
    color: '#1B1B1B',
  },
  textOverviewFont: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '156.4%',
    color: '#000000',
  },
  chechboxText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '17px',
    color: '#000000',
  },
}))

const MyTextFieldBig = withStyles({
  root: {
    width: '100%',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: `0 0 0 0`,
      },
    },
    '& .MuiFormLabel-root': {
      paddingTop: '1rem',
    },
  },
})(TextField)

function KidCustomOverviewComponent({ values, onFormChange }) {
  const classes = useStyles()

  return (
    <Grid container>
      <Grid item md={12}>
        <Grid item md={12} style={{ borderBottom: '1px solid black' }}>
          <Typography className={classes.title1}>Kontrola obrazu</Typography>
        </Grid>
        <Grid item md={12} style={{ display: 'flex', paddingTop: '1rem' }}>
          <Typography className={classes.textOverviewFont}>
            Prosím, překontolujte si údaje zadané Vámi a správnost všech
            informací.
          </Typography>
        </Grid>
        <Grid item md={12} style={{ paddingTop: '3rem' }}>
          <Box
            style={{
              backgroundColor: '#F0EEEB',
              paddingLeft: '1rem',
            }}
          >
            <FormControlLabel
              className={classes.chechboxText}
              control={
                <Checkbox
                  name="agreement"
                  checked={values.agreement}
                  onChange={(e) =>
                    onFormChange({
                      ...values,
                      agreement: e.target.checked,
                    })
                  }
                />
              }
              label={
                <div style={{ padding: '0.5rem 0 0.5rem 0.5rem' }}>
                  Souhlasím s tím, že údaje mnou zadané jsou bez vad
                </div>
              }
            ></FormControlLabel>
          </Box>
        </Grid>
        <Grid
          item
          md={12}
          style={{ borderBottom: '1px solid black', paddingTop: '6rem' }}
        >
          <Typography className={classes.title1}>Poznámky</Typography>
        </Grid>
        <Grid item md={12} style={{ display: 'flex', paddingTop: '1rem' }}>
          <Typography className={classes.textOverviewFont}>
            Každému přijatému tisku pečlivě kontrolujeme kvalitu fotky a
            čitelnost textů. Upozorňujeme, že nekontrolujeme gramatiku Vašich
            textů. Pokud máte nějakou připomínku, nebo požadavek k objednávce,
            prosím, napište ho zde:
          </Typography>
        </Grid>
        <Grid item md={12} style={{ display: 'flex', paddingTop: '1rem' }}>
          <MyTextFieldBig
            id="meno"
            placeholder="Vaše poznámka"
            variant="outlined"
            multiline
            rows={4}
            value={values.annotation}
            onChange={(e) =>
              onFormChange({ ...values, annotation: e.target.value })
            }
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default KidCustomOverviewComponent
