import React from 'react'
import { Box, Container } from '@material-ui/core'
import { Typography, Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

function ErrorNotFound() {
  const { push } = useHistory()
  return (
    <Container
      style={{
        minHeight: 'calc(100vh - 188px)',
        paddingTop: '7rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img src={'/images/Error.png'} style={{ width: '87px' }}></img>
      <Typography variant="h1" align="center">
        Stránka nenalezena
      </Typography>
      <Box width="100%" height="1rem"></Box>
      <Box width="100%" display="flex" justifyContent="center">
        <Button onClick={() => push('/')}>Pokračovat na homepage</Button>
      </Box>
    </Container>
  )
}

export default ErrorNotFound
