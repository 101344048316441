import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box, Typography, Grid } from '@material-ui/core'
import { useOrder } from '../../Context/OrderContext'
import { useCartProvider } from '../../Context/CartContext'

const useStyles = makeStyles(() => ({
  summartyTitle: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',

    paddingTop: '1rem',
    paddingBottom: '1rem',
    paddingLeft: '2rem',
    borderBottom: '1px black solid',
    backgroundColor: 'white',
  },
  summartyTitle2: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 12,
    lineHeight: '20px',
    color: '#000000',
    textAlign: 'left',
  },
  summartyTitle3: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '20px',
    color: '#000000',
    textAlign: 'right',

    paddingRight: '1rem',
  },
  summartyTitle4: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '20px',
    color: '#000000',
  },
  summartyTitle5: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '20px',
    color: '#000000',
  },
  billImage: {
    maxWidth: 35,
    maxHeight: 'auto',
  },
}))

function CartSummary() {
  const classes = useStyles()
  const { orders, voucher, totalPrice, isPersonalDelivery } = useOrder()
  const { payment, DELIVERY_PRICE } = useCartProvider()

  return (
    <Box style={{ border: '1px solid black' }}>
      <Typography className={classes.summartyTitle}>Souhrn</Typography>

      {orders.map((item) => (
        <Grid container key={item.ID_cart_item}>
          <Grid item xs={2} sm={2} md={2}>
            <img
              style={{ padding: '1rem' }}
              alt="OrderKidImage2"
              src={'/images/OrderKidImage2.png'}
              className={classes.billImage}
            ></img>
          </Grid>
          <Grid item xs={7} sm={7} md={7} style={{ paddingLeft: '1rem' }}>
            <Typography
              className={classes.summartyTitle2}
              style={{ paddingTop: '1rem' }}
            >
              {item.Name}
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
            }}
          >
            <Typography className={classes.summartyTitle3}>
              {item.Price} Kč
            </Typography>
          </Grid>
        </Grid>
      ))}
      {/* <Grid container style={{ paddingTop: "3rem" }}>
          <Grid
            item
            xs={9}
            sm={9}
            md={9}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              className={classes.summartyTitle5}
              style={{ paddingLeft: "1rem" }}
            >
              Sleva
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <Typography
              className={classes.summartyTitle5}
              style={{ paddingRight: "2rem" }}
            >
              -125 Kč
            </Typography>
          </Grid>
        </Grid> */}
      <Grid container style={{ paddingTop: '3rem', paddingBottom: '1rem' }}>
        <Grid
          item
          xs={9}
          sm={9}
          md={9}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            className={classes.summartyTitle5}
            style={{ paddingLeft: '1rem' }}
          >
            Doprava
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          sm={3}
          md={3}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Typography
            className={classes.summartyTitle5}
            style={{ paddingRight: '1rem' }}
          >
            {!voucher && isPersonalDelivery && payment.ID_delivery !== 2
              ? DELIVERY_PRICE + ' Kč'
              : 'zdarma'}
          </Typography>
        </Grid>
      </Grid>
      {voucher && (
        <Grid
          container
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Grid item xs={9} sm={9} md={9}>
            <Typography
              className={classes.summartyTitle4}
              style={{
                paddingLeft: '1rem',
                paddingTop: '1rem',
                paddingBottom: '1rem',
              }}
            >
              Sleva ({voucher.code}):
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Typography
              className={classes.summartyTitle4}
              style={{
                textAlign: 'right',
                paddingRight: '1rem',
              }}
            >
              - {voucher.discount} Kč
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        style={{
          display: 'flex',
          alignItems: 'center',
          borderTop: '1px solid black',
        }}
      >
        <Grid item xs={9} sm={9} md={9}>
          <Typography
            className={classes.summartyTitle4}
            style={{
              paddingLeft: '1rem',
              paddingTop: '1rem',
              paddingBottom: '1rem',
            }}
          >
            Konečná suma
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          sm={3}
          md={3}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Typography
            className={classes.summartyTitle4}
            style={{
              textAlign: 'right',
              paddingRight: '1rem',
            }}
          >
            {!voucher && isPersonalDelivery && payment.ID_delivery !== 2
              ? totalPrice + DELIVERY_PRICE
              : totalPrice}{' '}
            Kč
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default CartSummary
