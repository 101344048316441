import React from 'react'
import moment from 'moment'
import { Typography } from '@material-ui/core'
import { useKidOrder } from '../../Context/KidOrderContext'
import { BE_URL } from '../../config'

function KidsDataLayer({ dimensions: { width, height } }) {
  const { formInfo, formDetail } = useKidOrder()

  const getPX = (size) => {
    return `${width * size}px`
  }

  const BASE_SIZE = 0.017
  const BASE_LINE_HEIGHT = 0.02

  return (
    <>
      <img
        style={{
          position: 'absolute',
          top: '10.7%',
          left: '25.7%',
          width: '64.7%',
          height: '70.5%',
          objectFit: 'cover',
          filter: 'contrast(0.5) brightness(1.2)',
        }}
        src={
          formInfo.photo
            ? `${BE_URL}${formInfo.photo}`
            : '/images/editor/baby_placeholder.jpeg'
        }
      />
      <img
        style={{
          position: 'absolute',
          top: '74.5%',
          left: '9%',
          width: '30%',
        }}
        src="/images/editor/kids_day_description.png"
      />
      <Typography
        style={{
          position: 'absolute',
          top: '8.5%',
          left: '8%',
          fontSize: getPX(BASE_SIZE),
          lineHeight: getPX(BASE_SIZE),
          fontWeight: '300',
        }}
      >
        {moment(formDetail.birthDate).format('DD.MM.YYYY')}
      </Typography>
      <Typography
        style={{
          position: 'absolute',
          maxWidth: '170px',
          top: '8.5%',
          right: '10%',
          fontSize: getPX(BASE_SIZE),
          lineHeight: getPX(BASE_SIZE),
          fontWeight: '300',
        }}
      >
        {`${formInfo.name} ${formInfo.surename}`}
      </Typography>
      <Typography
        variant="h1"
        style={{
          position: 'absolute',
          maxWidth: getPX(0.9),
          whiteSpace: 'nowrap',
          top: '11%',
          left: '7.5%',
          fontSize: getPX(0.12),
          lineHeight: getPX(0.12),
          fontWeight: '400',
          overflow: 'hidden',
        }}
      >
        {formInfo.name}
      </Typography>
      <Typography
        style={{
          position: 'absolute',
          maxWidth: getPX(0.4),
          top: '22%',
          left: '8%',
          fontSize: getPX(BASE_SIZE),
          lineHeight: getPX(BASE_LINE_HEIGHT),
          fontWeight: '300',
        }}
      >
        {formInfo.message}
      </Typography>
      <Typography
        style={{
          position: 'absolute',
          width: '60px',
          textAlign: 'center',
          top: '36.7%',
          left: 'calc(17.8% - 2rem)',
          fontSize: getPX(BASE_SIZE),
          lineHeight: getPX(BASE_SIZE),
          fontWeight: '300',
        }}
      >
        {formDetail.sign}
      </Typography>
      <Typography
        style={{
          position: 'absolute',
          width: '60px',
          textAlign: 'center',
          top: '45.5%',
          left: 'calc(17.8% - 2rem)',
          fontSize: getPX(BASE_SIZE),
          lineHeight: getPX(BASE_SIZE),
          fontWeight: '300',
        }}
      >
        {formDetail.weight} g
      </Typography>
      <Typography
        style={{
          position: 'absolute',
          width: '60px',
          textAlign: 'center',
          top: '53%',
          left: 'calc(17.8% - 2rem)',
          fontSize: getPX(BASE_SIZE),
          lineHeight: getPX(BASE_SIZE),
          fontWeight: '300',
        }}
      >
        {formDetail.length} cm
      </Typography>
      <Typography
        style={{
          position: 'absolute',
          width: '93px',
          top: '83.5%',
          left: '11.5%',
          fontSize: getPX(BASE_SIZE),
          lineHeight: getPX(BASE_SIZE),
          fontWeight: '300',
        }}
      >
        {formDetail.birthHour}
      </Typography>
      <Typography
        style={{
          position: 'absolute',
          width: '93px',
          top: '86.7%',
          left: '11.5%',
          fontSize: getPX(BASE_SIZE),
          lineHeight: getPX(BASE_SIZE),
          fontWeight: '300',
        }}
      >
        {formDetail.birthPlace}
      </Typography>
      <Typography
        style={{
          position: 'absolute',
          width: getPX(0.3),
          top: '85.5%',
          left: '60%',
          fontSize: getPX(BASE_SIZE),
          lineHeight: getPX(BASE_SIZE),
          fontWeight: '300',
          fontStyle: 'italic',
        }}
      >
        {`"Bude doplněno ve výrobě"`}
      </Typography>
      <Typography
        style={{
          position: 'absolute',
          width: '93px',
          top: '84%',
          left: '24%',
          fontSize: getPX(BASE_SIZE),
          lineHeight: getPX(BASE_SIZE),
          fontWeight: '300',
          fontStyle: 'italic',
        }}
      >
        {`"Bude doplněno"`}
      </Typography>
    </>
  )
}

export default KidsDataLayer
