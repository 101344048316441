import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography, Box, Grid } from '@material-ui/core'
import FadeIn from './FadeIn'

const useStyles = makeStyles(() => ({
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  textContent: {
    fontFamily: 'EB Garamond',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 20,
    lineHeight: '26px',
    textAlign: 'center',
    color: '#1B1B1B',
  },
  textAuthor: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 13,
    lineHeight: '16px',

    textAlign: 'center',
    color: '#1B1B1B',
  },
}))

function CitationComponent() {
  const classes = useStyles()
  return (
    <Grid
      container
      style={{
        backgroundColor: '#F0EEEB',
        paddingTop: '90px',
        paddingBottom: '90px',
      }}
    >
      <Grid item xs={12}>
        <Box>
          <FadeIn>
            <Typography className={classes.textContent}>
              „Je zhola zbytečné se ptát, má-li život smysl či ne.<br></br> Má
              takový smysl, jaký mu dáme.“
            </Typography>
          </FadeIn>
        </Box>
        <Box>
          <FadeIn>
            <Typography className={classes.textAuthor}>- Seneca</Typography>
          </FadeIn>
        </Box>
      </Grid>
    </Grid>
  )
}

export default CitationComponent

{
  /*    
    <Container className={classes.textContainer}>
      <Box>
        <Typography>
          „Je zhola zbytečné se ptát, má-li život smysl či ne. Má takový smysl,
          jaký mu dáme.“
        </Typography>
        <Typography>- Seneca</Typography>
      </Box>
    </Container> 
    */
}
