import React, { useState } from 'react'
import { Box } from '@material-ui/core'
import CartSlider from '../Components/CartComponents/CartSlider'
import CartDecisionButton from '../Components/CartComponents/CartDecisionButtons'
import CartContent from '../Components/Forms/CartForms/CartContent'
import CartShippingPayment from '../Components/Forms/CartForms/CartShippingPayment'
import CartContactDetails from '../Components/Forms/CartForms/CartContactDetails'
import CartThanks from '../Components/CartComponents/CartThanks'

function CartPage() {
  const [page, setPage] = useState(0)

  return (
    <Box
      style={{
        minHeight: 'calc(100vh - 188px)',
        backgroundColor: '#E5E5E5',
        display: 'flex',
        flexFlow: 'column',
      }}
    >
      <CartSlider page={page}></CartSlider>
      <Box
        style={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          flexFlow: 'column',
        }}
      >
        {page === 0 && <CartContent />}
        {page === 1 && <CartContactDetails />}
        {page === 2 && <CartShippingPayment />}
        {page === 3 && <CartThanks />}
        {page === 3 ? null : (
          <CartDecisionButton
            page={page}
            setPage={setPage}
          ></CartDecisionButton>
        )}
      </Box>
    </Box>
  )
}

export default CartPage
