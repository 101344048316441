import React from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Grid,
  Box,
  Button,
  Typography,
  Container,
  Hidden,
} from '@material-ui/core'
import ConnectorLine from '../Components/ConnectorLine'
import { useHistory } from 'react-router-dom'
import FadeIn from './FadeIn'

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: '1rem',
    fontFamily: 'EB_Garamond',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 52,
    lineHeight: '47px',
    color: '#000',
    [theme.breakpoints.down("xs")]: {
      fontSize: 48,
    }
  },
  text: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '156.4%',
    color: '#000',
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    }
  },
  text2: {
    fontFamily: 'Over the Rainbow',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 30,
    lineHeight: '61px',
    color: '#000',
  },
  backTheme: {
    width: '100%',
    backgroundColor: '#F0EEEB',
  },
}))

function MarriageComponent() {
  const classes = useStyles()
  const { push } = useHistory()

  return (
    <Box className={classes.backTheme}>
      <Container>
        <ConnectorLine />
        <Hidden smDown>
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            style={{
              backgroundColor: '#F0EEEB',
              height: '70vh',
              minHeight: 500,
            }}
          >
            <FadeIn>
              <Grid
                item
                md={6}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  paddingRight: '4rem',
                }}
              >
                <Typography className={classes.title}>Naše svatba</Typography>
                <Typography className={classes.text}>
                  Ať už plánujete malou svatbu nebo velkou událost, tento plakát je skvělým způsobem,
                  jak zdokumentovat všechny důležité detaily vašeho svatebního dne. Navíc je to krásná památka,
                  ke které se můžete opakovaně obracet.
                </Typography>
                <Typography className={classes.text}>
                  Ideální dárek pro novomanžele, nebo na výročí svatby.
                </Typography>
                <br></br>
                <Typography className={classes.text2}>
                  s úctou k lásce
                </Typography>
                <Box>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ borderRadius: 0, paddingInline: 20}}
                    onClick={() => {
                      push('/detail/wedding')
                    }}
                  >
                    PODROBNOSTI
                  </Button>
                </Box>
              </Grid>
            </FadeIn>
            <Grid
              item
              md={6}
              style={{
                paddingLeft: '4rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <FadeIn>
                <img width="100%" src={'/images/marriageHome.webp'} />
              </FadeIn>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            style={{
              paddingTop: '4rem',
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <FadeIn>
                <img width="100%" src={'/images/marriageHome.webp'} />
              </FadeIn>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                paddingTop: '2rem',
                paddingBottom: '4rem',
              }}
            >
              <Grid item xs={12} sm={12} style={{ margin: '0 auto' }}>
                <Typography className={classes.title}>Naše svatba</Typography>
                <Typography className={classes.text}>
                  Ať už plánujete malou svatbu nebo velkou událost, tento plakát je skvělým způsobem,
                  jak zdokumentovat všechny důležité detaily vašeho svatebního dne. Navíc je to krásná památka,
                  ke které se můžete opakovaně obracet.
                </Typography>
                <Typography className={classes.text}>
                  Ideální dárek pro novomanžele, nebo na výročí svatby.
                </Typography>
                <br></br>
                <Typography className={classes.text2}>
                  s úctou k lásce
                </Typography>
                <Box>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ borderRadius: 0, paddingInline: 20}}
                    onClick={() => {
                      push('/detail/wedding')
                    }}
                  >
                    PODROBNOSTI
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
      </Container>
    </Box>
  )
}

export default MarriageComponent
