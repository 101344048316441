import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Box,
  Grid,
  Typography,
  Card,
  CardActionArea,
} from '@material-ui/core'

function Life() {
  const { push } = useHistory()
  return (
    <>
      <Typography variant="h1">LifeCalendar</Typography>
      <Box width="100%" height="0.5rem"></Box>
      <Typography variant="subtitle1">
        PERSOLIZOVANÉ DESIGNOVÉ PLAKÁTY
      </Typography>
      <Box width="100%" height="2rem"></Box>
      <Typography variant="p">Vyberte si, prosím, variantu:</Typography>
      <Box width="100%" height="1rem"></Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box
            style={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Card>
              <CardActionArea
                onClick={() => push('/detail/life/framed')}
                style={{
                  display: 'flex',
                }}
              >
                <img src="/images/lifeBasic.png" />
              </CardActionArea>
            </Card>
            <Button
              onClick={() => push('/detail/life/framed')}
              style={{
                position: 'absolute',
                bottom: '-1rem',
                left: '50%',
                transform: 'translate(-50%, 0)',
              }}
            >
              s rámečkem
            </Button>
          </Box>
          <Box width="100%" height="1rem"></Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            style={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Card>
              <CardActionArea
                onClick={() => push('/detail/life')}
                style={{
                  display: 'flex',
                }}
              >
                <img src="/images/lifeBasic.png" />
              </CardActionArea>
            </Card>
            <Button
              onClick={() => push('/detail/life')}
              style={{
                position: 'absolute',
                bottom: '-1rem',
                left: '50%',
                transform: 'translate(-50%, 0)',
              }}
            >
              bez rámečku
            </Button>
          </Box>
          <Box width="100%" height="1rem"></Box>
        </Grid>
      </Grid>
    </>
  )
}

export default Life
