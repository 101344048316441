import React, { useRef } from 'react'
import { useOnLoadImages } from '../hooks/useOnLoadImages'

const ImagesContext = React.createContext()

const ImagesProvider = ({ children }) => {
  const wrapperRef = useRef(null)
  const imagesLoaded = useOnLoadImages(wrapperRef)

  return (
    <div style={{ width: '100%', height: '100%' }} ref={wrapperRef}>
      <ImagesContext.Provider
        value={{
          imagesLoaded,
        }}
      >
        {children}
      </ImagesContext.Provider>
    </div>
  )
}

const useImages = () => React.useContext(ImagesContext)
export { ImagesProvider, useImages }
