import React, { useRef } from 'react'
import { makeStyles, withStyles } from '@material-ui/styles'
import {
  Box,
  Grid,
  TextField,
  Typography,
  Button,
  FormHelperText,
  LinearProgress,
} from '@material-ui/core'
import PublishIcon from '@material-ui/icons/Publish'
import Check from '@material-ui/icons/Check'
import { useKidOrder } from '../../../Context/KidOrderContext'
import { useAuth } from '../../../Context/AuthContext'
import { red } from '@material-ui/core/colors'
import FrameColorSelect from '../../Editor/FrameColorSelect'

const useStyles = makeStyles((theme) => ({
  title1: {
    fontFamily: 'Bodoni',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 18,
    lineHeight: '173.5%',
    letterSpacing: '0.13em',
    textTransform: 'uppercase',
    color: '#1B1B1B',
  },

  title2: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '17px',
    textTransform: 'uppercase',
    color: '#1B1B1B',
  },
  error: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: red,
  },
  uploadButton: {
    width: '100%',
    height: 118,
    borderRadius: 0,
  },
  uploadButtonText1: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: '138.4%',
    color: '#505050',
    marginTop: '0.5rem',
  },
  uploadButtonText2: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 10,
    lineHeight: '138.4%',
    color: '#505050',
  },
  photoError: {
    color: theme.palette.error.main,
    marginLeft: '15px',
  },
  description: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '17px',
    color: '#1B1B1B',
  },
  messageUntouched: {
    color: '#b9b9b9',
  },
}))

const MyTextField = withStyles({
  root: {
    width: '100%',
    height: 'auto',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: `0 0 0 0`,
      },
    },
    '& .MuiFormLabel-root': {
      paddingTop: '2rem',
    },
  },
})(TextField)

const MyTextFieldBig = withStyles({
  root: {
    width: '100%',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: `0 0 0 0`,
      },
    },
    '& .MuiFormLabel-root': {
      paddingTop: '1rem',
    },
  },
})(TextField)

const UploadImgButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#FFFFFF'),
    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
    boxShadow: 'none !important',
    '&.Mui-disabled': {
      backgroundColor: '#f3f3f3',
    },
  },
}))(Button)

function KidCutomInformationComponent({ errors, framed }) {
  const classes = useStyles()

  const { uploadFile } = useAuth()
  const { formInfo, setFormInfo, messageTouched, setMessageTouched } =
    useKidOrder()
  const uploadInput = useRef()
  const onButtonClick = () => {
    uploadInput.current.click()
  }

  const onFileUploaded = (res) => {
    setFormInfo({ ...formInfo, photo: res, isUploading: false })
  }

  const handlePhotoUpload = (e) => {
    if (e.target.files[0]) {
      setFormInfo({ ...formInfo, isUploading: true })
      uploadFile('/cart/upload', e.target.files[0], onFileUploaded)
    }
  }

  const surenameError = errors?.find(
    (error) => error.path === 'surename'
  )?.message

  const nameError = errors?.find((error) => error.path === 'name')?.message
  const photoError = errors?.find((error) => error.path === 'photo')?.message

  const messageError = errors?.find(
    (error) => error.path === 'message'
  )?.message

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Grid item xs={12} md={12} style={{ borderBottom: '1px solid black' }}>
          <Typography className={classes.title1}>Informace</Typography>
        </Grid>
        {framed && (
          <FrameColorSelect
            value={formInfo.colorSelect}
            onChange={(e) =>
              setFormInfo({ ...formInfo, colorSelect: e.target.value })
            }
          ></FrameColorSelect>
        )}
        <Typography className={classes.title2} style={{ paddingTop: '1rem' }}>
          Jméno
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <MyTextField
              id="name"
              placeholder="Matyáš"
              variant="outlined"
              value={formInfo.name}
              error={!!nameError}
              helperText={nameError}
              onChange={(e) =>
                setFormInfo({ ...formInfo, name: e.target.value })
              }
            ></MyTextField>
          </Grid>

          <Grid item xs={6}>
            <MyTextField
              id="surename"
              placeholder="Veselý"
              variant="outlined"
              value={formInfo.surename}
              error={!!surenameError}
              helperText={surenameError}
              onChange={(e) =>
                setFormInfo({ ...formInfo, surename: e.target.value })
              }
            />
          </Grid>
        </Grid>
        <Typography className={classes.title2} style={{ paddingTop: '2rem' }}>
          Fotka
        </Typography>
        <Box style={{ paddingTop: '1rem' }}>
          <input
            ref={uploadInput}
            style={{ display: 'none' }}
            type="file"
            accept=".jpg, .jpeg, .png, .heic"
            disabled={formInfo.isUploading}
            onInput={handlePhotoUpload}
          />
          <UploadImgButton
            variant="contained"
            color="default"
            component="label"
            htmlFor="imageUpload"
            error={!!photoError}
            className={classes.uploadButton}
            onClick={onButtonClick}
            disabled={formInfo.isUploading}
            style={{ borderColor: photoError ? '#f44336' : undefined }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {formInfo.isUploading ? (
                <>
                  <LinearProgress style={{ width: '100%' }} />
                  <Typography className={classes.uploadButtonText1}>
                    Nahrávání obrázku
                  </Typography>
                </>
              ) : formInfo.photo ? (
                <>
                  <Check></Check>
                  <Typography className={classes.uploadButtonText1}>
                    Obrázek úspěšně uložen
                  </Typography>
                </>
              ) : (
                <>
                  <PublishIcon></PublishIcon>
                  <Typography className={classes.uploadButtonText1}>
                    Vybrat soubor
                  </Typography>
                  <Typography className={classes.uploadButtonText2}>
                    (jpg, png, heic)
                  </Typography>
                </>
              )}
            </Box>
          </UploadImgButton>

          <FormHelperText className={classes.photoError}>
            {photoError}
          </FormHelperText>
        </Box>

        <Typography className={classes.title2} style={{ paddingTop: '2rem' }}>
          Vzkaz
        </Typography>
        <Typography
          className={classes.description}
          style={{ paddingTop: '0.5rem' }}
        >
          Napište miminku krátký citát nebo vzkaz do života
        </Typography>
        <MyTextFieldBig
          id="message"
          variant="outlined"
          multiline
          rows={4}
          InputProps={{
            className: !messageTouched && classes.messageUntouched,
          }}
          value={formInfo.message}
          error={!!messageError}
          helperText={messageError}
          onChange={(e) =>
            setFormInfo({ ...formInfo, message: e.target.value })
          }
          onClick={() => setMessageTouched(true)}
        />
      </Grid>
    </Grid>
  )
}

export default KidCutomInformationComponent
