import React from 'react'
import { makeStyles, withStyles } from '@material-ui/styles'
import {
  Box,
  Container,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core'
import { useCartProvider } from '../../../Context/CartContext'
import CartBillContactDetails from './CartBillContactDetails'
import CartSummary from '../../CartComponents/CartSummary'
import { useMediaQuery } from 'react-responsive'

const useStyles = makeStyles(() => ({
  backTheme: {
    width: '100%',
    backgroundColor: '#E5E5E5',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    color: '#000000',
    paddingTop: '1rem',
  },
}))

const MyTextField = withStyles({
  root: {
    paddingBottom: '0rem',
  },
})(TextField)

const MyFormControl = withStyles({
  root: {
    marginTop: '1rem',
    width: '100%',
  },
})(FormControl)

const MySelect = withStyles({
  root: {
    padding: '8px 14px',
  },
})(Select)

function CartContactDetails() {
  const classes = useStyles()

  const { shipping, setShipping } = useCartProvider()

  const isMobile = useMediaQuery({ query: `(max-width: 959px)` })

  return (
    <Box className={classes.backTheme}>
      <Container>
        <Box style={{ display: isMobile ? 'block' : 'flex', width: '100%' }}>
          <Box
            style={{
              width: isMobile ? '100%' : '60%',
            }}
          >
            <Box
              style={{
                paddingLeft: '1rem',
                paddingRight: '1rem',
                border: '1px solid black',
              }}
            >
              <MyTextField
                size="small"
                id="firstname"
                placeholder="Jméno"
                variant="outlined"
                value={shipping.First_name}
                onChange={(e) =>
                  setShipping({ ...shipping, First_name: e.target.value })
                }
              />
              <MyTextField
                size="small"
                id="lastname"
                placeholder="Přijímení"
                variant="outlined"
                value={shipping.Last_name}
                onChange={(e) =>
                  setShipping({ ...shipping, Last_name: e.target.value })
                }
              />
              <MyTextField
                size="small"
                id="email"
                type="email"
                placeholder="Email"
                variant="outlined"
                value={shipping.Email}
                onChange={(e) =>
                  setShipping({ ...shipping, Email: e.target.value })
                }
              />
              <MyTextField
                size="small"
                id="phone"
                type="phone"
                placeholder="Telefon"
                variant="outlined"
                value={shipping.Phone}
                onChange={(e) =>
                  setShipping({ ...shipping, Phone: e.target.value })
                }
              />
              <Typography className={classes.title}>
                Fakturační adresa
              </Typography>
              <MyTextField
                size="small"
                id="address"
                placeholder="Ulice"
                variant="outlined"
                value={shipping.Street}
                onChange={(e) =>
                  setShipping({ ...shipping, Street: e.target.value })
                }
              />
              <MyTextField
                size="small"
                id="city"
                placeholder="Město"
                variant="outlined"
                value={shipping.City}
                onChange={(e) =>
                  setShipping({ ...shipping, City: e.target.value })
                }
              />
              <MyTextField
                size="small"
                id="zipCode"
                placeholder="PSČ"
                variant="outlined"
                value={shipping.Postal_code}
                onChange={(e) =>
                  setShipping({ ...shipping, Postal_code: e.target.value })
                }
              />
              <MyFormControl variant="outlined">
                <InputLabel id="select-outlined-label">Stát</InputLabel>
                <MySelect
                  labelId="select-outlined-label"
                  id="select-outlined"
                  value="ČR"
                  onChange={() => {}}
                  label="Stát"
                >
                  <MenuItem value="ČR">
                    <em>ČR</em>
                  </MenuItem>
                </MySelect>
              </MyFormControl>
              <Typography className={classes.title}>
                Doručovací adresa
              </Typography>
              <FormControlLabel
                className={classes.label}
                control={
                  <Checkbox
                    name="correctAddress"
                    color="default"
                    checked={shipping.agreement}
                    onChange={(e) => {
                      setShipping({
                        ...shipping,
                        agreement: e.target.checked,
                      })
                    }}
                  />
                }
                label={
                  <Typography className={classes.title} style={{ padding: 0 }}>
                    Doručovací adresa je stejná jako fakturační adresa
                  </Typography>
                }
              />
              {shipping.agreement === false && <CartBillContactDetails />}
            </Box>
            {/*             <Box border="1px solid black" px="1rem" pb="1rem" mt={2}>
              <Typography className={classes.title}>
                Přejete si vytvořit účet?
              </Typography>
              <MyTextField
                size="small"
                id="password"
                type="password"
                placeholder="Heslo"
                variant="outlined"
                value={shipping.accountPassword}
                onChange={(e) =>
                  setShipping({ ...shipping, accountPassword: e.target.value })
                }
              />
              <MyTextField
                size="small"
                id="password-confirm"
                type="password"
                placeholder="Potvrzení hesla"
                variant="outlined"
                value={shipping.accountPasswordConfirm}
                onChange={(e) =>
                  setShipping({
                    ...shipping,
                    accountPasswordConfirm: e.target.value,
                  })
                }
              />
              <FormControlLabel
                className={classes.title}
                control={
                  <Checkbox
                    name="accountAgreement"
                    color="default"
                    checked={shipping.accountAgreement}
                    onChange={(e) => {
                      setShipping({
                        ...shipping,
                        accountAgreement: e.target.checked,
                      })
                    }}
                  />
                }
                label={
                  <Typography className={classes.title} style={{ padding: 0 }}>
                    Souhlasím s podmínkami pro vytvoření účtu
                  </Typography>
                }
              />
            </Box> */}
          </Box>
          {!isMobile && <Box style={{ width: '5%' }}></Box>}
          <Box
            style={{ width: isMobile ? '100%' : '35%' }}
            mt={isMobile ? 2 : undefined}
          >
            <CartSummary></CartSummary>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default CartContactDetails
