import React from 'react'
import Terms from '../../Components/TextComponents/Terms'

function TermsPage() {
  return (
    <>
      <Terms />
    </>
  )
}

export default TermsPage
