import React from "react";
import LifeCalendar from "../Components/LifeCalendarOffer";
function LifeCalendarPage() {
  return (
    <>
      <LifeCalendar />
    </>
  );
}

export default LifeCalendarPage;
