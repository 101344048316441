import React from 'react'
import { makeStyles, withStyles } from '@material-ui/styles'
import {
  Container,
  Box,
  Grid,
  Button,
  Hidden,
  CircularProgress,
} from '@material-ui/core'
import { useCartProvider } from '../../Context/CartContext'
import { useOrder } from '../../Context/OrderContext'

const useStyles = makeStyles(() => ({
  backTheme: {
    width: '100%',
    backgroundColor: '#E5E5E5',
  },
  buttonText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '20px',
    textAlign: 'center',
    color: '#FFFFFF',
  },
}))

const NextButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#313131'),
    backgroundColor: '#313131',
    '&:hover': {
      backgroundColor: '#313131',
    },
  },
}))(Button)

const BackToCartButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#E5E5E5'),
    backgroundColor: '#E5E5E5',
    '&:hover': {
      backgroundColor: '#E5E5E5',
    },
  },
}))(Button)

function CartDecisionButton({ page, setPage }) {
  const classes = useStyles()
  const {
    order,
    createAccount,
    shipping,
    submitting,
    payment,
    fetchPaymentURL,
    setPayment,
    DEFAULT_PAYMENT,
  } = useCartProvider()
  const { loadOrders, orders, setGatewayResponse, totalPrice, voucher } =
    useOrder()

  const nextPage = async () => {
    if (page === 1) {
      await createAccount()
      setPage(page + 1)
    } else if (page === 2) {
      const hash = await order()
      window.dataLayer.push({
        event: 'purchase',
        eventProps: {
          transaction_id: hash,
          value: totalPrice,
          currency: 'CZK',
          items: orders.map((item) => ({ item_name: item.Name })),
        },
      })

      if (payment.Payment === 'card' && !voucher) {
        const url = await fetchPaymentURL(hash)
        setGatewayResponse(url)
      } else {
        setPage(page + 1)
        setPayment(DEFAULT_PAYMENT)
        await loadOrders()
      }
    } else {
      setPage(page + 1)
    }
  }

  const shippingValid = !(
    shipping.First_name === '' ||
    shipping.Last_name === '' ||
    shipping.Email === '' ||
    shipping.Phone === '' ||
    shipping.Street === '' ||
    shipping.City === '' ||
    shipping.Postal_code === '' ||
    shipping.Country === ''
  )

  return (
    <Box className={classes.backTheme}>
      <Container style={{ paddingBottom: '4rem' }} maxWidth="lg">
        <Hidden xsDown>
          <Grid
            container
            style={{
              paddingTop: '2rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Grid item md={6} style={{ display: 'flex' }}>
              <Box>
                <BackToCartButton
                  variant="outlined"
                  style={{
                    borderRadius: 0,
                    padding: '1rem',
                  }}
                  className={classes.buttonText}
                  disabled={page === 0}
                  onClick={() => setPage(page - 1)}
                >
                  Zpět
                </BackToCartButton>
              </Box>
            </Grid>
            <Grid
              item
              md={6}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Box>
                <NextButton
                  disabled={
                    !orders.length ||
                    (page === 1 && !shippingValid) ||
                    submitting
                  }
                  variant="contained"
                  style={{ borderRadius: 0, padding: '1rem', minWidth: 122 }}
                  className={classes.buttonText}
                  onClick={() => nextPage()}
                >
                  {(page === 0 || page === 1) && 'Pokračovat'}
                  {page === 2 && !submitting && 'Objednat'}
                  {submitting && <CircularProgress size={20} />}
                </NextButton>
              </Box>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid
            container
            style={{
              paddingTop: '2rem',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Box style={{ width: '100%' }}>
                <NextButton
                  disabled={
                    !orders?.length ||
                    (page === 1 && !shippingValid) ||
                    submitting
                  }
                  variant="contained"
                  style={{ borderRadius: 0, padding: '1rem', width: '100%' }}
                  className={classes.buttonText}
                  onClick={() => nextPage()}
                >
                  {(page === 0 || page === 1) && 'Pokračovat'}
                  {page === 2 && !submitting && 'Objednat'}
                  {submitting && <CircularProgress size={20} />}
                </NextButton>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '1rem',
                width: '100%',
              }}
            >
              <Box style={{ width: '100%' }}>
                <BackToCartButton
                  variant="outlined"
                  style={{ borderRadius: 0, padding: '1rem', width: '100%' }}
                  className={classes.buttonText}
                  disabled={page === 0}
                  onClick={() => setPage(page - 1)}
                >
                  Zpět
                </BackToCartButton>
              </Box>
            </Grid>
          </Grid>
        </Hidden>
      </Container>
    </Box>
  )
}

export default CartDecisionButton
