import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Container, Box, Grid, Typography } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  backTheme: {
    width: '100%',
    backgroundColor: '#E5E5E5',
  },
  text: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '20px',
    color: '#000000',
    textAlign: 'left',
    paddingTop: '2rem',
  },
}))

function GDPR() {
  const classes = useStyles()

  return (
    <Box className={classes.backTheme}>
      <Container maxWidth="lg">
        <Grid container>
          <Grid
            xs={12}
            sm={12}
            md={12}
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column',
              paddingTop: '7rem',
              paddingBottom: '8rem',
            }}
          >
            <Typography variant="h1">Ochrana osobních údajů</Typography>
            <p style={{ textAlign: 'center' }}>
              <strong>I.</strong>
            </p>

            <p>
              <strong>Základní ustanovení</strong>
            </p>

            <ol>
              <li>
                Správcem osobních údajů podle čl. 4 bod 7 nařízení Evropského
                parlamentu a Rady (EU) 2016/679 o ochraně fyzických osob v
                souvislosti se zpracováním osobních údajů a o volném pohybu
                těchto údajů (dále jen: „GDPR”) je Lucie Přikrylová, IČ
                09142886, sídlem Zahradní 42/1005, Havířov-Šumbark (dále jen:
                „správce“).
              </li>
              <li>Kontaktní údaje správce jsou</li>
            </ol>

            <p>tel. +420 739 831 800</p>

            <p>
              email:{' '}
              <a href="mailto:tvuj@lifecalendar.cz">tvuj@lifecalendar.cz</a>
            </p>

            <ol>
              <li>
                Osobními údaji se rozumí veškeré informace o identifikované nebo
                identifikovatelné fyzické osobě; identifikovatelnou fyzickou
                osobou je fyzická osoba, kterou lze přímo či nepřímo
                identifikovat, zejména odkazem na určitý identifikátor,
                například jméno, identifikační číslo, lokační údaje, síťový
                identifikátor nebo na jeden či více zvláštních prvků fyzické,
                fyziologické, genetické, psychické, ekonomické, kulturní nebo
                společenské identity této fyzické osoby.
              </li>
              <li>Správce nejmenoval pověřence pro ochranu osobních údajů.</li>
            </ol>

            <p>&nbsp;</p>

            <p style={{ textAlign: 'center' }}>
              <strong>II.</strong>
            </p>

            <p>
              <strong>Zdroje a kategorie zpracovávaných osobních údajů</strong>
            </p>

            <ol>
              <li>
                Správce zpracovává osobní údaje, které jste mu poskytl/a nebo
                osobní údaje, které správce získal na základě plnění Vaší
                objednávky.
              </li>
              <li>
                Správce zpracovává Vaše identifikační a kontaktní údaje a údaje
                nezbytné pro plnění smlouvy.
              </li>
            </ol>

            <p>&nbsp;</p>

            <p style={{ textAlign: 'center' }}>
              <strong>III.</strong>
            </p>

            <p>
              <strong>Zákonný důvod a účel zpracování osobních údajů</strong>
            </p>

            <ol>
              <li>Zákonným důvodem zpracování osobních údajů je</li>
            </ol>

            <ul>
              <li>
                oprávněný zájem správce na poskytování přímého marketingu
                (zejména pro zasílání obchodních sdělení a newsletterů) podle
                čl. 6 odst. 1 písm. f) GDPR,
              </li>
              <li>
                Váš souhlas se zpracováním pro účely poskytování přímého
                marketingu (zejména pro zasílání obchodních sdělení a
                newsletterů) podle čl. 6 odst. 1 písm. a) GDPR ve spojení s § 7
                odst. 2 zákona č. 480/2004 Sb., o některých službách informační
                společnosti v případě, že nedošlo k objednávce zboží nebo
                služby.
              </li>
            </ul>

            <ol>
              <li>Účelem zpracování osobních údajů je</li>
            </ol>

            <ul>
              <li>
                vyřízení Vaší objednávky a výkon práv a povinností vyplývajících
                ze smluvního vztahu mezi Vámi a správcem; při objednávce jsou
                vyžadovány osobní údaje, které jsou nutné pro úspěšné vyřízení
                objednávky (jméno a adresa, kontakt), poskytnutí osobních údajů
                je nutným požadavkem pro uzavření a plnění smlouvy, bez
                poskytnutí osobních údajů není možné smlouvu uzavřít či jí ze
                strany správce plnit,
              </li>
              <li>
                zasílání obchodních sdělení a činění dalších marketingových
                aktivit.
              </li>
            </ul>

            <ol>
              <li>
                Ze strany správce nedochází k automatickému individuálnímu
                rozhodování ve smyslu čl. 22 GDPR. S takovým zpracováním jste
                poskytl/a svůj výslovný souhlas.
              </li>
            </ol>

            <p>&nbsp;</p>

            <p>&nbsp;</p>

            <p style={{ textAlign: 'center' }}>
              <strong>IV.</strong>
            </p>

            <p>
              <strong>Doba uchovávání údajů</strong>
            </p>

            <ol>
              <li>Správce uchovává osobní údaje</li>
            </ol>

            <ul>
              <li>
                po dobu nezbytnou k výkonu práv a povinností vyplývajících ze
                smluvního vztahu mezi Vámi a správcem a uplatňování nároků z
                těchto smluvních vztahů (po dobu 15 let od ukončení smluvního
                vztahu).
              </li>
              <li>
                po dobu, než je odvolán souhlas se zpracováním osobních údajů
                pro účely marketingu, nejdéle 15 let, jsou-li osobní údaje
                zpracovávány na základě souhlasu.
              </li>
            </ul>

            <ol>
              <li>
                Po uplynutí doby uchovávání osobních údajů správce osobní údaje
                vymaže.
              </li>
            </ol>

            <p>&nbsp;</p>

            <p>
              <strong>V.</strong>
            </p>

            <p>
              <strong>Příjemci osobních údajů (subdodavatelé správce)</strong>
            </p>

            <ol>
              <li>Příjemci osobních údajů jsou osoby</li>
            </ol>

            <ul>
              <li>
                podílející se na dodání zboží / služeb / realizaci plateb na
                základě smlouvy,
              </li>
              <li>
                zajišťující služby provozování e-shopu a další služby v
                souvislosti s provozováním e-shopu,
              </li>
              <li>zajišťující marketingové služby</li>
            </ul>

            <ol>
              <li>
                Správce má v úmyslu předat osobní údaje do třetí země (do země
                mimo EU) nebo mezinárodní organizaci. Příjemci osobních údajů ve
                třetích zemích jsou poskytovatelé mailingových služeb /
                cloudových služeb.
              </li>
            </ol>

            <p>&nbsp;</p>

            <p style={{ textAlign: 'center' }}>
              <strong>VI.</strong>
            </p>

            <p>
              <strong>Vaše práva</strong>
            </p>

            <ol>
              <li>Za podmínek stanovených v GDPR máte</li>
            </ol>

            <ul>
              <li>právo na přístup ke svým osobním údajům dle čl. 15 GDPR,</li>
              <li>
                právo opravu osobních údajů dle čl. 16 GDPR, popřípadě omezení
                zpracování dle čl. 18 GDPR.
              </li>
              <li>právo na výmaz osobních údajů dle čl. 17 GDPR.</li>
              <li>právo vznést námitku proti zpracování dle čl. 21 GDPR a</li>
              <li>právo na přenositelnost údajů dle čl. 20 GDPR.</li>
              <li>
                právo odvolat souhlas se zpracováním písemně nebo elektronicky
                na adresu nebo email správce uvedený v čl. III těchto podmínek.
              </li>
            </ul>

            <ol>
              <li>
                Dále máte právo podat stížnost u Úřadu pro ochranu osobních
                údajů v případě, že se domníváte, že bylo porušeno Vaší právo na
                ochranu osobních údajů.
              </li>
            </ol>

            <p>&nbsp;</p>

            <p style={{ textAlign: 'center' }}>
              <strong>VII.</strong>
            </p>

            <p>
              <strong>Podmínky zabezpečení osobních údajů</strong>
            </p>

            <ol>
              <li>
                Správce prohlašuje, že přijal veškerá vhodná technická a
                organizační opatření k zabezpečení osobních údajů.
              </li>
              <li>
                Správce přijal technická opatření k zabezpečení datových úložišť
                a úložišť osobních údajů v listinné podobě, zejména použitím
                zámků, zálohy, hesel a antivirového programu.
              </li>
              <li>
                Správce prohlašuje, že k osobním údajům mají přístup pouze jím
                pověřené osoby.
              </li>
            </ol>

            <p>&nbsp;</p>

            <p style={{ textAlign: 'center' }}>
              <strong>VIII.</strong>
            </p>

            <p>
              <strong>Závěrečná ustanovení</strong>
            </p>

            <ol>
              <li>
                Odesláním objednávky z internetového objednávkového formuláře
                potvrzujete, že jste seznámen/a s podmínkami ochrany osobních
                údajů a že je v celém rozsahu přijímáte.
              </li>
              <li>
                Správce je oprávněn tyto podmínky změnit. Novou verzi podmínek
                ochrany osobních údajů zveřejní na svých internetových stránkách
                a zároveň Vám zašle novou verzi těchto podmínek Vaši e-mailovou
                adresu, kterou jste správci poskytl/a.
              </li>
            </ol>

            <p>&nbsp;</p>

            <p>Tyto podmínky nabývají účinnosti dnem 1. 1. 2022</p>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default GDPR
