import React, { useState } from 'react'
import VizSensor from 'react-visibility-sensor'
import { Fade } from '@material-ui/core'

export default function FadeIn({ children }) {
  const [active, setActive] = useState(false)

  return (
    <VizSensor
      partialVisibility
      onChange={(isVisible) => {
        if (!active) setActive(isVisible)
      }}
    >
      <Fade in={active} timeout={1500}>
        {children}
      </Fade>
    </VizSensor>
  )
}
