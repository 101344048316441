import React, { createContext, useState, useEffect } from 'react'
import axios from 'axios'
import useLocalStorage from '../hooks/useLocalStorage'
import dataURLToBlob from '../helpers/dataURLToBlob'
import { BE_API_URL } from '../config'
import { useSnackBars } from '../Context/ErrorSnackbarContext'
import heic2any from 'heic2any'

export const AuthContext = createContext()

const AuthProvider = (props) => {
  const [status, setStatus] = useState()

  const { addAlert } = useSnackBars()
  const [authToken, setAuthToken] = useLocalStorage('auth')
  const [refreshToken, setRefreshToken] = useLocalStorage('refresh')

  const login = async (email, password) => {
    try {
      const res = await axios.get(BE_API_URL + '/user/login', {
        params: { email, password },
      })
      if (res.status === 404) {
        setStatus('error')
        return
      }
      setAuthToken(res.data.access_token)
      setRefreshToken(res.data.refresh_token)
    } catch (error) {
      console.error('error', error)
    }
  }

  const getToken = async (getNew = false) => {
    try {
      const storageToken = localStorage.getItem('auth')
      if (storageToken && !getNew) return JSON.parse(storageToken)
      const res = await axios.get(BE_API_URL + '/auth/')
      if (res.status !== 200 || !res.data) {
        setStatus('error')
        return
      }
      localStorage.setItem('auth', JSON.stringify(res.data.accessToken))
      return res.data.accessToken
    } catch (error) {
      console.error('error', error)
    }
  }

  /*   const renewAccessToken = async () => {
    try {
      const res = await axios.post(BE_API_URL + '/auth/refreshToken', {
        headers: {
          Authorization: 'Bearer ' + refreshToken,
        },
      })
      if (res.status === 404) {
        setStatus('error')
        return
      }
      setAuthToken(res.data.access_token)
      setRefreshToken(res.data.refresh_token)
      return res.data.access_token
    } catch (error) {
      console.error('error', error)
    }
  }

  const logout = () => {
    setAuthToken(null)
    setRefreshToken(null)
  } */

  const getResult = async (res) => {
    if ((res.status !== 200 && res.status !== 201) || !res.data) {
      console.error(res)
      return
    }
    return res.data
  }

  const handleError = async (error) => {
    if (error.response) {
      if (error.response.status === 403) {
        getToken(true)
      }
      const message = error.response?.data?.error
      if (message) {
        addAlert(message)
      }
    }
  }

  const getData = async (url, params = {}) => {
    const token = await getToken()
    try {
      let res = await axios.get(BE_API_URL + url, {
        params,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      return await getResult(res)
    } catch (error) {
      handleError(error)
    }
  }

  const postData = async (url, params = {}) => {
    const token = await getToken()
    try {
      let res = await axios.post(BE_API_URL + url, params, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      return await getResult(res)
    } catch (error) {
      handleError(error)
    }
  }

  const deleteData = async (url, params = {}) => {
    const token = await getToken()
    try {
      let res = await axios.delete(BE_API_URL + url, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
        data: params,
      })
      return await getResult(res)
    } catch (error) {
      handleError(error)
    }
  }

  const uploadFile = async (url, file, onFileUploaded) => {
    const token = await getToken()
    const formData = new FormData()

    let apiFile = file
    const fileName = file.name.slice(0, file.name.lastIndexOf('.'))
    const fileType = file.name.slice(file.name.lastIndexOf('.'))
    if (fileType === '.heic') {
      const reader = new FileReader()
      reader.onload = async function () {
        let blob = dataURLToBlob(reader.result)
        blob = await heic2any({
          blob,
          toType: 'image/png',
          quality: 1,
        })

        apiFile = new File([blob], fileName + '.png', {
          type: 'image/png',
        })

        formData.append('img', apiFile)
        try {
          let res = await axios.post(BE_API_URL + url, formData, {
            headers: {
              Authorization: 'Bearer ' + token,
              'Content-Type': 'multipart/form-data',
            },
          })
          const result = await getResult(res)

          onFileUploaded(result)
        } catch (error) {
          handleError(error)
        }
      }
      reader.readAsDataURL(apiFile)
    } else {
      formData.append('img', apiFile)
      try {
        let res = await axios.post(BE_API_URL + url, formData, {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'multipart/form-data',
          },
        })
        const result = await getResult(res)

        onFileUploaded(result)
      } catch (error) {
        handleError(error)
      }
    }
  }

  return (
    <AuthContext.Provider
      value={{
        status,
        authToken,
        login,
        getData,
        postData,
        deleteData,
        uploadFile,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}
const useAuth = () => React.useContext(AuthContext)

export { AuthProvider, useAuth }
