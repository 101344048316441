import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box, Container, Grid, Typography } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  backTheme: {
    width: '100%',
    backgroundColor: '#E5E5E5',
  },
  historyTitle: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 14,
    lineHeight: '17px',
    textDecorationLine: 'underline',
    textTransform: 'uppercase',
    color: '#000000',
  },
  profileTitle: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '17px',
    textTransform: 'uppercase',
    color: '#000000',
  },
  emailTitle: {
    fontFamily: 'Bodoni',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 18,
    lineHeight: '173.5%',
    letterSpacing: '0.13em',
    color: '#1B1B1B',
  },
}))

function EmptyOrderComponent() {
  const classes = useStyles()
  return (
    <Box className={classes.backTheme}>
      <Container>
        <Grid
          container
          style={{ paddingTop: '8rem', minHeight: 'calc(100vh - 188px)' }}
        >
          <Grid
            item
            xs={6}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              borderBottom: '1px solid black',
              maxHeight: '2rem',
            }}
          >
            <Typography className={classes.emailTitle}>
              emailuživatele@seznam.cz
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              borderBottom: '1px solid black',
              maxHeight: '2rem',
            }}
          >
            <Typography
              className={classes.profileTitle}
              style={{ paddingRight: '4rem' }}
            >
              MŮJ PROFIL
            </Typography>
            <Typography className={classes.historyTitle}>
              HISTORIE OBJEDNÁVEK
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              minHeight: '50vh',
            }}
          >
            <Typography>Zatím nemáte u nás žádné objednávky</Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default EmptyOrderComponent
