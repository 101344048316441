import React from 'react'
import Contact from './Pages/Contact'
import './App.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from './theme.js'
import ScrollToTop from './Components/ScrollToTop'
import Header from './Components/MyHeader'
import Footer from './Components/Footer'
import HomePage from './Pages/Home'
import LifeCalendar from './Pages/LifeCalendarPage'
import Detail from './Pages/Detail'
import LoginRegister from './Pages/Login'
import EmptyOrders from './Pages/Orders'
import UserOrdersPage from './Pages/UserOrdersPage'
import OrderProfile from './Pages/Profil'
import Editor from './Pages/Editor'
import Category from './Pages/Category'
import './fonts.css'
import TmpTestPage from './Pages/TmpTestPage'
import Cart from './Pages/Cart'
import FaqPage from './Pages/TextPages/FaqPage'
import ErrorNotFound from './Pages/ErrorNotFound'
import TermsPage from './Pages/TextPages/TermsPage'
import GDPR from './Pages/TextPages/GDPR'
import State from './Pages/Payment/State'
import PaymentDialog from './Components/Dialogs/PaymentDialog'

import { KidProvider } from './Context/KidOrderContext'
import { MarriageOrderProvider } from './Context/MarriageOrderContext'
import { OrderProvider } from './Context/OrderContext'
import { CartProvider } from './Context/CartContext'
import { AuthProvider } from './Context/AuthContext'
import { SnackBarProvider } from './Context/ErrorSnackbarContext'

function App() {
  return (
    <React.StrictMode>
      <SnackBarProvider>
        <AuthProvider>
          <OrderProvider>
            <CartProvider>
              <KidProvider>
                <MarriageOrderProvider>
                  <ThemeProvider theme={theme}>
                    <Router>
                      <PaymentDialog />
                      <ScrollToTop />
                      <Header />
                      <Switch>
                        <Route path="/" exact component={HomePage} />

                        <Route path="/contact" exact component={Contact} />

                        <Route
                          path="/LifeCalendar"
                          exact
                          component={LifeCalendar}
                        />

                        <Route
                          path="/detail/:type/:framed"
                          exact
                          component={Detail}
                        />

                        <Route path="/detail/:type" exact component={Detail} />

                        <Route
                          path="/LoginRegister"
                          exact
                          component={LoginRegister}
                        />

                        <Route
                          path="/EmptyOrders"
                          exact
                          component={EmptyOrders}
                        />

                        <Route
                          path="/orders"
                          exact
                          component={UserOrdersPage}
                        />

                        <Route path="/profile" exact component={OrderProfile} />

                        <Route
                          path="/TmpTestPage"
                          exact
                          component={TmpTestPage}
                        />

                        <Route
                          path="/editor/:type/:framed"
                          exact
                          component={Editor}
                        />

                        <Route path="/editor/:type" exact component={Editor} />

                        <Route
                          path="/category/:type"
                          exact
                          component={Category}
                        />

                        <Route path="/cart" exact component={Cart} />

                        <Route path="/faq" exact component={FaqPage} />

                        <Route path="/terms" exact component={TermsPage} />

                        <Route path="/gdpr" exact component={GDPR} />

                        <Route path="/payment/:state" exact component={State} />

                        <Route path="*" component={ErrorNotFound} />
                      </Switch>
                      <Footer />
                    </Router>
                  </ThemeProvider>
                </MarriageOrderProvider>
              </KidProvider>
            </CartProvider>
          </OrderProvider>
        </AuthProvider>
      </SnackBarProvider>
    </React.StrictMode>
  )
}

export default App
