import React, { useState } from 'react'
import { useOrder } from './OrderContext'
import { useAuth } from './AuthContext'

const KidContext = React.createContext()

export const DEFAULT_MESSAGE =
  'Pár okamžiků jsi na světě a už milujeme tě. Přejeme ti, maličká(ý), měj život jako písnička, kterou ty si ráda zpíváš, a na svět se šťastně díváš. (návrh)'

const KidProvider = ({ children }) => {
  const { loadOrders } = useOrder()
  const { postData } = useAuth()

  const FROM_INFO = {
    name: '',
    surename: '',
    photo: null,
    message: DEFAULT_MESSAGE,
    colorSelect: 'black',
    isUploading: false,
  }

  const FROM_DETAIL = {
    birthDate: '2017-05-24',
    birthHour: '07:30',
    sign: '',
    weight: '',
    length: '',
    birthPlace: '',
  }

  const FROM_OVERVIEW = {
    agreement: false,
    annotation: '',
  }
  const [formInfo, setFormInfo] = useState(FROM_INFO)
  const [formDetail, setFormDetail] = useState(FROM_DETAIL)
  const [formOverview, setFormOverview] = useState(FROM_OVERVIEW)
  const [messageTouched, setMessageTouched] = useState(false)

  const handleSaveKid = async (framed) => {
    await postData('/cart/', {
      ProductID: framed ? 1 : 2,
      Quantity: 1,
      Data: JSON.stringify({ ...formInfo, ...formDetail, ...formOverview }),
    })
    loadOrders()
    setFormInfo(FROM_INFO)
    setFormDetail(FROM_DETAIL)
    setFormOverview(FROM_OVERVIEW)
  }
  return (
    <KidContext.Provider
      value={{
        formInfo,
        setFormInfo,
        formDetail,
        setFormDetail,
        formOverview,
        setFormOverview,
        handleSaveKid,
        messageTouched,
        setMessageTouched,
      }}
    >
      {children}
    </KidContext.Provider>
  )
}
const useKidOrder = () => React.useContext(KidContext)
export { KidProvider, useKidOrder }
