import React from 'react'
import { hydrate, render } from 'react-dom'
import 'react-slideshow-image/dist/styles.css'
import 'react-image-lightbox/style.css'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import TagManager from 'react-gtm-module'
import LogRocket from 'logrocket'

if (process.env.NODE_ENV === 'production') {
  LogRocket.init('kofbee/lifecalendar')
}

const tagManagerDevArgs = {
  gtmId: 'GTM-PL9Z6H9',
  auth: 'XCIjcvn-kjNdxdcQhmnjcg',
  preview: 'env-4',
}

const tagManagerProdArgs = {
  gtmId: 'GTM-PL9Z6H9',
  auth: 'eLTz5agWYI9BA3B9VRFYqQ',
  preview: 'env-1',
}

TagManager.initialize(
  process.env.NODE_ENV === 'production' ? tagManagerProdArgs : tagManagerDevArgs
)

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://03888541affb4b74a563705e9e07d746@o245321.ingest.sentry.io/6479429',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

const rootElement = document.getElementById('root')

if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement)
} else {
  render(<App />, rootElement)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
