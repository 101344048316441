import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
  },
  sepatatorLine: {
    border: '1px solid #1B1B1B',
    height: 50 /* nie 50, pretoze tam bola mala biela medzera */,
    /* netreba uz
    marginTop: -25,
    marginBottom: -25, */
    alignSelf: 'center',
  },
}))

function ConnectorLineComponent() {
  const classes = useStyles()
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      position="relative"
    >
      <Box position="absolute" className={classes.sepatatorLine}></Box>
    </Box>
  )
}

export default ConnectorLineComponent
