import React from "react";

import ProfilComponent from "../Components/Profil";

function ProfilFun() {
  return (
    <>
      <ProfilComponent />
    </>
  );
}

export default ProfilFun;
