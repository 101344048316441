import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Box,
  Grid,
  Typography,
  Card,
  CardActionArea,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { useImages } from '../../Context/ImagesContext'
import { useMediaQuery } from 'react-responsive'

const useStyles = makeStyles(() => ({
  item: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '& .MuiToolbar-gutters': {
      paddingLeft: 0,
    },
  },
  card: {
    border: '1px solid transparent',
    transition: 'border 250ms ease-out',
    '&:hover': {
      border: '1px solid #000',
    },
  },
  image: {
    maxHeight: '60vh',
  },
  loading: {
    display: 'none',
  },
}))

function Wedding() {
  const classes = useStyles()
  const { push } = useHistory()
  const { imagesLoaded } = useImages()
  const isMobile = useMediaQuery({ query: `(max-width: 576px)` })
  return (
    <>
      <Typography variant="h1">Svatba</Typography>
      <Box width="100%" height="0.5rem"></Box>
      <Typography variant="subtitle1">
        PERSOLIZOVANÉ DESIGNOVÉ PLAKÁTY
      </Typography>
      <Box width="100%" height="2rem"></Box>
      <Typography style={{ textAlign: 'center' }} variant="body1">
        Vyberte si variantu:
      </Typography>
      <Box width="100%" height="1rem"></Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box
            style={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Card style={{ borderRadius: '0px' }}>
              <CardActionArea
                onClick={() => push('/detail/wedding/framed')}
                style={{ display: 'flex' }}
              >
                {!imagesLoaded && (
                  <Skeleton
                    variant="rect"
                    style={{
                      width: '80vh',
                      height: '60vh',
                    }}
                  />
                )}
                <img
                  className={`${classes.image} ${
                    !imagesLoaded ? classes.loading : ''
                  }`}
                  src="/images/category/wedding_framed.webp"
                />
              </CardActionArea>
            </Card>

            <Box
              style={{
                position: 'absolute',
                bottom: '-3.8rem',
                left: '10vw',
                transform: 'translate(-20%, 0)',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Button
                style={{ marginBottom: 1 }}
                onClick={() => push('/detail/wedding/framed')}
              >
                s rámečkem
              </Button>
              <span style={{ marginTop: '1rem', fontSize: '1.3rem' }}>
                1 950,–
              </span>
            </Box>
          </Box>
          <Box width="100%" height="1rem"></Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            style={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              marginTop: isMobile ? '2.5rem' : undefined,
            }}
          >
            <Card style={{ borderRadius: '0px' }}>
              <CardActionArea
                onClick={() => push('/detail/wedding')}
                style={{ display: 'flex' }}
              >
                {!imagesLoaded && (
                  <Skeleton
                    variant="rect"
                    style={{
                      width: '80vh',
                      height: '60vh',
                    }}
                  />
                )}
                <img
                  className={`${classes.image} ${
                    !imagesLoaded ? classes.loading : ''
                  }`}
                  src="/images/category/wedding.webp"
                />
              </CardActionArea>
            </Card>

            <Box
              style={{
                position: 'absolute',
                bottom: '-3.8rem',
                left: '10vw',
                transform: 'translate(-20%, 0)',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Button onClick={() => push('/detail/wedding')}>
                bez rámečku
              </Button>
              <span style={{ marginTop: '1rem', fontSize: '1.3rem' }}>
                1 290,–
              </span>
            </Box>
          </Box>
          <Box width="100%" height="1rem"></Box>
        </Grid>
      </Grid>
    </>
  )
}

export default Wedding
