import React from 'react'
import moment from 'moment'
import { Typography } from '@material-ui/core'
import { useMarriageOrder } from '../../Context/MarriageOrderContext'
import { BE_URL } from '../../config'

function WeddingDataLayerMobile({ dimensions: { width, height } }) {
  const { formInfo, formDetail } = useMarriageOrder()

  const getPX = (size) => {
    return `${width * size}px`
  }

  const BASE_SIZE = 0.012
  const BIGGER_SIZE = 0.015
  const BASE_LINE_HEIGHT = 0.013

  return (
    <>
      <img
        style={{
          position: 'absolute',
          top: '10.7%',
          left: '27.7%',
          width: '62.7%',
          height: '70.5%',
          objectFit: 'cover',
          filter: 'contrast(0.5) brightness(1.2)',
        }}
        src={
          formInfo.photo
            ? `${BE_URL}${formInfo.photo}`
            : '/images/editor/wedding_placeholder.webp'
        }
      />
      <img
        style={{
          position: 'absolute',
          top: '11.5%',
          left: '9%',
          width: '55%',
        }}
        src="/images/editor/mr_mrs.png"
      />
      <img
        style={{
          position: 'absolute',
          top: '74.5%',
          left: '9%',
          width: '30%',
        }}
        src="/images/editor/day_description.png"
      />
      <Typography
        style={{
          position: 'absolute',
          top: '8.5%',
          right: '9.5%',
          fontSize: getPX(BIGGER_SIZE),
          fontWeight: '300',
        }}
      >
        {moment(formInfo.date).format('DD.MM.YYYY')}
      </Typography>
      <Typography
        style={{
          position: 'absolute',
          top: '8.5%',
          left: '9%',
          fontSize: getPX(BIGGER_SIZE),
          textAlign: 'center',
        }}
      >
        {formInfo.name1 !== '' && `${formInfo.name1} a`} {formInfo.name2}
      </Typography>
      <Typography
        style={{
          position: 'absolute',
          maxWidth: getPX(0.4),
          top: '20.8%',
          left: '9%',
          fontSize: getPX(BIGGER_SIZE),
          fontWeight: '300',
        }}
      >
        {formInfo.note}
      </Typography>
      <Typography
        style={{
          position: 'absolute',
          top: '35.5%',
          left: '1%',
          fontSize: getPX(0.02),
          textAlign: 'center',
          fontStyle: 'italic',
          fontWeight: 600,
          width: getPX(0.35),
        }}
      >
        {formInfo.name1 !== '' && `${formInfo.name1} a`} {formInfo.name2}
      </Typography>
      <Typography
        style={{
          position: 'absolute',
          top: '45%',
          left: '1%',
          fontSize: getPX(BASE_SIZE),
          textAlign: 'center',
          width: getPX(0.35),
        }}
      >
        {formDetail.place}
      </Typography>
      <Typography
        style={{
          position: 'absolute',
          top: '83.8%',
          left: '12.3%',
          fontSize: getPX(BASE_SIZE),
          lineHeight: getPX(BASE_LINE_HEIGHT),
          textAlign: 'left',
          maxWidth: getPX(0.19),
          fontWeight: '300',
        }}
      >
        {formDetail.song}
      </Typography>
      <Typography
        style={{
          position: 'absolute',
          top: '83.7%',
          left: '31.8%',
          fontSize: getPX(BASE_SIZE),
          lineHeight: getPX(BASE_LINE_HEIGHT),
          textAlign: 'left',
          width: '90px',
          fontWeight: '300',
        }}
      >
        {formDetail.priest}
      </Typography>
      <Typography
        style={{
          position: 'absolute',
          top: '88%',
          left: '12.3%',
          fontSize: getPX(BASE_SIZE),
          textAlign: 'left',
          width: '125px',
          fontWeight: '300',
        }}
      >
        {formDetail.procession}
      </Typography>
      <Typography
        style={{
          position: 'absolute',
          top: '88%',
          left: '31.8%',
          fontSize: getPX(BASE_SIZE),
          textAlign: 'left',
          width: '125px',
          fontWeight: '300',
        }}
      >
        {formInfo.hour}
      </Typography>
      <Typography
        style={{
          position: 'absolute',
          top: '84%',
          left: '49%',
          fontSize: getPX(BASE_SIZE),
          textAlign: 'left',
          width: '290px',
          fontWeight: '300',
        }}
      >
        {formDetail.story}
      </Typography>
      {/*       <Box position="absolute" top="10.7%" left="25.7%" width="64.7%">
          </Box> */}
    </>
  )
}

export default WeddingDataLayerMobile
