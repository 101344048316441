import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, Box, Typography, Container } from '@material-ui/core'
import ConnectorLine from './ConnectorLine'
import { useMediaQuery } from 'react-responsive'

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: 'Bodoni',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 44,
    color: '#FFFFFF',
    '@media (max-width: 926px)': {
      fontSize: 35,
    },
  },
  info: {
    marginTop: '1em',
    fontWeight: 600,
    fontSize: 16,
    color: '#FFFFFF',
  },
  backTheme: {
    width: '100%',
  },
}))

function HomeInfo() {
  const classes = useStyles()

  const isMobile = useMediaQuery({ query: `(max-width: 959px)` })

  return (
    <Box className={classes.backTheme}>
      <Container>
        <ConnectorLine />
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignContent="center"
          style={{ padding: '5rem 0' }}
        >
          <Box width="100%" display="flex" justifyContent="flex-end">
            <img src="/images/logo-circle.svg" />
          </Box>
          <Grid item md={12} style={{ position: 'relative', paddingBottom: 0 }}>
            <Box position="absolute" left="1rem" bottom="2rem">
              <Typography className={classes.title} variant="h3">
                {isMobile ? 'Designový' : 'Personalizovaný designový'} doplněk
              </Typography>
            </Box>
            <img width="100%" src={'/images/homeinfo-all.webp'} />
          </Grid>
          <Grid item md={6}>
            <Box position="relative">
              <Box position="absolute" left="1rem" bottom="2rem">
                <Typography className={classes.title} variant="h3">
                  Kvalitní materiály
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.info}
                  style={{ textTransform: 'uppercase' }}
                >
                  lesklý 180g papír, neutrálně bílý
                </Typography>
              </Box>
              <img width="100%" src={'/images/homeinfo-paper.webp'} />
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box position="relative">
              <Box position="absolute" left="1rem" bottom="2rem">
                <Typography className={classes.title} variant="h3">
                  Masivní rámy
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.info}
                  style={{ textTransform: 'uppercase' }}
                >
                  pevná, dřevěná kontrukce se sklem
                </Typography>
              </Box>
              <img width="100%" src={'/images/homeinfo-frame.webp'} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default HomeInfo
