import React, { useState, useEffect } from 'react'
import { useAuth } from './AuthContext'

const OrderContext = React.createContext({ orders: [] })

const OrderProvider = ({ children }) => {
  const { authToken, getData } = useAuth()
  const [orders, setOrders] = useState([])
  const [totalPrice, setTotalPrice] = useState(0)
  const [voucher, setVoucher] = useState(null)
  const [gatewayResponse, setGatewayResponse] = useState(null)

  const loadOrders = async () => {
    const res = await getData('/cart')
    if (res?.cart) {
      setOrders(res.cart.cartContent)
      setVoucher(res.cart.voucherDetail)
      setTotalPrice(res.cart.totalPrice)
    } else {
      setOrders([])
      setTotalPrice(0)
    }
  }

  const onlineProducts = [5, 6]

  const isPersonalDelivery = !!orders.find(
    (item) => !onlineProducts.includes(item.ID_product)
  )

  useEffect(() => {
    if (authToken) loadOrders()
  }, [authToken])

  return (
    <OrderContext.Provider
      value={{
        loadOrders,
        orders,
        setOrders,
        totalPrice,
        voucher,
        isPersonalDelivery,
        gatewayResponse,
        setGatewayResponse,
      }}
    >
      {children}
    </OrderContext.Provider>
  )
}

const useOrder = () => React.useContext(OrderContext)
export { OrderProvider, useOrder }
