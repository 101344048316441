import React, { useRef, useLayoutEffect, useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  CircularProgress,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles'

const TransparentDialog = withStyles(() => ({
  root: {
    '& .MuiDialog-paper': {
      background: 'transparent',
      margin: 0,
    },
    '& .MuiPaper-elevation24': {
      boxShadow: 'none !important',
    },
  },
}))(Dialog)

const useStyles = makeStyles((theme) => ({
  customDialog: {
    '&.MuiDialog-paperWidthSm.MuiDialog-paperScrollBody': {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        maxWidth: '100%',
      },
    },
  },
}))

const RESET_TIMEOUT = 100

function CustomImageDialogMobile({
  open,
  setOpen,
  children,
  baseImage,
  setDimensions,
}) {
  const [loaded, setLoaded] = useState(false)

  const classes = useStyles()

  const handleClose = () => {
    setOpen(false)
  }
  const imageLoad = () => {
    setTimeout(() => setLoaded(true), 1465)
  }

  const targetRef = useRef()

  let movement_timer = null

  const onDimensionsChange = () => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      })
    }
  }

  useLayoutEffect(() => {
    loaded && onDimensionsChange()
  }, [loaded])

  window.addEventListener('resize', () => {
    clearInterval(movement_timer)
    movement_timer = setTimeout(onDimensionsChange, RESET_TIMEOUT)
  })

  return (
    <TransparentDialog
      fullWidth
      scroll={'body'}
      open={open}
      onClose={handleClose}
      aria-labelledby="custom-image-dialog-title"
      aria-describedby="custom-image-dialog-description"
      PaperProps={{
        classes: {
          root: classes.customDialog,
        },
      }}
    >
      <DialogTitle
        style={{
          padding: '0 1rem',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <CloseIcon style={{ color: 'white' }} onClick={handleClose}></CloseIcon>
      </DialogTitle>
      <DialogContent
        ref={targetRef}
        style={{
          margin: '0 1rem',
          padding: '0',
          position: 'relative',
          backgroundColor: 'transparent',
        }}
      >
        {!loaded && (
          <Box
            style={{
              position: 'absolute',
              width: '93.3%',
              height: '670px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 1,
              backgroundColor: 'white',
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {children}

        <img
          onLoad={imageLoad}
          style={{ width: '100%' }}
          src={`/images/${baseImage}`}
        ></img>
      </DialogContent>
      <Typography
        style={{ margin: '0 2rem 2rem', color: 'white', textAlign: 'center' }}
      >
        *automatický náhled, nejedná se o finální verzi
      </Typography>
    </TransparentDialog>
  )
}

export default CustomImageDialogMobile
