import React from 'react'
import { makeStyles, withStyles, Hidden } from '@material-ui/core'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Grid,
  Container,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

const useStyles = makeStyles(() => ({
  dialogTitleText: {
    fontFamily: 'Bodoni',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 36,
    lineHeight: '173.5%',
    color: '#1B1B1B',
  },
  dialogText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '156.4%',
    color: '#000000',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  blackButtonText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '17px',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#FFFFFF',
  },
  whiteButtonText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '17px',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#000000',
  },
}))

const BlackColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#1B1B1B'),
    backgroundColor: '#1B1B1B',
    borderRadius: 0,
    border: '1px solid #1B1B1B',
    width: 188,
    height: 46,
    '&:hover': {
      backgroundColor: '#1B1B1B',
    },
  },
}))(Button)

const WhiteColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#FFFFFF'),
    backgroundColor: '#FFFFFF',
    borderRadius: 0,
    border: '1px solid #1B1B1B',
    width: 188,
    height: 46,
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
}))(Button)

function ConfirmOrder({ open }) {
  const classes = useStyles()

  const { push } = useHistory()
  const isMobile = useMediaQuery({ query: `(max-width: 959px)` })

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Container
        style={{
          width: '100%',
          margin: '1rem 0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid container style={{ border: '1px solid black', padding: '1rem' }}>
          <Grid
            item
            xs={12}
            md={12}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Typography
              variant="h3"
              className={classes.dialogTitleText}
              style={{ fontSize: isMobile ? '30px' : undefined }}
            >
              Úspěšně uloženo
            </Typography>
          </Grid>
          <Hidden smDown>
            <Grid item xs={12} md={12}>
              <DialogContent>
                <DialogContentText
                  className={classes.dialogText}
                  id="alert-dialog-description"
                >
                  Pokračujte prosím do košíku a dokončete objednávku.
                </DialogContentText>
              </DialogContent>
            </Grid>
            <Grid item xs={12} md={12}>
              <DialogActions>
                <Box
                  style={{
                    width: '100%',
                    display: isMobile ? 'flex' : 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <WhiteColorButton onClick={() => push('/')} color="primary">
                    <Typography className={classes.whiteButtonText}>
                      Na hlavní stránku
                    </Typography>
                  </WhiteColorButton>
                </Box>
                <Box
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <BlackColorButton
                    onClick={() => push('/cart')}
                    color="primary"
                    autoFocus
                  >
                    <Typography className={classes.blackButtonText}>
                      Do košíku
                    </Typography>
                  </BlackColorButton>
                </Box>
              </DialogActions>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid item xs={12} md={12}>
              <DialogContentText
                className={classes.dialogText}
                id="alert-dialog-description"
              >
                Pokračujte prosím do košíku a dokončete objednávku.
              </DialogContentText>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{ display: isMobile ? 'block' : 'flex' }}
            >
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <BlackColorButton
                  onClick={() => push('/cart')}
                  color="primary"
                  autoFocus
                >
                  <Typography className={classes.blackButtonText}>
                    Do košíku
                  </Typography>
                </BlackColorButton>
              </Box>
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '0.5rem',
                }}
              >
                <WhiteColorButton onClick={() => push('/')} color="primary">
                  <Typography className={classes.whiteButtonText}>
                    Na hlavní stránku
                  </Typography>
                </WhiteColorButton>
              </Box>
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </Dialog>
  )
}

export default ConfirmOrder
