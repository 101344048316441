import React, { useState } from 'react'
import { useAuth } from './AuthContext'

const DELIVERY_PRICE = 79

const CartContext = React.createContext()

const CartProvider = ({ children }) => {
  const { postData, getData } = useAuth()
  const [submitting, setSubmitting] = useState(false)
  const [coupon, setCoupon] = useState({
    couponName: '',
  })
  const [shipping, setShipping] = useState({
    First_name: '',
    Last_name: '',
    Email: '',
    Phone: '',
    Street: '',
    City: '',
    Postal_code: '',
    Country: 'ČR',
    accountPassword: '',
    accountPasswordConfirm: '',
    agreement: true,
    delivery_address: {
      First_name: '',
      Last_name: '',
      Email: '',
      Phone: '',
      Street: '',
      City: '',
      Postal_code: '',
      Country: '',
    },
  })

  const DEFAULT_PAYMENT = {
    ID_delivery: null,
    Payment: 'card',
  }
  const [payment, setPayment] = useState(DEFAULT_PAYMENT)

  const order = async () => {
    setSubmitting(true)
    const hash = await postData('/order', {
      ...shipping,
      ...payment,
    })

    setSubmitting(false)

    return hash
  }

  const fetchPaymentURL = async (hash) => {
    setSubmitting(true)
    const url = await getData(`/order/${hash}/pay`)

    setSubmitting(false)

    return url
  }

  const checkCoupon = async () => {
    return await postData('/voucher', {
      voucherCode: coupon.couponName,
    })
  }

  const createAccount = async () => {
    /*     await postData('/coupon', {
      ...coupon,
    }) */
  }

  return (
    <CartContext.Provider
      value={{
        coupon,
        setCoupon,
        shipping,
        setShipping,
        payment,
        submitting,
        setPayment,
        order,
        checkCoupon,
        createAccount,
        fetchPaymentURL,
        DELIVERY_PRICE,
        DEFAULT_PAYMENT,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

const useCartProvider = () => React.useContext(CartContext)
export { CartProvider, useCartProvider }
