import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useHistory } from 'react-router-dom'
import React from 'react'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

const useStylesHome = makeStyles((theme) => ({
  palette: {
    primary: '#505050',
    secondary: 'green',
    error: 'red',
  },
  previewImage: {
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    backgroundImage: 'url(./images/mainPageImage.webp)',
  },
  previewTextBig: {
    fontFamily: 'EB_Garamond',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 32,
    lineHeight: '40px',
    [theme.breakpoints.up('sm')]: {
      fontSize: 48,
      lineHeight: '63px',
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '90vh',
  },
  buttonsBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  arrowContainer: {
    display: 'flex',
    alignSelf: 'flex-end',
    justifyContent: 'center',
    marginTop: '1rem',
  },
  previewTextSmall: {
    margin: '0.5rem 0px 1rem 0',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 18,
    color: '#1B1B1B',
  },
  box: {
    padding: '2rem',
    background: 'rgb(255 255 255 / 70%)',
    textAlign: 'center',
    minWidth: '60vw',
    [theme.breakpoints.up('sm')]: {
      padding: '3rem',
    },
  },
}))

function MainPreviewComponent() {
  const classes = useStylesHome()
  const { push } = useHistory()
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item xs={12}>
        <Box height="100vh" position="relative">
          <Box
            position="absolute"
            height="100%"
            width="100%"
            className={classes.previewImage}
          >
            <Container className={classes.textContainer}>
              <Box className={classes.box}>
                <Box>
                  <Typography className={classes.previewTextBig}>
                    Nechte si vytvořit plakát s vlastním příběhem
                  </Typography>
                </Box>
                <Box>
                  <Typography className={classes.previewTextSmall}>
                    Krásná připomínka nejdůležitějších dnů života
                  </Typography>
                </Box>
                <Box className={classes.buttonsBox}>
                  {/* <ColorButton onClick={() => push('/category/life')} variant="contained" style={{ borderRadius: 0 }}>
                  ŽIVOTNÍ KALENDÁŘ
                </ColorButton> */}
                  <Button
                    color="primary"
                    onClick={() => push('/detail/wedding')}
                    variant="contained"
                    style={{ borderRadius: 0 }}
                  >
                    SVATBA
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => push('/detail/kids')}
                    variant="contained"
                    style={{ borderRadius: 0, marginLeft: '1rem' }}
                  >
                    DĚTI
                  </Button>
                </Box>
              </Box>
            </Container>
            <Container className={classes.arrowContainer}>
              <IconButton>
                <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
              </IconButton>
            </Container>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default MainPreviewComponent
