import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Select,
  MenuItem,
} from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import 'react-slideshow-image/dist/styles.css'
import Lightbox from 'react-image-lightbox'
import { Slide } from 'react-slideshow-image'
import { useAuth } from '../../Context/AuthContext'
import ConfirmVoucher from '../Dialogs/confirm/ConfirmVoucher'
import { useOrder } from '../../Context/OrderContext'

const useStyles = makeStyles(() => ({
  backTheme: {
    backgroundColor: '#FFFFFF',
    width: '100%',
  },
  image: {
    maxWidth: '100%',
    width: '100%',
  },

  title: {
    fontFamily: 'Bodoni',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '36px',
  },

  textP: {
    fontSize: '14px',
    fontWeight: 'normal',
    color: '#1B1B1B',
  },

  cena: {
    fontSize: '22px',
    fontWeight: 'normal',
    marginTop: '35px',
  },

  pop: {
    marginTop: '20px',
    marginRight: '10px',
  },

  rozmer: {
    marginTop: '20px',
  },

  amount: {
    border: '2px solid',
    padding: '6px',
    marginTop: '10px',
    maxWidth: '50px',
    maxHeight: '30px',
    paddingLeft: '20px',
  },

  btn: {
    marginTop: '2rem',
    backgroundColor: '#1B1B1B',
    borderRadius: 0,
    color: 'white',
    '&:hover': {
      background: 'black',
    },
  },

  butt: {
    width: '100%',
  },

  text2: {
    fontSize: '14px',
    fontWeight: 'bold',
    marginTop: '5rem',
  },

  text3: {
    fontSize: '14px',
    fontWeight: 'normal',
    marginRight: '10px',
  },

  collapseMenu: {
    fontSize: '14px',
    fontWeight: 'bold',
    marginTop: '60px',
  },

  collapseMenu1: {
    fontSize: '14px',
    fontWeight: 'bold',
    marginTop: '15px',
  },

  heading: {
    fontSize: '14px',
    fontWeight: 'bold',
  },

  root: {
    marginTop: '40px',
    borderRadius: '0px',
  },

  accord: {
    marginTop: '10px',
    borderBottom: '1px solid #E4E4E4',
    boxShadow: '5px 5px 0px -30px #FFFFFF',
  },
}))
const DetailVoucher = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [imageIndex, setImageIndex] = useState(0)
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const { postData } = useAuth()
  const { loadOrders } = useOrder()

  const [framed, setFramed] = useState()
  const [done, setDone] = useState(false)

  const imageData = [
    {
      url: `/images/detail/poukaz.webp`,
      title: 'Wedding',
    },
  ]

  const handleSaveVoucher = () => {
    postData('/cart/', {
      ProductID: framed ? 6 : 5,
      Quantity: 1,
      Data: {},
    }).then(() => {
      loadOrders()
      setDone(true)
    })
  }

  return (
    <>
      <Box className={classes.backTheme}>
        <Container
          style={{
            backgroundColor: '#FFFFFF',
            minHeight: 'calc(100vh - 100px - 281px)',
          }}
        >
          <Grid
            container
            spacing={isDesktop ? 8 : null}
            style={{
              marginTop: '100px',
              width: '100%',
              backgroundColor: '#FFFFFF',
            }}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {isDesktop ? (
                <div>
                  {imageData.map((img, i) => (
                    <img
                      onClick={() => {
                        setIsOpen(true)
                        setImageIndex(i)
                      }}
                      className={classes.image}
                      key={img.title}
                      src={img.url}
                      alt={img.title}
                    />
                  ))}
                </div>
              ) : (
                <Slide
                  {...{
                    duration: 5000,
                    autoplay: false,
                    transitionDuration: 200,
                    arrows: true,
                    infinite: true,
                  }}
                >
                  {imageData.map((image, i) => (
                    <div key={image.url} className="each-slide-effect">
                      <img
                        onClick={() => {
                          setIsOpen(true)
                          setImageIndex(i)
                        }}
                        style={{ width: '100%' }}
                        src={image.url}
                      />
                    </div>
                  ))}
                </Slide>
              )}
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Typography className={classes.title}>Dárkový poukaz</Typography>
              <Typography className={classes.textP}>
                DÁRKOVÝ POUKAZ NA LIBOVOLNÝ PLAKÁT
              </Typography>
              <Typography className={classes.cena}>
                {framed ? '1 950,-' : '1 390,-'}
              </Typography>
              <p
                style={{
                  fontSize: '11px',
                  color: 'grey',
                  margin: 0,
                }}
              >
                * včetně dopravy
              </p>

              <Select
                variant="outlined"
                style={{ marginTop: '1rem' }}
                value={framed ? 'framed' : 'basic'}
                onChange={(e) => setFramed(e.target.value === 'framed')}
              >
                <MenuItem value="basic">Bez rámu</MenuItem>
                <MenuItem value="framed">S rámem</MenuItem>
              </Select>
              <Box className={classes.butt}>
                <Button
                  className={classes.btn}
                  onClick={() => handleSaveVoucher()}
                >
                  VLOŽIT DO KOŠÍKU
                </Button>
              </Box>

              <Typography className={classes.text2}>POPIS</Typography>
              <Typography className={classes.text3}>
                Zakoupením tohoto pokukazu darujete svým blízkým možnost
                vytvořit si svůj osobní plakát{framed ? ' s rámem' : ''}. V ceně
                voucheru je zahrnuta doprava pro obdarovaného zdarma a jeden
                libovolný plakát{framed ? ' s rámem' : ''} v hodnotě{' '}
                {framed ? '1850,-' : '1 290,-'}&nbsp;Kč. Zakoupením tohoto
                poukazu darujete svým blízkým možnost vytvořit si plakát
                {framed ? ' s rámem' : ''} na míru. Pro využití poukazu
                obdarovaný prvně vyplní informace potřebné na vytvoření plakátu,
                jako u klasických objednávek, a posléze v košíku vyplní kód z
                poukazu.
              </Typography>
              <Typography className={classes.text3}>
                V ceně dárkového poukazu je zahrnuta doprava balíku pro
                obdarovaného, tudíž už nebude mít žádné další náklady. Voucher
                Vám pošleme po přijetí platby ihned na email.
              </Typography>
              <Box width="100%" height="3rem" />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <ConfirmVoucher open={done} setOpen={setDone} />

      {isOpen && (
        <Lightbox
          mainSrc={imageData[imageIndex]?.url}
          nextSrc={imageData[(imageIndex + 1) % imageData.length]?.url}
          prevSrc={
            imageData[(imageIndex + imageData.length - 1) % imageData.length]
              ?.url
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setImageIndex(
              (imageIndex + imageData.length - 1) % imageData.length
            )
          }
          onMoveNextRequest={() =>
            setImageIndex((imageIndex + 1) % imageData.length)
          }
        />
      )}
    </>
  )
}

export default DetailVoucher
