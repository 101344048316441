import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Typography,
  Grid,
  Container,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  dialogTitleText: {
    fontFamily: 'Bodoni',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 36,
    lineHeight: '173.5%',
    color: '#1B1B1B',
  },
  dialogText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '156.4%',
    color: '#000000',

    display: 'flex',
    justifyContent: 'center',
  },
  blackButtonText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '17px',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#FFFFFF',
  },
  whiteButtonText: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: '17px',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#000000',
  },
}))

const BlackColorButton = withStyles((theme) => ({
  root: {
    margin: '0 auto',
    color: theme.palette.getContrastText('#1B1B1B'),
    backgroundColor: '#1B1B1B',
    borderRadius: 0,
    border: '1px solid #1B1B1B',
    width: 188,
    height: 46,
    '&:hover': {
      backgroundColor: '#1B1B1B',
    },
  },
}))(Button)

const WhiteColorButton = withStyles((theme) => ({
  root: {
    margin: '0 auto',
    color: theme.palette.getContrastText('#FFFFFF'),
    backgroundColor: '#FFFFFF',
    borderRadius: 0,
    border: '1px solid #1B1B1B',
    width: 188,
    height: 46,
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
}))(Button)

function ConfirmVoucher({ open, setOpen }) {
  const classes = useStyles()

  const handleClose = () => {
    setOpen(false)
  }

  const { push } = useHistory()

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Container
        style={{
          minHeight: 300,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <Grid container style={{ border: '1px solid black', margin: '1rem 0' }}>
          <Grid item xs={12} md={12}>
            <DialogTitle
              style={{ display: 'flex', justifyContent: 'center' }}
              id="alert-dialog-title"
            >
              <Typography className={classes.dialogTitleText}>
                Úspěšně přidáno do košíku
              </Typography>
            </DialogTitle>
          </Grid>
          <Grid item xs={12} md={12}>
            <DialogContent>
              <DialogContentText
                className={classes.dialogText}
                id="alert-dialog-description"
              >
                Pokračujte prosím do košíku a dokončete objednávku.
              </DialogContentText>
            </DialogContent>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{ display: 'flex', padding: '1rem' }}
          >
            <WhiteColorButton onClick={() => push('/')} color="primary">
              <Typography className={classes.whiteButtonText}>
                Na hlavní stránku
              </Typography>
            </WhiteColorButton>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{ display: 'flex', padding: '1rem' }}
          >
            <BlackColorButton
              onClick={() => push('/cart')}
              color="primary"
              autoFocus
            >
              <Typography className={classes.blackButtonText}>
                Do košíku
              </Typography>
            </BlackColorButton>
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  )
}

export default ConfirmVoucher
