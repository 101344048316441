import React from 'react'
import GDPR from '../../Components/TextComponents/GDPR'

function GDPRPage() {
  return (
    <>
      <GDPR />
    </>
  )
}

export default GDPRPage
